"use client";

import React, { useEffect, useState } from "react";
import { GeoJSON, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "../index.css";
import "leaflet-boundary-canvas";
import { useTranslation } from "react-i18next";

export default function LausitzMap({
  geoJsonFiles,
  selectedFiles,
  reverseGeocode,
}) {
  const map = useMap();

  const { t } = useTranslation("translations");

  useEffect(() => {
    const osm = L.TileLayer.boundaryCanvas(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        boundary: geoJsonFiles[0].data[0],
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, UK shape <a href="https://github.com/johan/world.geo.json">johan/word.geo.json</a>',
      }
    );

    map.addLayer(osm);
  }, [map, geoJsonFiles]);

  return geoJsonFiles
    .filter(({ id }) => selectedFiles.includes(id))
    .map(({ id, data, color }, index) => (
      <React.Fragment key={index}>
        <GeoJSON
          data={data}
          style={() => ({
            color,
            weight: 2,
            fillOpacity: 0.15,
            fillColor: "#66000000",
          })}
          onEachFeature={(feature, layer) => {
            layer.on({
              click: async (event) => {
                const { lat, lng } = event.latlng;
                layer.bindPopup(`<b>${t("loading")}...</b>`);
                const locationInfo = await reverseGeocode(lat, lng);
                layer.setPopupContent(
                  `<b>${
                    locationInfo?.display_name
                      ? locationInfo?.display_name
                      : t("No data")
                  }</b>`
                );
              },
            });
          }}
        />
      </React.Fragment>
    ));
}
