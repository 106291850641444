import React, { useMemo } from "react";
import classes from "./categories.module.scss";
import CategoryCard from "./categoryCard";
import { useTranslation } from "react-i18next";
import FilterSection from "../filterSection";

export default function Categories({
  fetchRelevantFile = () => {},
  selectedFiles = [],
  handleSelectedID = () => {},
  loading = false,
  handleCheckboxChange,
  // railway
  railWayOptions,
  // transportVolumesOptions
  transportVolumesOptions,
  filterSelections,
  setFilterSelections,
  // roadTacks
  roadsOptions,
  // Railway Map
  onChangeRadius,
  handleKeyPress,
  radius,
  setIsSearchedClicked,
  railwayMapClass,
  // Terminals
  terminalOptions = [],
  // Filter Category
  filterCategory = "",
}) {
  const { t } = useTranslation("sidebar");
  const { t: translation } = useTranslation("translations");

  const CATEGORIES = useMemo(() => {
    const allCategories = [
        {
            id: 'roadTacks',
            label: t('categories.roadTacks'),
            image: './images/roads.png',
            menus: roadsOptions
                ? [
                      ...roadsOptions.map(({ name }) => name.toLowerCase()),
                      t('filters.international_roads').toLowerCase(),
                      t('filters.national_roads').toLowerCase(),
                  ]
                : [],
            action: (
                <>
                    <FilterSection
                        label={t('roadFilters')}
                        data={roadsOptions}
                        handleCheckboxChange={handleCheckboxChange}
                        filterSelections={filterSelections}
                        isRoadTacks
                    />
                    <FilterSection
                        label={t('filters.roadFilters')}
                        data={[
                            {
                                name: t('filters.international_roads'),
                                image: './images/road_tracks.png',
                                singleItem: 'roads',
                                filter: 'international',
                                values: [],
                            },
                            {
                                name: t('filters.national_roads'),
                                image: './images/road_tracks.png',
                                singleItem: 'roads',
                                filter: 'national',
                                values: [],
                            },
                        ]}
                        handleCheckboxChange={handleCheckboxChange}
                        filterSelections={filterSelections}
                        isRoadTacks
                    />
                </>
            ),
            isDropdown: true,
        },
        {
            id: 'railway',
            label: t('categories.railway'),
            image: './images/railways.png',
            menus: railWayOptions
                ? [
                      ...railWayOptions.map(({ name }) => name.toLowerCase()),
                      'openrailwaymap',
                  ]
                : [],
            action: (
                <>
                    <FilterSection
                        label={t('railwayMapFilter')}
                        data={[
                            {
                                name: 'OpenRailwayMap',
                                image: './images/railways.png',
                                singleItem: 'railwayMap',
                                filter: 'Railway Map',
                                values: [],
                            },
                        ]}
                        handleCheckboxChange={handleCheckboxChange}
                        filterSelections={filterSelections}
                        isRailway
                    />
                    <FilterSection
                        label={t('railwayFilters')}
                        data={railWayOptions}
                        handleCheckboxChange={handleCheckboxChange}
                        filterSelections={filterSelections}
                        isRailway
                    />
                </>
            ),
            isDropdown: true,
        },
        {
            id: 'kvTerminal',
            label: t('categories.kvTerminal'),
            image: './images/kv-terminal.png',
            menus: terminalOptions
                ? terminalOptions.map(({ name }) => name.toLowerCase())
                : [],
            action: (
                <FilterSection
                    label={translation('Filter for Terminals')}
                    data={terminalOptions}
                    handleCheckboxChange={handleCheckboxChange}
                    isRailway
                    filterSelections={filterSelections}
                />
            ),
            isDropdown: true,
        },
        {
            id: 'inlandPort',
            label: t('categories.inlandPort'),
            image: './images/ports.png',
        },
        {
            id: 'airport',
            label: t('categories.airport'),
            image: './images/airport.png',
        },
        {
            id: 'transportVolumes',
            label: t('categories.transportVolumes'),
            image: './images/transport.png',
            // menus: transportVolumesOptions
            //   ? transportVolumesOptions.map(({ name }) => name.toLowerCase())
            //   : [],
            // action: (
            //   <FilterSection
            //     label={t("transportVolumesFilters")}
            //     data={transportVolumesOptions}
            //     filterSelections={filterSelections}
            //     setFilterSelections={setFilterSelections}
            //     handleCheckboxChange={handleCheckboxChange}
            //     isTransportVolumes
            //   />
            // ),
            action: null,
            // isDropdown: true,
        },
        {
            id: 'tollData',
            label: t('categories.tollData'),
            image: './images/toll.png',
        },
        {
            id: 'socio-economic',
            label: t('categories.socio-economic'),
            image: './images/socio-economic.svg',
        },
    ];

    // Apply filter based on filterCategory
    return filterCategory
      ? allCategories
          .filter((category) => {
            const labelToLowerCase = category.label.toLowerCase() || "";
            const filterCategoryToLowerCase =
              filterCategory.toLowerCase() || "";
            if (
              labelToLowerCase.includes(filterCategoryToLowerCase) ||
              category?.menus?.toString()?.includes(filterCategoryToLowerCase)
            ) {
              return category;
            }
          })
          .map((category) => ({ ...category, shouldOpen: true }))
      : allCategories.map((category) => ({ ...category, shouldOpen: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFiles,
    railWayOptions,
    roadsOptions,
    transportVolumesOptions,
    filterCategory,
    terminalOptions,
    t,
  ]);

  return (
    <div className={classes["all-categories"]}>
      {CATEGORIES.map(
        ({
          id,
          image,
          label,
          action,
          isDropdown = false,
          shouldOpen = false,
        }) => (
          <CategoryCard
            key={label}
            fetchRelevantFile={fetchRelevantFile}
            selectedFiles={selectedFiles}
            handleSelectedID={() => handleSelectedID(id)}
            loading={loading}
            id={id}
            image={image}
            label={label}
            action={action}
            isDropdown={isDropdown}
            shouldOpen={shouldOpen}
          />
        )
      )}
    </div>
  );
}
