import { InLandPortsIcon } from "../icons/InLandPorts.icon.js";
import { Marker, useMap } from "react-leaflet";
import InlandPorts from "../../../data/Inland_Ports_Terminal.json";

export const GEOInlandPorts = ({
  selectedFiles,
  handleInlandPort = () => {},
}) => {

  const map = useMap();

  if (!selectedFiles.includes("inlandPort")) return null;


  const handleMarkerClick = (event, markerPosition, selectedPortDetails) => {
    map.flyTo(markerPosition, 15, {
      animate: true,
    });
    handleInlandPort(selectedPortDetails);
  };

  return InlandPorts.features.map((data, index) => {
    const position = [
      data.geometry.coordinates[1],
      data.geometry.coordinates[0],
    ];
    return (
      <Marker
        key={index}
        position={position}
        icon={InLandPortsIcon}
        eventHandlers={{
          click: (event) => handleMarkerClick(event, position, data),
        }}
      ></Marker>
    );
  });
};
