import React, { useEffect, useState } from "react";
import axios from "axios";
import classes from "./comparisonSider.module.scss";
import { useTranslation } from "react-i18next";
import LineChartWithTooltip from "../lineChartWithTooltip";
import Loader from "../sharedComponent/loader";
import { REGIONS_LIST } from "../../utils/constants";
import { WegweiserService } from "../../services";
import Select from "../sharedComponent/select";
import ComparisionTable from "./comparisionTable";

const ComparisonSidebar = ({
  setCompare = () => {},
  cuurentRegion = "",
  selectedRegionFriendlyURL = "",
}) => {
  const [regions, setRegions] = useState([
    { name: cuurentRegion, data: [], chart: null },
    { name: "", data: [], chart: null },
  ]);

  const { t } = useTranslation("translations");

  const [loading, setLoading] = useState(false);
  const [selectedIndicatorData, setSelectedIndicatorData] = useState(null);

  const fetchIndicatorValueForRegion = async (region) => {
    try {
      const result = await WegweiserService.getSpecificRegionDetails(region);

      const regionId = result?.data[0]?.id;
      const regionFriendlyURL = result?.data[0]?.friendlyUrl;

      const response = await WegweiserService.getStatisticsData({
        regionIds: [regionId],
        years: [2022],
        indicatorIds: [
          166, 162, 25, 26, 27, 413, 13, 147, 154, 155, 248, 249, 158, 50, 315,
          324, 336, 325, 300, 225, 160, 301, 200, 261, 263, 262, 264, 260, 286,
        ],
      });

      const formattedData = response?.data?.indicators?.map((indicator) => ({
        indicatorFriendlyUrl: indicator.friendlyUrl,
        name: indicator?.name,
        unit: indicator?.unit,
        value: indicator?.regionYearValues[0][0], // Assuming you want the first value from regionYearValues array
        friendlyURL: regionFriendlyURL,
      }));

      return { formattedData: formattedData, regionFriendlyURL };
    } catch (error) {
      console.error("Error fetching indicator data:", error);
    }
  };

  const fetchChart = async (
    chart = "tabelle",
    indicator = "demografische-entwicklung",
    region = ""
  ) => {
    const startYear = "2013";
    const endYear = "2024";

    try {
      const response = await WegweiserService.fetchStatsByFriendlyURL({
        indicator,
        friendlyURL: selectedRegionFriendlyURL,
        startYear,
        endYear,
        chart,
        arrayBuffer: true,
      });
      const buffer = await response.data;

      const base64String = buffer
        ? btoa(
            new Uint8Array(buffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          )
        : "";
      return base64String;
    } catch (error) {
      console.error("Error fetching chart:", error);
    }
  };

  const handleRegionChange = async (index, selectedRegion) => {
    setLoading(true);
    const data = await fetchIndicatorValueForRegion(selectedRegion);
    const chartData = await fetchChart(
      "tabelle",
      "demografische-entwicklung",
      selectedRegion
    );
    setRegions((prevRegions) => {
      const updatedRegions = [...prevRegions];
      updatedRegions[index] = {
        name: selectedRegion,
        data: selectedRegion ? data?.formattedData || [] : [],
        chart: selectedRegion ? chartData : "",
        regionFriendlyURL: selectedRegion ? data?.regionFriendlyURL : "",
      };
      return updatedRegions;
    });
    setLoading(false);
  };

  const addRegionInput = () => {
    setRegions((prevRegions) => [
      ...prevRegions,
      { name: "", data: [], chart: null },
    ]);
  };

  const removeRegionInput = (index) => {
    setRegions((prevRegions) => {
      const updatedRegions = [...prevRegions];
      updatedRegions.splice(index, 1);
      return updatedRegions;
    });
  };

  useEffect(() => {
    const fetchLatest = async () => {
      setLoading(true);
      const data = await fetchIndicatorValueForRegion(cuurentRegion);
      const chartData = await fetchChart(
        "tabelle",
        "demografische-entwicklung",
        cuurentRegion
      );
      setRegions((prevRegions) => {
        const updatedRegions = [...prevRegions];
        updatedRegions[0] = {
          name: cuurentRegion,
          data: data?.formattedData || [],
          chart: chartData,
          regionFriendlyURL: data?.regionFriendlyURL,
        };
        return updatedRegions;
      });
      setLoading(false);
    };

    fetchLatest();
  }, []);

  const [jsonData, setJSONData] = useState("");

  const fetchGraph = async () => {
    try {
      let regionsFriendlyURLS = "";
      regions.forEach((d) => {
        if (d.data.regionFriendlyURL || d.regionFriendlyURL) {
          regionsFriendlyURLS +=
            (d.data.regionFriendlyURL || d.regionFriendlyURL) + "+";
        }
      });
      const indicator =
        selectedIndicatorData?.indicatorFriendlyUrl ||
        "demografische-entwicklung";

      if (regionsFriendlyURLS.endsWith("+")) {
        regionsFriendlyURLS = regionsFriendlyURLS.slice(0, -1);
      }
      const result = await WegweiserService.fetchStatsByFriendlyURL({
        indicator,
        friendlyURL: regionsFriendlyURLS,
        startYear: "2006",
        endYear: "2022",
        chart: "liniendiagramm",
        isJSON: true,
      }).then((res) => res.data);
      setJSONData(result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      fetchGraph();
    } catch (e) {
      console.log("e", e);
    }
  }, [JSON.stringify(regions), selectedIndicatorData?.indicatorFriendlyUrl]);

  const handleRowClick = (data) => {
    setSelectedIndicatorData(data);
  };

  return (
    <div className={classes["comparison-sidebar"]}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>{t("Comparison between regions")}</h2>
        <span
          className={classes["close-icon"]}
          onClick={() => setCompare(false)}
        >
          &times;
        </span>
      </div>
      {/* Select/Add Region(s) */}
      <div className={classes["region-inputs"]}>
        {regions.map((region, index) => (
          <div key={index} className={classes["region-input"]}>
            <Select
              index={index}
              region={region}
              isComparison
              options={REGIONS_LIST.filter(
                (region) =>
                  !regions.some((reg) => reg.name === region && index !== 0)
              )}
              onChange={(e) => handleRegionChange(index, e.target.value)}
            />

            {index !== 0 && (
              <span
                className={classes["close-icon"]}
                onClick={() => removeRegionInput(index)}
              >
                &times;
              </span>
            )}
          </div>
        ))}
        <button
          className={`${classes["add-region-button"]} ${
            regions.length === 7 ? classes.disabled : ""
          }`}
          onClick={addRegionInput}
          disabled={regions.length === 7}
        >
          +
        </button>
      </div>
      {loading && <Loader />}
      {regions[0]?.data?.length > 1 && (
        <ComparisionTable
          handleRowClick={handleRowClick}
          data={regions[0]?.data}
          regions={regions}
        />
      )}
      {/* <div className={classes["charts-container"]}>
        <div>
          <h2>
            {!selectedIndicatorData?.name
              ? t("demografische-entwicklung")
              : t(selectedIndicatorData.name?.replace(":", " "))}
          </h2>
          {jsonData && (
            <div style={{ width: "800px" }}>
              <LineChartWithTooltip
                indicatorName={
                  selectedIndicatorData
                    ? t(selectedIndicatorData?.name?.replace(":", " "))
                    : t("demografische-entwicklung")
                }
                unit={selectedIndicatorData?.unit || "%"}
                JSONData={jsonData ? jsonData : {}}
                selectedRegions={
                  regions?.length > 0
                    ? regions.filter((r) => r.name).map((r) => r.name)
                    : []
                }
              />
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default ComparisonSidebar;
