import React, { useMemo } from "react";
import classes from "./feedbackButton.module.scss";
import CommentsIcon from "../icons/comments.icon";
import { useTranslation } from "react-i18next";


export default function FeedbackButton({ isSidebarOpen = false }) {
  const { i18n } = useTranslation();

  const link = useMemo(() => {
    if(i18n.language === "en") {
      return "https://www.distill-lausitz.de/feedback/"
    }

    return "https://www.distill-lausitz.de/de-feedback/"
  }, [i18n.language])

  return (
    <a
      target="_blank"
      href={link}
      rel="noreferrer"
      className={`${classes["feedback-container"]} ${
        isSidebarOpen ? classes.open : ""
      }`}
    >
      <button>
        <CommentsIcon /> Feedback
      </button>
    </a>
  );
}
