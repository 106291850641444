import React from "react";
import { Line } from "react-chartjs-2";
import classes from "./lineChartWithTooltip.module.scss";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  indicator,
} from "chart.js";
import { useTranslation } from "react-i18next";

const colorStyles = [
  { borderColor: "rgb(255, 0, 0)", backgroundColor: "rgba(255, 0, 0, 0.1)" },
  { borderColor: "rgb(0, 255, 0)", backgroundColor: "rgba(0, 255, 0, 0.1)" },
  { borderColor: "rgb(0, 0, 255)", backgroundColor: "rgba(0, 0, 255, 0.1)" },
  {
    borderColor: "rgb(255, 255, 0)",
    backgroundColor: "rgba(255, 255, 0, 0.1)",
  },
  {
    borderColor: "rgb(0, 255, 255)",
    backgroundColor: "rgba(0, 255, 255, 0.1)",
  },
  {
    borderColor: "rgb(255, 0, 255)",
    backgroundColor: "rgba(255, 0, 255, 0.1)",
  },
  {
    borderColor: "rgb(192, 192, 192)",
    backgroundColor: "rgba(192, 192, 192, 0.1)",
  },
];

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChartWithTooltip = ({
  indicatorName = "",
  JSONData,
  selectedRegions = [],
  unit = "",
}) => {
  const { i18n } = useTranslation();
  const options = {
    responsive: true,
    locale:i18n.language,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Bevölkerungsprognose",
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const line = tooltipModel.body.map(getBody)?.[0][0];

            // Parse the JSON string into an object
            const lineObject = JSON.parse(
              line.substring(0, line.lastIndexOf(":"))
            );

            const { region, indicatorName } = lineObject;

            // Extract the value after the colon
            let value = line.substring(line.lastIndexOf(":") + 1).trim();

            let innerHtml = "<thead>";

            innerHtml += "</thead><tbody>";

            innerHtml += "<tr><th>" + region + "</th></tr>";
            titleLines.forEach(function (title) {
              innerHtml += "<tr><th>" + title + "</th></tr>";
            });
            if (indicatorName) {
              innerHtml += "<tr><th>" + indicatorName + "</th></tr>";
            }
            innerHtml += "<tr><th>" + value + ` ${unit}` + "</th></tr>";

            innerHtml += "</tbody>";

            let tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = 1;
          tooltipEl.style.zIndex = 50000;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
          tooltipEl.style.fontSize = "14px";
          tooltipEl.style.padding = "8px";
          tooltipEl.style.background = "rgba(0, 0, 0, 0.8)";
          tooltipEl.style.color = "white";
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Removes all vertical grid lines
        },
      },
    },
  };

  const labels = JSONData?.years;

  const datasets = [
    ...(JSONData?.data?.indicators?.[0].regionYearValues?.length
      ? JSONData?.data?.indicators?.[0].regionYearValues.map((data, index) => ({
          label:
            (selectedRegions?.[index]?.replace("-lk", "")?.toUpperCase() ||
              "") + " - Population Forecast",
          label: JSON.stringify({
            region: selectedRegions?.[index],
            indicatorName: indicatorName,
          }),
          data: data,
          borderColor: colorStyles[index].borderColor,
          backgroundColor: colorStyles[index].backgroundColor,
          fill: false,
          tension: 0.1,
        }))
      : []),
  ];

  const data = {
    labels,
    datasets,
  };

  return (
    <div className={classes["line-chart-container"]}>
      <Line options={options} data={data} />
      {selectedRegions.length > 0 && (
        <div className={classes.regions}>
          {selectedRegions.map((region, index) => (
            <div className={classes["single-region"]} key={region}>
              <div
                style={{ background: colorStyles[index].borderColor }}
                className={classes.circle}
              ></div>
              {region?.replace("-lk", "")}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LineChartWithTooltip;
