"use client";

import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import store from "../../../store";

const RoutingMachine = ({
  source = {},
  destination = {},
  longestRoute = false,
  shortestRoute = false,
  filterSelections,
  sourceStation,
  destinationStation,
}) => {
  const map = useMap();
  const routingControls = useRef([]);

  const { mapColors = {} } = store((state) => state) || {};

  useEffect(() => {
    // Clear existing routing controls
    routingControls.current.forEach((control) => map.removeControl(control));

    // Create routing controls based on filter selections
    const controls = [];

    // Check if both road and railway routing are selected
    const roadRoutingSelected =
      filterSelections &&
      filterSelections["Road Routing"] &&
      filterSelections["Road Routing"].roadRouting;
    const railwayRoutingSelected =
      filterSelections &&
      filterSelections["Railway Routing"] &&
      filterSelections["Railway Routing"].railwayRouting;
    const combinedRouting =
      filterSelections &&
      filterSelections["Combined Routing"] &&
      filterSelections["Combined Routing"].combinedRouting;

    if (!roadRoutingSelected && !railwayRoutingSelected && !combinedRouting) {
      return;
    }
    if (roadRoutingSelected) {
      controls.push(
        createRoutingControl(
          source,
          destination,
          longestRoute,
          shortestRoute,
          false
        )
      );
    }
    if (railwayRoutingSelected && sourceStation && destinationStation) {
      const stationSource = {
        latitude: sourceStation.lat,
        longitude: sourceStation.lon,
      };
      const stationDestination = {
        latitude: destinationStation.lat,
        longitude: destinationStation.lon,
      };
      controls.push(
        createRoutingControl(
          stationSource,
          stationDestination,
          longestRoute,
          shortestRoute
        )
      );

      if (combinedRouting) {
        // Draw route from source to sourceStation
        const sourceToSourceStation = createRoutingControl(
          source,
          stationSource,
          false,
          false,
          false,
          { sourceIcon: true }
        );
        controls.push(sourceToSourceStation);

        // Draw route from destination to destinationStation
        const destinationToDestinationStation = createRoutingControl(
          destination,
          stationDestination,
          false,
          false,
          false,
          { destinationIcon: true }
        );
        controls.push(destinationToDestinationStation);
      }

      // Draw route from sourceStation to destinationStation
      const sourceStationToDestinationStation = createRoutingControl(
        stationSource,
        stationDestination,
        false,
        false,
        true
      );
      controls.push(sourceStationToDestinationStation);
    }

    // Add created routing controls to the map
    controls.forEach((control) => control.addTo(map));

    // Save the created routing controls for future reference
    routingControls.current = controls;
  }, [
    source,
    destination,
    longestRoute,
    shortestRoute,
    filterSelections,
    sourceStation,
    destinationStation,
    map,
  ]);

  const sourceIcon = new L.Icon({
    iconUrl: "/images/source-icon.png",
    iconSize: [63, 86],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const destinationIcon = new L.Icon({
    iconUrl: "/images/destination-icon.png",
    iconSize: [63, 86],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: "marker-shadow.png",
    shadowSize: [41, 41],
  });

  const createRoutingControl = (
    source,
    destination,
    isLongest,
    isShortest,
    isRailway = false,
    iconDetails = null
  ) => {
    const waypoints = [
      L.latLng(source.latitude, source.longitude),
      L.latLng(destination.latitude, destination.longitude),
    ];

    const serviceUrl = isRailway
      ? "https://signal.eu.org/osm/eu/route/v1/"
      : "https://routing.openstreetmap.de/routed-car/route/v1/";

    const lineColor = isRailway
      ? mapColors.railway.routing
      : mapColors.railway.combinedRouting;

    let sIcon = new L.Icon.Default();
    let dIcon = new L.Icon.Default();

    if (iconDetails && iconDetails?.sourceIcon) {
      sIcon = sourceIcon;
    } else if (iconDetails && iconDetails?.destinationIcon) {
      sIcon = destinationIcon;
    }

    const markerOptions = iconDetails
      ? {
          //   createMarker: function (i, wp, n) {
          //     const iconOpts = i === 0 ? sourceIcon : destinationIcon;
          //     return L.marker(wp.latLng, { icon: iconOpts });
          //   },
          createMarker: function (i, waypoint, n) {
            const markerIcon =
              i === 0 ? sIcon : i === 1 ? dIcon : new L.Icon.Default();
            return L.marker(waypoint.latLng, { icon: markerIcon });
          },
        }
      : {};
    const options = {
      router: new L.Routing.osrmv1({
        serviceUrl: serviceUrl,
      }),
      routeWhileDragging: false,
      showAlternatives: true, // Always show alternatives
      lineOptions: {
        styles: [{ color: lineColor, opacity: 0.6, weight: 5 }],
      },
      ...markerOptions,
    };

    const control = L.Routing.control({ waypoints, ...options }).on(
      "routesfound",
      (e) => {
        if (e.routes.length >= 2) {
          let index;
          if (isLongest) {
            let maxLength = 0;
            e.routes.forEach((route, i) => {
              if (route.summary.totalDistance > maxLength) {
                maxLength = route.summary.totalDistance;
                index = i;
              }
            });
          }
          if (isShortest) {
            let minLength = Infinity;
            e.routes.forEach((route, i) => {
              if (route.summary.totalDistance < minLength) {
                minLength = route.summary.totalDistance;
                index = i;
              }
            });
          }
          // Select the route based on the determined index
          // e.routes.splice(0, 1);
        }
      }
    );

    return control;
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
            .leaflet-routing-container {
                background-color: white !important;
            }
            .leaflet-routing-container table{
                display:none;
            }
            .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
                margin-top: 100px;
            }
        `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return null;
};

export default RoutingMachine;
