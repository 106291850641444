import { useMap, GeoJSON } from "react-leaflet";
import L from "leaflet";
import { AirPortIcon } from "../icons/Airport.icon.js";
import centroid from "@turf/centroid";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../axios/axiosIntercepter";
import { useTranslation } from "react-i18next";
import { WegweiserService } from "../../../services/index.js";

export const GEOAirport = ({ data, loading, selectedFiles }) => {
  const [markers, setMarkers] = useState([]);
  const map = useMap();

  const { i18n } = useTranslation();

  useEffect(() => {
    return () => {
      markers.forEach((marker) => map.removeLayer(marker));
    };
  }, [markers, map]);

  useEffect(() => {
    if (!selectedFiles.includes("airport")) {
      markers.forEach((marker) => map.removeLayer(marker));
      setMarkers([]);
    }
  }, [data, selectedFiles]);

  //   Fetches the properties of an airport by its ID.
  const getData = async (id) => {
    try {
      const response = await WegweiserService.getAirportPropertiesById(id);
      if (response) {
        let name = response.data.properties.name;
        if (i18n.language === "en" && name) {
          name = name.replace("Flughafen", "");
          return "Airport " + name;
        }
        return name || "";
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return !loading && data && selectedFiles.includes("airport") ? (
    <GeoJSON
      data={data}
      style={() => ({
        color: "red",
        weight: 2,
        fillOpacity: 0.5,
        fillColor: "#E4F0C7",
      })}
      onEachFeature={async (feature, layer) => {
        const center = centroid(feature);
        const lat = center.geometry.coordinates[0];
        const lng = center.geometry.coordinates[1];
        // const airportPopupContent = `<b>${feature.properties.name}</b>`;
        const marker = L.marker([lng, lat], { icon: AirPortIcon })
          .addTo(map)
          .on({
            click: async (event) => {
              const airportPopupContent = `<p style="padding-top: 5px;">
              <b>${feature.properties.name}</b>
              </p>`;
              if (airportPopupContent) {
                layer.bindPopup(airportPopupContent).openPopup();
              }
            },
          });
        // .bindPopup(airportPopupContent); // Bind the popup to the marker;
        setMarkers((prevMarkers) => [...prevMarkers, marker]);
      }}
    />
  ) : (
    ""
  );
};
