// DataTable.js
import React, { useEffect, useState } from "react";
import Popup from "../piechart/statsPopup";
import PersonIcon from "../icons/person.icon";
import CategoryIcon from "../icons/category.icon";
import OperatorIcon from "../icons/operator.icon";
import AddressIcon from "../icons/address.icon";
import LatitudeIcon from "../icons/latitude.icon";
import LongitudeIcon from "../icons/longitude.icon";
import ServicesIcon from "../icons/services.icon";
import SettingIcon from "../icons/settting.icon";
import StorageIcon from "../icons/storage.icon";
import ConnectionIcon from "../icons/connection.icon";
import CapacityIcon from "../icons/capacity.icon";
import HoursIcon from "../icons/hours.icon";
import classes from "./dataTable.module.scss";
import { useTranslation } from "react-i18next";
import { WegweiserService } from "../../services";
import IndicatorsTableContent from "../indicatorsTableContent";
import TerminalTableContent from "../terminalSideBar/terminalTableContent";
import StatisticsDataOfRegion from "../piechart/statisticsDataOfRegion";
import Source from "../sharedComponent/source";

const DataTable = ({
  data,
  terminal = false,
  everyWeg = false,
  setSelectedAttributes = () => {},
  selectedAttributes = [],
  selectedRegionFriendlyURL = "",
  terminalTable = false,
  setAttribute = () => {},
  selectedRegionId = "",
  setSelectedRegions = () => {},
  selectedRegions = [],
  setPopupOpen = () => {},
  popupOpen = false,
  setJsonData = () => {},
  setTypeOfData = () => {},
  setLoading = () => {},
  setSelectedIndicatorInfo = () => {},
}) => {
  const { t } = useTranslation("translations");

  const [selectedIndicator, setSelectedIndicator] = useState("");
  // const [selectedRegions, setSelectedRegions] = useState([
  //   selectedRegionFriendlyURL,
  // ]);

  const fetchChart = async (
    chart = "tabelle",
    indicator = "Bevölkerungsprognose"
  ) => {
    setPopupOpen(true);
    setTypeOfData(indicator);
    setLoading(true);
    setJsonData(null);

    const startYear = "2013";
    const endYear = "2024";

    const selectedRegionQuery = selectedRegions
      .map((region) => `+${region.replace(/ /g, "-")}`)
      .join("");

    try {
      await WegweiserService.fetchStatsByFriendlyURL({
        indicator,
        friendlyURL: selectedRegionQuery,
        startYear,
        endYear,
        chart,
        isJSON: true,
      })
        .then((response) =>
          response?.data ? JSON.stringify(response.data) : ""
        )
        .then((result) => {
          setJsonData(result);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    if (popupOpen && selectedIndicator && selectedRegions.length)
      fetchChart("liniendiagramm", selectedIndicator);
  }, [selectedRegions, popupOpen, selectedIndicator]);

  const handleClosePopup = () => {
    setPopupOpen(false);
    setSelectedRegions([selectedRegionFriendlyURL]);
  };

  return (
    <div>
      <table
        className={`${classes.dataTable} ${
          terminalTable ? classes["terminal-table"] : ""
        }`}
      >
        {!everyWeg && (
          <thead>
            <tr className={classes.border}>
              {!everyWeg && !terminal && (
                <>
                  <th>{t("Selection")}</th>
                  <th>{t("Factor")}</th>
                </>
              )}
              {(everyWeg || terminal) && (
                <th>
                  <div>{t("Attribute")}</div>
                </th>
              )}
              <th style={{ minWidth: "75px" }}>
                <div>
                  {t("Value")}
                  {!terminal && !everyWeg ? " (2022)" : null}
                </div>
              </th>
              {!terminal && !everyWeg && <th>{t("Unit")}</th>}
            </tr>
          </thead>
        )}
        <tbody>
          {everyWeg ? (
            // Wegweiser Indicators
            <IndicatorsTableContent
              selectedRegionFriendlyURL={selectedRegionFriendlyURL}
              selectedRegionId={selectedRegionId}
              setSelectedIndicatorInfo={setSelectedIndicatorInfo}
            />
          ) : terminal ? (
            // Render selected terminal data
            <TerminalTableContent classes={classes} data={data} />
          ) : (
            // Render selected region statistics data
            <StatisticsDataOfRegion
              fetchChart={fetchChart}
              setSelectedIndicator={setSelectedIndicator}
              setAttribute={setAttribute}
              data={data}
              selectedAttributes={selectedAttributes}
              setSelectedAttributes={setSelectedAttributes}
              everyWeg={everyWeg}
              setSelectedIndicatorInfo={setSelectedIndicatorInfo}
            />
          )}
          {!terminal && (
            <tr>
              <td
                style={{ cursor: "unset" }}
                colSpan={!terminal && !everyWeg ? "4" : "3"}
              >
                <Source />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Selected row (indicator) details */}
    </div>
  );
};

export default DataTable;
