import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Chart, ArcElement } from "chart.js";
import data from "../../data/regionData.json";
import DataTable from "../dataTable";
import Popup from "./statsPopup";
import indicatorData from "../../data/indicatorData.json";
import ComparisonSidebar from "../comparisonSidebar";
import classes from "./piechart.module.scss";
import { useTranslation } from "react-i18next";
import { WegweiserService } from "../../services";
import StatisticsActionButtons from "./statisticsActionButtons";
import Modal from "../sharedComponent/modal";
import Source from "../sharedComponent/source";

const PieChartSidebar = ({
  selectedRegion,
  onClose,
  clickedLocationInfo,
  selectedRegionId,
  state = "",
  selectedRegionFriendlyURL = "",
}) => {
  Chart.register(ArcElement);

  // Find the data for the selected region
  const selectedRegionData = data.find(
    (region) => region.region === selectedRegion
  );
  // const [wegData2, setWegData2] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allIndicators, setAllIndicators] = useState(null);
  const [everyWeg, setEveryWeg] = useState(false);
  const [compare, setCompare] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [compareAttributes, setCompareAttributes] = useState(false);
  const [attribute, setAttribute] = useState("");
  const [attributePopup, setAttributePopup] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([
    selectedRegionFriendlyURL,
  ]);
  const [jsonData, setJsonData] = useState();
  const [typeOfData, setTypeOfData] = useState("");
  const [attributePopupDataLoading, setAttributePopupDataLoading] =
    useState(false);
  const [selectedIndicatorInfo, setSelectedIndicatorInfo] = useState(null);

  const { t } = useTranslation("translations");

  // Fetches statistical data for a specified region and set of indicators

  const fetchStats = async (regionId) => {
    try {
      setLoading(true);
      const response = await WegweiserService.getStatisticsData({
        regionIds: [regionId],
        years: [2022],
        indicatorIds: [
          166, 162, 25, 26, 27, 413, 13, 147, 154, 155, 248, 249, 158, 50, 315,
          324, 336, 325, 300, 225, 160, 301, 200, 261, 263, 262, 264, 260, 286,
        ],
      });
      const formattedData = response?.data?.indicators?.map((indicator) => ({
        name: indicator?.name,
        unit: indicator?.unit,
        value: indicator?.regionYearValues[0][0], // Assuming you want the first value from regionYearValues array
        friendlyURL: indicator?.friendlyUrl,
      }));

      setAllIndicators(formattedData);
    } catch (error) {
      console.error("Error fetching image:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const callAPIs = async () => {
      await fetchStats(selectedRegionId);
    };

    callAPIs();
  }, [selectedRegion, selectedRegionId]);

  // Check if the selected region exists
  if (!selectedRegionData) {
    return <div>Select a region to view data.</div>;
  }

  // Render checkboxes for each region and select region(s)
  const renderRegionCheckboxes = () => {
    const regions = [
      "Dahme-Spreewald, LK",
      "Cottbus",
      "Oberspreewald-Lausitz, LK",
      "Elbe-Elster, LK",
      "Spree-Neiße, LK",
      "Bautzen, LK",
      "Görlitz, LK",
    ];

    const friendlyURLs = {
      "Dahme-Spreewald, LK": "dahme-spreewald-lk",
      Cottbus: "cottbus",
      "Oberspreewald-Lausitz, LK": "oberspreewald-lausitz-lk",
      "Elbe-Elster, LK": "elbe-elster-lk",
      "Spree-Neiße, LK": "spree-neisse-lk",
      "Bautzen, LK": "bautzen-bz",
      "Görlitz, LK": "goerlitz-gr",
    };

    return (
      <div className={classes.regions}>
        {regions.map((region) => (
          <div className={classes.region} key={region}>
            <input
              type="checkbox"
              checked={selectedRegions.includes(friendlyURLs[region])}
              onChange={() => handleRegionCheckboxChange(region)}
            />
            <label>{region}</label>
          </div>
        ))}
      </div>
    );
  };

  // Function to handle selection/deselection of regions
  const handleRegionCheckboxChange = (region) => {
    if (loading) return;

    const friendlyURLs = {
      "Dahme-Spreewald, LK": "dahme-spreewald-lk",
      Cottbus: "cottbus",
      "Oberspreewald-Lausitz, LK": "oberspreewald-lausitz-lk",
      "Elbe-Elster, LK": "elbe-elster-lk",
      "Spree-Neiße, LK": "spree-neisse-lk",
      "Bautzen, LK": "bautzen-bz",
      "Görlitz, LK": "goerlitz-gr",
    };

    const friendlyURL = friendlyURLs[region];

    if (selectedRegions.includes(friendlyURL)) {
      setSelectedRegions(selectedRegions.filter((r) => r !== friendlyURL));
    } else {
      setSelectedRegions([...selectedRegions, friendlyURL]);
    }
  };

  const handleAttributePopupClose = () => {
    setAttributePopup(false);
    setSelectedRegions([selectedRegionFriendlyURL]);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    renderRegionCheckboxes();
  }, [renderRegionCheckboxes, selectedRegions]);

  const {
    village = "",
    municipality = "",
    county = "",
    state: selectedRegionState = "",
    postcode = "",
    country = "",
    city_district,
  } = clickedLocationInfo?.address || {};

  const formatAddressLine = (...parts) => {
    const formatted = parts.filter(Boolean).join(", ");
    return formatted ? `${formatted}<br />` : "";
  };

  return (
    <>
      {compare ? (
        // Comparision Table and Chart - Popup
        <ComparisonSidebar
          setCompare={setCompare}
          cuurentRegion={selectedRegion}
          selectedAttributes={selectedAttributes}
          state={state}
          selectedRegionFriendlyURL={selectedRegionFriendlyURL}
        />
      ) : (
        <div className={classes.sidebar}>
          <div className={classes["sidebar-header"]}>
            <span className={classes["close-icon"]} onClick={onClose}>
              &times;
            </span>
          </div>
          <h2 className={classes.heading}>
            {t("Statistical key figures on socio-economic factors")}
          </h2>
          <h3 className={classes.info}>
            {t("In the")} Landkreis {selectedRegion?.replace(", LK", "")}
          </h3>
          {/* <h3 className={classes.address}>{t("Address")}</h3> */}
          {/* Actions Button */}
          <StatisticsActionButtons
            loading={loading}
            setCompare={setCompare}
            setEveryWeg={setEveryWeg}
            setCompareAttributes={setCompareAttributes}
            selectedAttributes={selectedAttributes}
            everyWeg={everyWeg}
            compareAttributes={compareAttributes}
          />

          <p className={classes["address-line"]}>
            <p className={classes.bold}>{t("Selected Address")}</p>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: formatAddressLine(
                    village === municipality && village && municipality
                      ? city_district
                      : village,
                    municipality
                  ),
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: formatAddressLine(
                    county,
                    selectedRegionState,
                    postcode,
                    country
                  ),
                }}
              />
            </p>
          </p>
          <div className={classes["data-table"]}>
            {/* Statistics Table */}
            {allIndicators && (
              <DataTable
                data={allIndicators}
                indicatorData={indicatorData}
                everyWeg={everyWeg}
                selectedRegion={selectedRegion}
                state={state}
                selectedAttributes={selectedAttributes}
                setSelectedAttributes={setSelectedAttributes}
                selectedRegionFriendlyURL={selectedRegionFriendlyURL}
                setAttribute={setAttribute}
                attribute={attribute}
                selectedRegionId={selectedRegionId}
                setPopupOpen={setAttributePopup}
                popupOpen={attributePopup}
                setSelectedRegions={setSelectedRegions}
                selectedRegions={selectedRegions}
                setJsonData={setJsonData}
                jsonData={jsonData}
                setTypeOfData={setTypeOfData}
                setLoading={setAttributePopupDataLoading}
                loading={attributePopupDataLoading}
                setSelectedIndicatorInfo={setSelectedIndicatorInfo}
              />
            )}
            {selectedAttributes.length > 1 && compareAttributes && (
              <Popup
                compare
                data={selectedAttributes}
                selectedRegionFriendlyURL={selectedRegionFriendlyURL}
                onClose={() => setCompareAttributes(false)}
              />
            )}
          </div>
        </div>
      )}
      {attributePopup && !everyWeg && (
        <Popup
          onClose={handleAttributePopupClose}
          typeOfData={typeOfData}
          renderRegionCheckboxes={renderRegionCheckboxes}
          loading={attributePopupDataLoading}
          isStatistics
          lineChart
          jsonData={jsonData}
          name={!everyWeg ? attribute?.replace(":", " ") : attribute}
          selectedRegions={selectedRegions}
          showOnRight={!everyWeg}
        />
      )}
      {selectedIndicatorInfo && (
        <Modal onClose={() => setSelectedIndicatorInfo(null)}>
          <div className={classes["indicator-info-container"]}>
            <p className={classes.bold}>{t(selectedIndicatorInfo.name)}</p>
            <p>{selectedIndicatorInfo.explanation}</p>
            <p className={classes.heading}>{t("Calculation")}:</p>
            <p>{selectedIndicatorInfo.calculation}</p>
          </div>
          <Source />
        </Modal>
      )}
    </>
  );
};

export default PieChartSidebar;
