import React from "react";
import classes from "./singleTab.module.scss";

export default function SingleTab({
  onClick = () => {},
  isActive = false,
  label = "",
}) {
  return (
    <div
      className={`${classes["sidebar-tab"]} ${
        isActive && classes["active-tab"]
      }`}
    >
      <span className={classes["tab-text"]} onClick={onClick}>
        {label}
      </span>
    </div>
  );
}
