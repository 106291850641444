import React, { useEffect, useState } from "react";
import data from "../../data/topics.json";
import GraphPopup from "./graphPopup/index";
import { useTranslation } from "react-i18next";
import ForecastOptionTable from "./forecastOptionTable";
import { axiosInstance } from "../../axios/axiosIntercepter";
import { WegweiserService } from "../../services";
const IndicatorsTableContent = ({
  selectedRegionId,
  setSelectedIndicatorInfo = () => {},
}) => {
  const [expandedId, setExpandedId] = useState(null);
  const [graph, setGraph] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const minYear = 2020;
  const maxYear = 2040;
  const [sliderValue, setSliderValue] = useState(2020);
  const [friendlyUrl, setFriendlyUrl] = useState("");
  const [hasTable, setHasTable] = useState(false);

  // ----------------------------------------------------------------

  const [selectedRegionsIds, setSelectedRegionsIds] = useState([
    5989, 5960, 6154, 6027, 6308, 7307, 7365,
  ]);

  const [indicatorIds, setIndicatorIds] = useState([]);

  function generateYearArray(maxYear) {
    const startYear = 2006;
    const years = [];

    for (let year = startYear; year <= maxYear; year++) {
      years.push(year);
    }

    return years;
  }

  const getStatisticsData = async (
    indicators,
    maxYear = 2006,
    isBaseYear = true
  ) => {
    try {
      setLoading(true);
      if (indicators.length) {
        setIndicatorIds(indicators);
      }
      const years = isBaseYear ? generateYearArray(Number(maxYear)) : [maxYear];
      const data = {
        indicatorIds: indicators?.length > 0 ? indicators : indicatorIds,
        regionIds: selectedRegionsIds,
        years,
      };

      if (
        !Boolean(
          data.indicatorIds?.length &&
            data.years?.length &&
            data?.regionIds?.length
        )
      ) {
        return;
      }

      const response = await WegweiserService.getStatisticsData(data);
      if (response?.status === 200) {
        setTableData({ ...response.data, years });
      }
    } catch (err) {
      setTableData(null);
    } finally {
      setLoading(false);
    }
  };

  const handlePopup = () => {
    setPopupOpen(true);
  };

  useEffect(() => {
    if (hasTable) {
      setSelectedRegionsIds([5989, 5960, 6154, 6027, 6308, 7307, 7365]);
    }
  }, [hasTable]);

  const handleClosePopup = () => {
    setGraph(null);
    setPopupOpen(false);
    setTableData(null);
    setSelectedRegionsIds([]);
    setIndicatorIds([]);
    setHasTable(false);
    setSliderValue(2020);
  };

  const getGraphicData = async (
    friendlyUrl,
    maxYear = 2020,
    isBaseYear = false
  ) => {
    handlePopup();
    setLoading(true);
    setFriendlyUrl(friendlyUrl);
    let topicIds = [];
    if (
      friendlyUrl === "bevoelkerung-nach-altersgruppen-prognose" ||
      friendlyUrl === "altersstrukturgrafik"
    ) {
      topicIds = [1];
    } else if (friendlyUrl === "anteile-der-altersgruppen") {
      topicIds = [2];
    } else if (friendlyUrl === "entwicklung-der-altersgruppen-prognose") {
      topicIds = [3];
    }
    try {
      const years = isBaseYear ? [maxYear, 2020] : [maxYear];
      const data = {
        regionIds: [selectedRegionId],
        years,
        ...(friendlyUrl === "anteile-der-altersgruppen-2" ? {} : { topicIds }),
        ...(!friendlyUrl === "entwicklung-der-altersgruppen"
          ? {}
          : {
              indicatorIds: [356, 359, 362, 365, 368, 371, 374, 377, 380, 383],
            }),
      };
      const response = await WegweiserService.getStatisticsData(data);

      if (response?.status === 200) {
        setJsonData(JSON.stringify(response.data));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = (id, friendlyUrl) => {
    getGraphicData(friendlyUrl);
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };

  const { t } = useTranslation("translations");

  return (
    <>
      {data.map((item) => (
        <React.Fragment key={item.id}>
          <tr
            style={{
              borderTop: "1px solid #f5f7fa",
              borderBottom: "1px solid #f5f7fa",
            }}
            onClick={() => {
              handleToggle(item.id, item.friendlyUrl);
              setTitle(item.title || "");
            }}
          >
            <td style={{ fontWeight: "bold" }}>{t(item.title)}</td>
          </tr>
          <>
            {item.topics.map((topic) => (
              <tr>
                <td
                  key={topic.id}
                  style={{ fontWeight: "bold" }}
                  onClick={() => {
                    setTitle(topic.title || "");
                    getGraphicData(topic.friendlyUrl, minYear, false);
                    setSliderValue(minYear);
                  }}
                >
                  {t(topic.title)}
                </td>
              </tr>
            ))}
          </>
        </React.Fragment>
      ))}
      {/* Render other forecast indicators */}
      {/* <ForecastOptionTable
        setTitle={setTitle}
        getStatisticsData={getStatisticsData}
        handlePopup={handlePopup}
        // setSliderValue={setSliderValue}
        minYear={2006}
        setIndicatorIds={setIndicatorIds}
        setHasTable={setHasTable}
        onClick={getStatisticsData}
        setSliderValue={setSliderValue}
        setSelectedIndicatorInfo={setSelectedIndicatorInfo}
      /> */}
      {/* Render selected indicator details */}
      {popupOpen && (
        <GraphPopup
          loading={loading}
          jsonData={!hasTable ? jsonData : tableData ? {} : null}
          onClose={handleClosePopup}
          onSliderChange={!hasTable ? getGraphicData : getStatisticsData}
          friendlyUrl={friendlyUrl}
          setSliderValue={setSliderValue}
          sliderValue={sliderValue}
          minYear={minYear}
          maxYear={maxYear}
          title={title}
          hasTable={hasTable}
          setSelectedRegionsIds={setSelectedRegionsIds}
          selectedRegionsIds={selectedRegionsIds}
          tableData={tableData}
        />
      )}
    </>
  );
};

export default IndicatorsTableContent;
