import React, { useMemo } from "react";
import classes from "./rightSection.module.scss";
import CircularIcon from "../circular-icon";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function RightSection() {
  const { t } = useTranslation("sidebar");

  const IMAGES_DETAILS = useMemo(() => {
    return [
      {
        style: {
          width: "800px",
          margin: "0 -30px",
          rotate: "45deg",
        },
        images: [
          {
            src: "/images/welcome/airport.svg",
            toolTipText: t("legend-section.airport.label"),
            borderColor: "#595D69B2",
            link: "/map?filter=airport",
          },
          {
            src: "/images/welcome/terminal.svg",
            toolTipText: t("legend-section.kv-terminal.label"),
            borderColor: "#0000FFB2",
            link: "/map?filter=kvTerminal",
          },
        ],
      },
      {
        style: {
          width: "810px",
          margin: "0 -35px",
          rotate: "355deg",
        },
        images: [
          {
            src: "/images/welcome/railway.svg",
            toolTipText: t("legend-section.railways.label"),
            borderColor: "#FF0000B2",
            link: "/map?filter=railway",
          },
          {
            src: "/images/welcome/volume.svg",
            toolTipText: t("legend-section.transport-volumes.label"),
            borderColor: "#BC0F1BB2",
            link: "/map?filter=transportVolumes",
          },
        ],
      },
      {
        style: {
          width: "705px",
          margin: "0 10px 0 20px",
          rotate: "300deg",
        },
        images: [
          {
            src: "/images/welcome/road_track.svg",
            toolTipText: t("legend-section.road-tracks.label"),
            borderColor: "#868BA0CC",
            link: "/map?filter=roadTacks",
          },
          {
            src: "/images/welcome/inland_ports.svg",
            toolTipText: t("legend-section.inland-ports.label"),
            borderColor: "#7CBFEACC",
            link: "/map?filter=inlandPort",
          },
        ],
      },
    ];
  }, [t]);
  return (
    <div className={classes["image-container"]}>
      <div className={classes["inner-circle"]}>
        <img
          className={classes["grey-map"]}
          src="/images/welcome/grey-map.png"
          alt=""
        />
        <img
          className={classes["green-map"]}
          src="/images/welcome/lausitz-map.png"
          alt=""
        />
      </div>
      {IMAGES_DETAILS.map((details, index) => (
        <Images key={index} {...details} />
      ))}
    </div>
  );
}

const Images = ({ images = [], style = {} }) => {
  const { i18n } = useTranslation();

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        display: "flex",
        justifyContent: "space-between",
        ...style,
      }}
    >
      {images.map((data) => (
        <Link to={data.link + `&lang=${i18n.language}`}>
          <CircularIcon
            key={data.src}
            imageSrc={data.src}
            borderColor={data.borderColor}
            rotate={style.rotate}
            toolTipText={data.toolTipText}
          />
        </Link>
      ))}
    </div>
  );
};
