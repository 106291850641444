import React from "react";
import classes from "./source.module.scss";
import { useTranslation } from "react-i18next";

export default function Source({ mt = false }) {
  const { t } = useTranslation("translations");
  return (
    <p className={`${classes.text} ${mt ? classes.mt : ""}`}>
      {t("Source")}: {t("Bertelsmann Stiftung")} -{" "}
      <b>www.wegweiser-kommune.de</b>
    </p>
  );
}
