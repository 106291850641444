import React from "react";

export default function FilterIcon({ open, ...rest }) {
  return (
    <>
      {open ? (
        <svg
          {...rest}
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.17725 4.93359L4.97725 0.733594L0.777246 4.93359"
            stroke="#484848"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          {...rest}
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.777344 1.2207L4.97734 5.4207L9.17734 1.2207"
            stroke="#484848"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </>
  );
}
