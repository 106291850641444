import React from "react";
import classes from "./tabs.module.scss";
import SingleTab from "../../sharedComponent/singleTab";

export default function Tabs({ data, onClick = () => {}, activeTab }) {
  return (
    <div className={classes.sidebar_tabs}>
      {data?.map((tab, i) => (
        <SingleTab
          isActive={activeTab === i}
          label={tab}
          key={i}
          onClick={() => onClick(i)}
        />
      ))}
    </div>
  );
}
