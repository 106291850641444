import React from "react";
import classes from "./select.module.scss";
import { useTranslation } from "react-i18next";

export default function Select({
  index,
  options = [],
  isComparison = false,
  region = {},
  onChange = () => {},
}) {
  const { t } = useTranslation("translations");

  return (
    <select className={classes.select} value={region.name} onChange={onChange}>
      {isComparison && index !== 0 && (
        <option value="">
          {t("Select Region")} {index + 1}
        </option>
      )}
      {isComparison && region?.name && index !== 0 && (
        <option value={region?.name}>{region?.name}</option>
      )}
      {options.map((value, index) => (
        <option value={value} key={index}>
          {value}
        </option>
      ))}
    </select>
  );
}
