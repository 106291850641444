import React from "react";
import CarbonEmissionAndDistance from "../carbonEmissionAndDistance";
import classes from "./transportEmission.module.scss";
import { useTranslation } from "react-i18next";

export default function TransportEmission({
  viaValue = "",
  hours = "",
  shortestRouteKm = "",
  totalCarbonEmission = "",
  thresholdLevel = "",
  emissionRatePerKm = "",
}) {
  const { t } = useTranslation("translations");

  return (
    <div className={classes["transport-emission-container"]}>
      <div className={classes["transport-details"]}>
        <div className={classes["icon-and-details"]}>
          <div className={classes["icon-container"]}>
            <img src="/images/truck-icon.png" />
          </div>
          <div className={classes["details"]}>
            <p className={classes["heading"]}>
              {t("via")} {viaValue}
            </p>
          </div>
        </div>
        <p className={`${classes["heading"]} ${classes["red"]}`}>{hours}</p>
      </div>
      <div className={classes["route-container"]}>
        <p>{t("Shortest route")}</p>
        <p>{shortestRouteKm}</p>
      </div>
      <div className={classes["total-carbon-emissions"]}>
        <p className={classes["red"]}>
          <span className={classes["heading"]}>
            {t("Total Carbon Emissions")}
          </span>
        </p>
        <p className={classes["value"]}>{totalCarbonEmission}</p>
      </div>
      <CarbonEmissionAndDistance thresholdLevel={thresholdLevel} />
      <div className={classes["transport-emission"]}>
        <p className={classes["heading"]}>{t("Transport Emissions")}</p>
        <p className={classes["sub-heading"]}>
          {t("Emission rate per kilometer")}
        </p>
        <div className={classes["icon-and-details"]}>
          <div className={classes["icon-container"]}>
            <img src="/images/truck-icon.png" />
          </div>
          <div className={classes["details"]}>
            <p className={classes[""]}>Truck</p>
            <p className={classes["value"]}>{emissionRatePerKm}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
