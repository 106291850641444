import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classes from "./statisticsActionButtons.module.scss";

export default function StatisticsActionButtons({
  loading = false,
  setCompare = () => {},
  setEveryWeg = () => {},
  setCompareAttributes = () => {},
  selectedAttributes = [],
  everyWeg = false,
  compareAttributes = false,
}) {
  const { t } = useTranslation("translations");

  const isActive = useMemo(() => {
    if (compareAttributes) {
      return "Compare Attributes";
    } else if (everyWeg) {
      return "Forecast data";
    } else {
      return "Overview";
    }
  }, [everyWeg, compareAttributes]);

  return (
    <div className={classes["buttons-container"]}>
      <button
        className={`${classes.button} ${
          isActive === "Overview" ? classes.active : ""
        }`}
        onClick={() => setEveryWeg(false)}
      >
        {t("Overview")}
      </button>
      <button className={classes.button} onClick={() => setCompare(true)}>
        {t("Comparative data")}
      </button>
      <button
        className={`${classes.button} ${
          isActive === "Forecast data" ? classes.active : ""
        }`}
        onClick={() => setEveryWeg(true)}
      >
        {t("Forecast data")}
      </button>
      {selectedAttributes.length > 1 && (
        <button
          className={`${classes.button} ${
            isActive === "Compare Attributes" ? classes.active : ""
          }`}
          onClick={() => setCompareAttributes(true)}
        >
          {t("Compare Attributes")}
        </button>
      )}
    </div>
  );
}
