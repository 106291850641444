import React from "react";

export default function LeftArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="72"
      viewBox="0 0 56 72"
      fill="none"
    >
      <g filter="url(#filter0_d_54_36226)">
        <path
          d="M13.3096 13.6611H33.5596C36.321 13.6611 38.5596 15.8997 38.5596 18.6611V49.0411C38.5596 51.8026 36.321 54.0411 33.5596 54.0411H13.3096V13.6611Z"
          fill="white"
        />
      </g>
      <path
        d="M27.998 25.8516L21.2481 33.8516L27.998 41.8517"
        stroke="#8C8C8C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_54_36226"
          x="0.30957"
          y="0.661133"
          width="55.25"
          height="70.3799"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_54_36226"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_54_36226"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
