import React from "react";
import CookieConsent from "react-cookie-consent";
import "./index.css";
import { useTranslation } from "react-i18next";

export default function Cookies() {
  const { t } = useTranslation("translations");

  return (
    <CookieConsent
      location="bottom"
      buttonText={t("Accept All Cookies")}
      declineButtonText={t("Reject All Cookies")}
      cookieName="myAwesomeCookieName"
      expires={150}
      enableDeclineButton
    >
      {t("This website uses cookies to enhance the user experience.")}
    </CookieConsent>
  );
}
