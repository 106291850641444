import React, { useState, useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import PulseLoader from "../sharedComponent/pulseLoader";
import classes from "./index.module.scss";
import store from "../../store";
import { useTranslation } from "react-i18next";
import JSZip from "jszip";

const ALLOWED_KEYS = [
  "bridge",
  "electrified",
  "gauge",
  "voltage",
  "frequency",
  "maxspeed",
  "name",
  "ref",
];

// Function to simplify the coordinates using Douglas-Peucker algorithm
// function simplifyCoordinates(coordinates, tolerance) {
//   if (coordinates.length < 3) {
//     return coordinates;
//   }
//   const simplifiedCoordinates = [coordinates[0]];
//   simplifyLine(
//     coordinates,
//     0,
//     coordinates.length - 1,
//     tolerance,
//     simplifiedCoordinates
//   );
//   simplifiedCoordinates.push(coordinates[coordinates.length - 1]);
//   return simplifiedCoordinates;
// }

// Recursive function to apply Douglas-Peucker algorithm
// function simplifyLine(coordinates, start, end, tolerance, simplified) {
//   let maxDistance = 0;
//   let farthestIndex = 0;
//   for (let i = start + 1; i < end; i++) {
//     const distance = distanceToSegment(
//       coordinates[i],
//       coordinates[start],
//       coordinates[end]
//     );
//     if (distance > maxDistance) {
//       maxDistance = distance;
//       farthestIndex = i;
//     }
//   }
//   if (maxDistance > tolerance) {
//     simplifyLine(coordinates, start, farthestIndex, tolerance, simplified);
//     simplifyLine(coordinates, farthestIndex, end, tolerance, simplified);
//   } else {
//     simplified.push(coordinates[farthestIndex]);
//   }
// }

// Function to calculate the distance from a point to a line segment
// function distanceToSegment(point, start, end) {
//   const [x, y] = point;
//   const [x1, y1] = start;
//   const [x2, y2] = end;
//   const dx = x2 - x1;
//   const dy = y2 - y1;
//   const lengthSquared = dx * dx + dy * dy;
//   const t = lengthSquared
//     ? ((x - x1) * dx + (y - y1) * dy) / lengthSquared
//     : -1;
//   if (t < 0) {
//     return Math.sqrt((x - x1) * (x - x1) + (y - y1) * (y - y1));
//   } else if (t > 1) {
//     return Math.sqrt((x - x2) * (x - x2) + (y - y2) * (y - y2));
//   } else {
//     const projectionX = x1 + t * dx;
//     const projectionY = y1 + t * dy;
//     return Math.sqrt(
//       (x - projectionX) * (x - projectionX) +
//         (y - projectionY) * (y - projectionY)
//     );
//   }
// }

// function generateChunkedQuery(center, totalRadius) {
//   const defaultChunkSize = 50000; // default chunk size in meters
//   let chunkSize =
//     totalRadius < defaultChunkSize ? totalRadius / 2 : defaultChunkSize;

//   let query = "[out:json][timeout:300];\n\n";
//   let currentRadius = 0;

//   while (currentRadius < totalRadius) {
//     let nextRadius = currentRadius + chunkSize;
//     if (nextRadius > totalRadius) {
//       nextRadius = totalRadius;
//     }

//     if (currentRadius === 0) {
//       // Initial chunk from 0 to nextRadius
//       query += `// Query for radius 0 to ${nextRadius} meters\n(\n  way["railway"="rail"]["usage"="main"](around:${nextRadius},${center.lat},${center.lon});\n);\nout geom;\n\n`;
//     } else {
//       // Subsequent chunks from previousRadius to nextRadius
//       query += `// Query for radius ${currentRadius} to ${nextRadius} meters\n(\n  way["railway"="rail"]["usage"="main"](around:${nextRadius},${center.lat},${center.lon}) -> .a;\n  way["railway"="rail"]["usage"="main"](around:${currentRadius},${center.lat},${center.lon}) -> .b;\n  (.a; - .b;);\n);\nout geom;\n\n`;
//     }

//     currentRadius = nextRadius;
//   }

//   return query;
// }

const RailwayMap = ({
  selectedFiles = {},
  isNationalRailwayAdded = false,
  isInternationalRailwayAdded = false,
}) => {
  const [geojsonData, setGeojsonData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { mapColors = {} } = store((state) => state) || {};
  const { t } = useTranslation("translations");
  const { i18n } = useTranslation();

  // Function to bind events to each feature
  const onEachFeature = (feature, layer) => {
    if (Object.keys(feature.properties).length) {
      let popupContent = "";
      const details = feature.properties;

      // Create table for details
      if (Object.keys(details).length > 0) {
        popupContent += `<table class=${
          classes["railway-table-container"]
        }><tr><th>${t("Attribute")}</th><th>${t("Value")}</th></tr>`;
        for (let [key, value] of Object.entries(details)) {
          if (ALLOWED_KEYS.includes(key)) {
            let k = key.replaceAll(":", " ");
            k = t("tags." + k);
            k = k.replace("tags.", "");

            let v = isNaN(value) ? t("tags." + value) : value;
            v = v.replace("tags.", "");
            v = value?.includes("https://") ? "https:" + v : v;
            popupContent += `<tr><td>${k}</td><td style="text-transform: capitalize;">${v}</td></tr>`;
          }
        }
        popupContent += `</table>`;
      }

      layer.bindPopup(popupContent);
    }
  };

  const getLineStyle = (feature) => {
    const { electrified = undefined } = feature.properties || {};
    return {
      color:
        electrified === "no" || electrified === undefined
          ? mapColors.railwayMap.nonElectrified
          : mapColors.railwayMap.electrified,
      weight: 3,
      zIndex: 2,
    };
  };

  useEffect(() => {
    const fetchAndProcessGeoJSON = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://geojsons.distill.i-ways-network.org/train-geojson.zip"
        );
        const blob = await response.blob();

        // Uncompress the ZIP file
        const zip = await JSZip.loadAsync(blob);

        // Assume there is only one file in the ZIP and it is a GeoJSON file
        const geojsonFileName = Object.keys(zip.files)[0];
        const geojsonContent = await zip.file(geojsonFileName).async("text");

        // Parse GeoJSON
        const geojsonObject = JSON.parse(geojsonContent);
        setGeojsonData(geojsonObject);
      } catch (error) {
        console.error("Error fetching or processing the GeoJSON file:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndProcessGeoJSON();
  }, []);

  useEffect(() => {
    if (geojsonData) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 0);
    }
  }, [isNationalRailwayAdded, isInternationalRailwayAdded, i18n.language, geojsonData]);

  return (
    <div>
      {loading && (
        <div className="message-popup">
          <span>
            <PulseLoader />
          </span>
        </div>
      )}
      {geojsonData && !loading && (
        <GeoJSON
          data={geojsonData}
          onEachFeature={onEachFeature}
          style={getLineStyle}
          zIndex={
            isNationalRailwayAdded || isInternationalRailwayAdded ? 999 : 1
          } // Set higher zIndex if railway added
        />
      )}
    </div>
  );
};

export default RailwayMap;
