import React from "react";

export default function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="72"
      viewBox="0 0 43 72"
      fill="none"
    >
      <g filter="url(#filter0_d_33_117630)">
        <path
          d="M0 13.6611H20.25C23.0114 13.6611 25.25 15.8997 25.25 18.6611V49.0411C25.25 51.8026 23.0114 54.0411 20.25 54.0411H0V13.6611Z"
          fill="white"
        />
      </g>
      <path
        d="M7.93848 25.8511L14.6884 33.8512L7.93848 41.8512"
        stroke="#8C8C8C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_33_117630"
          x="-13"
          y="0.661133"
          width="55.25"
          height="70.3799"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33_117630"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_33_117630"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
