import React, { useState, useEffect } from "react";
import file from "./../../../data/Database/VerkehreCottbus.json";
import { Polyline, Marker, Popup } from "react-leaflet";
import {
  TransportVolumeEndPointIcon,
  TransportVolumeIcon,
} from "../icons/TransportVolume.icon";
import classes from "./transportVolumes.module.scss";
import { useTranslation } from "react-i18next";
import { IoMdInformation } from "react-icons/io";
import Modal from "../../sharedComponent/modal";
import PolyLines from "./polylines";
import transportVolumeYearlyData from "./../../../data/transport_volume_yearly_data.json";
import nuts3Data from "./../../../data/Database/nuts3Data.json";

export const TransportVolumes = ({
  selectedFiles,
  showAllTransportVols,
  filterSelections,
  setPopupOpen = () => {},
}) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { t } = useTranslation("translations");
  const { i18n } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(2022);

  const regionsToDraw = {
    DE402: {
      NUTS3: "DE402",
      Region: "Cottbus, Kreisfreie Stadt",
      lat: "51.7512991",
      lon: "14.3223077",
    },
    DE406: {
      NUTS3: "DE406",
      Region: "Dahme-Spreewald",
      lat: "52.073909549999996",
      lon: "13.866791497499554",
    },
    DE407: {
      NUTS3: "DE407",
      Region: "Elbe-Elster",
      lat: "51.62717585",
      lon: "13.480545282630338",
    },
    DE40B: {
      NUTS3: "DE40B",
      Region: "Oberspreewald-Lausitz",
      lat: "51.630905150000004",
      lon: "14.033929813357613",
    },
    DE40G: {
      NUTS3: "DE40G",
      Region: "Spree-Neiße",
      lat: "51.7812916",
      lon: "14.197048760921327",
    },
    DED2C: {
      NUTS3: "DED2C",
      Region: "Bautzen",
      lat: "51.1813907",
      lon: "14.4275735",
    },
    DED2D: {
      NUTS3: "DED2D",
      Region: "Görlitz",
      lat: "51.1563185",
      lon: "14.991018",
    },
  };

  const currentYear = 2022; // Set the current year
  const years = Array.from(
    { length: currentYear - 2010 + 1 },
    (_, i) => 2010 + i
  ).reverse();

  if (!selectedFiles.includes("transportVolumes")) {
    return null;
  }

  return (
    <>
      {Object.keys(regionsToDraw).map((key, index) => {
        const {
          lat = "",
          lon = "",
          Region: region = "",
          NUTS3,
        } = nuts3Data[key] || {};
        let details = transportVolumeYearlyData[selectedYear?.toString()] || [];
        if (details.length) {
          details = details.filter((data) => data["Beladeregion"] === NUTS3);
          details.sort((a, b) => b.Tkm - a.Tkm);
        }
        return (
          <div key={index}>
            <Marker
              position={[lat, lon]}
              icon={TransportVolumeIcon}
              eventHandlers={{
                click: () => setSelectedMarker(region),
              }}
            >
              {selectedMarker === region &&
                details.length > 0 &&
                details.map((data, index) => {
                  let from_lat = nuts3Data[data["Beladeregion"]].lat;
                  let from_lon = nuts3Data[data["Beladeregion"]].lon;
                  let to_lat = nuts3Data[data["Entladeregion"]].lat;
                  let to_lon = nuts3Data[data["Entladeregion"]].lon;
                  return (
                    <>
                      <PolyLines
                        key={index}
                        from={[from_lat, from_lon]}
                        to={[to_lat, to_lon]}
                        tkm={data.Tkm}
                      />
                      {from_lat !== to_lat && from_lon !== to_lon && (
                        <Marker
                          position={[to_lat, to_lon]}
                          icon={TransportVolumeEndPointIcon}
                        >
                          <Popup>
                            <div>
                              <h4>{`${
                                nuts3Data[data["Entladeregion"]]?.Region
                              }`}</h4>
                            </div>
                          </Popup>
                        </Marker>
                      )}
                    </>
                  );
                })}
              <Popup>
                <div>
                  <h3>Region: {region}</h3>
                  <h4>NUTS3: {NUTS3}</h4>
                  <select
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className={classes.select}
                    defaultValue={selectedYear}
                  >
                    <option value="">{t("Select Year")}</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <div
                    className={classes["transport-volume-table-main-container"]}
                  >
                    <table
                      className={classes["transport-volume-table-container"]}
                    >
                      <tr>
                        <th>{t("Attribute")}</th>
                        <th>
                          <div className={classes.flex}>
                            {t("Value")} (TKM)
                            <IoMdInformation
                              onClick={() => setPopupOpen(true)}
                              className={classes["open-information-icon"]}
                            />
                          </div>
                        </th>
                      </tr>
                      <tbody>
                        {details.map((d) => {
                          let from = nuts3Data[d["Beladeregion"]]?.Region;
                          let to = nuts3Data[d["Entladeregion"]]?.Region;

                          from = from.replaceAll("-", " ");
                          to = to.replaceAll("-", " ");

                          let value = d.Tkm;
                          if (i18n.language === "en") {
                            from = from.replaceAll("Lausitz", "Lusatia");
                            to = to.replaceAll("Lausitz", "Lusatia");
                            value = d.Tkm.toLocaleString("en-US");
                          } else {
                            value = d.Tkm.toLocaleString("de-DE");
                          }

                          return (
                            <tr>
                              <td>
                                {from}-{to}
                              </td>
                              <td>{value}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Popup>
            </Marker>
          </div>
        );
      })}
    </>
  );
};
