import React from "react";
import { Bar } from "react-chartjs-2";
import classes from "./barChart.module.scss";
import regionsData from "../../data/regionData.json";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

// Function to extract labels and data for a specific field
const extractChartData = (field) => {
  const labels = [];
  const data = [];

  regionsData.forEach((region) => {
    labels.push(region.region);
    data.push(region.data["2021"][field]);
  });

  return {
    labels,
    datasets: [
      {
        label: field,
        data,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};

export function BarChart() {
  const translatedLabel = [
    "Employment rate (%)",
    "Job development over the past 5 years (%)",
    "Share of employment in the 1st sector (%)",
    "Share of employment in the 2nd sector (%)",
    "Share of employment in the 3rd sector (%)",
    "Population (number)",
    "Population development since 2011 (%)",
    "Births (per 1,000 inhabitants)",
    "Deaths (per 1,000 inhabitants)",
    "Immigration (per 1,000 inhabitants)",
    "Out-migration (per 1,000 inhabitants)",
    "Median age (years)",
    "Population density (inhabitants per hectare)",
    "State investment allocations (euros per inhabitant)",
    "Trade tax (net) (euros per inhabitant)",
    "Trade tax assessment rate (%)",
    "Income tax (euros per inhabitant)",
    "Electricity from renewable sources (kW per inhabitant)",
    "Long-term unemployment rate (%)",
    "Employment rate 55 to 64-year-olds (%)",
    "Drinking water consumption (liters per inhabitant per day)",
    "Pupils at elementary school (%)",
    "School leavers with special school certificate - total (%)",
    "School leavers from grammar/vocational schools schools without a secondary general school certificate - total (%)",
    "School leavers with secondary general school certificate - total (%)",
    "School leavers with secondary school certificate - total (%)",
    "School leavers from grammar/vocational schools schools with advanced technical college/university entrance qualification - total (%)",
    "Purchasing power (euros/household)",
  ];
  const parameters = [
    "Beschäftigungsquote (%)",
    "Arbeitsplatzentwicklung der vergangenen 5 Jahre (%)",
    "Beschäftigungsanteil im 1. Sektor (%)",
    "Beschäftigungsanteil im 2. Sektor (%)",
    "Beschäftigungsanteil im 3. Sektor (%)",
    "Bevölkerung (Anzahl)",
    "Bevölkerungsentwicklung seit 2011 (%)",
    "Geburten (je 1.000 Einwohner:innen)",
    "Sterbefälle (je 1.000 Einwohner:innen)",
    "Zuzüge (je 1.000 Einwohner:innen)",
    "Fortzüge (je 1.000 Einwohner:innen)",
    "Medianalter (Jahre)",
    "Einwohner:innendichte (Einwohner:innen je Hektar)",
    "Staatliche Investitionszuweisungen (Euro je Einwohner:in)",
    "Gewerbesteuer (netto) (Euro je Einwohner:in)",
    "Hebesatz Gewerbesteuer (v.H.)",
    "Einkommensteuer (Euro je Einwohner:in)",
    "Strom aus erneuerbaren Quellen (kW je Einwohner:in)",
    "Langzeitarbeitslosenquote (%)",
    "Beschäftigungsquote 55- bis 64-Jährige (%)",
    "Trinkwasserverbrauch (l je Einwohner:in und Tag)",
    "Schüler:innen an Grundschulen (%)",
    "Schulabgänger:innen mit Förderschulabschluss - Gesamt (%)",
    "Schulabgänger:innen allgmb./berufsb. Schulen ohne Hauptschulabschluss - Gesamt (%)",
    "Schulabgänger:innen mit Hauptschulabschluss - Gesamt (%)",
    "Schulabgänger:innen mit Realschulabschluss - Gesamt (%)",
    "Schulabgänger:innen allgmb./berufsb. Schulen mit Fachhoch-/Hochschulreife - Gesamt (%)",
    "Kaufkraft (Euro/Haushalt)",
  ];

  return (
    <div className={classes["charts-container"]}>
      {parameters.map((parameter, index) => (
        <div key={index} className={classes.chart}>
          <h3>{translatedLabel[index]}</h3>
          <Bar options={options} data={extractChartData(parameter)} />
        </div>
      ))}
    </div>
  );
}
