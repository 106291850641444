import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { debounce } from "lodash";
import classes from "./leftSection.module.scss";
import SingleCard from "./single-card";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import MiniLoader from "../../sharedComponent/miniLoader";

export default function LeftSection() {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1); // To track the selected suggestion
  const dropdownRef = useRef(null); // Ref for the dropdown container
  const navigate = useNavigate();
  const { t: translation } = useTranslation("sidebar");
  const { t } = useTranslation("translations");
  const { i18n } = useTranslation();
  const provider = new OpenStreetMapProvider();

  const imagesAndData = useMemo(() => {
    return [
      {
        title: translation("legend-section.transport-volumes.label"),
        image: "/images/welcome/transport.png",
        link: `/map?filter=transportVolumes&lang=${i18n.language}`,
      },
      {
        title: translation("legend-section.railways.label"),
        image: "/images/welcome/railway.png",
        link: `/map?filter=railway&lang=${i18n.language}`,
      },
      {
        title: translation("legend-section.kv-terminal.label"),
        image: "/images/welcome/terminal.png",
        link: `/map?filter=kvTerminal&lang=${i18n.language}`,
      },
      {
        title: translation("legend-section.toll-data.label"),
        image: "/images/welcome/toll-data.png",
        link: `/map?filter=tollData&lang=${i18n.language}`,
      },
      {
        title: translation("Go To Map"),
        image: "/images/welcome/map.png",
        className: "active",
        link: `/map?lang=${i18n.language}`,
      },
    ];
  }, [translation, i18n.language]);

  const handleSearch = (seletedOption = "") => {
    if (search || seletedOption) {
      navigate(
        `/map?search-location=${seletedOption ? seletedOption : search}`
      );
    }
  };

  const fetchResults = async () => {
    try {
      setLoading(true);
      await provider.search({ query: search }).then((results) => {
        setSearchResults(results);
        setSelectedIndex(-1); // Reset index when new results come in
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  // Debounced version of setSearch
  const debouncedSetSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    debouncedSetSearch(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (searchResults.length > 0) {
      if (e.key === "ArrowDown") {
        setSelectedIndex((prevIndex) =>
          prevIndex === searchResults.length - 1 ? 0 : prevIndex + 1
        );
      } else if (e.key === "ArrowUp") {
        setSelectedIndex((prevIndex) =>
          prevIndex <= 0 ? searchResults.length - 1 : prevIndex - 1
        );
      } else if (e.key === "Enter") {
        if (selectedIndex >= 0) {
          handleSearch(searchResults[selectedIndex].label);
        }
      }
    }
  };

  useEffect(() => {
    if (provider.search && search) {
      fetchResults();
    } else {
      setSearchResults([]);
    }
  }, [search]);

  // Scroll the dropdown to the selected item
  useEffect(() => {
    if (dropdownRef.current && selectedIndex >= 0) {
      const selectedItem = dropdownRef.current.children[selectedIndex];
      if (selectedItem) {
        const dropdownHeight = dropdownRef.current.clientHeight;
        const itemTop = selectedItem.offsetTop;
        const itemHeight = selectedItem.clientHeight;
        const scrollTop = dropdownRef.current.scrollTop;

        // If the selected item is out of view, adjust scroll position
        if (itemTop < scrollTop) {
          console.log("🚀 ~ useEffect ~ itemTop:", itemTop);

          dropdownRef.current.scrollTop = 0;
        } else if (itemTop + itemHeight > scrollTop + dropdownHeight) {
          dropdownRef.current.scrollTop =
            itemTop + itemHeight - dropdownHeight + 10;
        }
      }
    }
  }, [selectedIndex]);

  return (
    <div className={classes.container} onKeyDown={handleKeyDown} tabIndex="0">
      <div className={classes["section-1"]}>
        <p className={`${classes["sub-title"]} ${classes.mb} `}>
          {t("Welcome_Page.Title")}
        </p>
        <p className={classes.heading}>
          <span>{t("Welcome_Page.Welcome to Distill")}</span> -{" "}
          {t("Welcome_Page.The Digital Tool for the Lusatia Region")}
        </p>
        <p className={classes["sub-title"]}>{t("Welcome_Page.Introduction")}</p>
        <p className={classes["sub-title"]}>{t("Welcome_Page.Profile")}</p>
        <p className={classes["sub-title"]}>{t("Welcome_Page.Description")}</p>
        <p className={classes["sub-title"]}>{t("Welcome_Page.Version")}</p>
      </div>
      {/* <div className={classes["section-2"]}>
        <p className={classes.heading}>{t("What's your plan today?")}</p>
        <p className={classes["sub-title"]}>
          {t(
            "Discover the best routes and places in your city with over 1258 listings"
          )}
        </p>
      </div> */}
      <div className={classes["section-3"]}>
        {/* <input
          onChange={handleInputChange}
          type="text"
          placeholder={t("Keywords")}
        />
        <button onClick={() => handleSearch()}>{t("Search")}</button>
        <img src="/images/edit.svg" alt="" /> */}
        <div
          ref={dropdownRef}
          className={`${classes.dropdown} ${loading ? classes.loading : ""}`}
        >
          {loading && <MiniLoader />}

          {searchResults.length > 0 && (
            <>
              {searchResults.map((result, index) => (
                <p
                  key={result.label}
                  onClick={() => handleSearch(result.label)}
                  className={selectedIndex === index ? classes.selected : ""}
                >
                  {result.label}
                </p>
              ))}
            </>
          )}
        </div>
      </div>
      <div className={classes["section-4"]}>
        <div className={classes["cards-container"]}>
          {imagesAndData.map((data, index) => (
            <SingleCard
              isLast={index === imagesAndData.length - 1}
              key={data.title}
              {...data}
            />
          ))}
        </div>
        <img
          className={classes.line}
          src="/images/welcome/line.png"
          alt="line"
        />
      </div>
    </div>
  );
}
