import React from "react";
import CustomSlider from "../customSlider";
import classes from "./carbonEmissionAndDistance.module.scss";
import { useTranslation } from "react-i18next";

export default function CarbonEmissionAndDistance() {
  const { t } = useTranslation("translations");

  return (
    <div className={classes["emission-distance-main-container"]}>
      <p className={classes.heading}>{t("Mode of transport")}</p>
      <div className={classes["icon-and-details"]}>
        <div className={classes["icon-container"]}>
          <img src="/images/truck-icon.png" />
        </div>
        <div className={classes.details}>
          <p className={classes.heading}>Truck</p>
          <p className={classes["sub-heading"]}>
            {t("Threshold level")}: <span>400 tkm</span>
          </p>
        </div>
      </div>
      <div className={classes["slider-main-container"]}>
        <p className={classes.heading}>
          {t("Travel Distance (km) and Carbon Emissions (tkm)")}
        </p>

        <CustomSlider />

        <div className={classes.divider} />

        <div className={classes["low-high-emissions"]}>
          <div className={classes["emission-container"]}>
            <div className={`${classes.bullet} ${classes.green}`}></div>
            <p className={classes["text-green"]}>{t("Low emissions")}</p>
          </div>
          <div className={classes["emission-container"]}>
            <div className={`${classes.bullet} ${classes.red}`}></div>
            <p className={classes["text-red"]}>{t("High emissions")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
