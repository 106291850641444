// DataSources.jsx
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classes from "./dataSources.module.scss";
import { getSelectedCategoriesDataSources } from "../../utils/data-sources"

export default function DataSources({
  selectedFiles = [],
  selectedCategoryID = "",
  filterSelections = {},
}) {
  const { t } = useTranslation("translations");

  const selectedCategoriesDataSources = useMemo(() => {
    return getSelectedCategoriesDataSources({
      selectedFiles,
      selectedCategoryID,
      filterSelections,
    });
  }, [selectedFiles, selectedCategoryID, filterSelections]);

  return (
    <div className={classes["data-source-container"]}>
      <p>{t("All statements without guarantee")}</p>
      {selectedCategoriesDataSources.map((datasource) => (
        <a
          href={datasource.href}
          key={datasource.label}
          className={classes.red}
          target="_blank"
          rel="noreferrer"
        >
          {datasource.label}
        </a>
      ))}
    </div>
  );
}
