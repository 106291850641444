import React from "react";
import TopBar from "./../topBar/index"; // Assuming you have a TopBar component
import Cookies from "../cookies";
import { LanguageSelectorProvider } from "../../context/languageSelector";
import { ResetDirectionsProvider } from "../../context/reset";

const Layout = ({ children }) => {
  return (
    <div className="app-layout">
      {/* Selected Language - Context Provider */}
      <LanguageSelectorProvider>
        {/* Reset Direction (Exit Direction) - Context Provider */}
        <ResetDirectionsProvider>
          {/* Header */}
          <TopBar />
          <div className="content">{children}</div>
          {/* Cookies */}
          <Cookies />
        </ResetDirectionsProvider>
      </LanguageSelectorProvider>
    </div>
  );
};

export default Layout;
