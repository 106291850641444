import React from "react";
import Footer from "../sharedComponent/footer";
import classes from "./welcome.module.scss";
import LeftSection from "./left-section";
import RightSection from "./right-section";

export default function WelcomePage() {
  return (
    <>
      <div className={classes["main-container"]}>
        <div className={classes["welcome-page-container"]}>
          <div className={classes.grid}>
            <div className={classes["left-section"]}>
              <LeftSection />
            </div>
            <div>
              <RightSection />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
