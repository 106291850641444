import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://core-backend.distill-lausitz.de",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = "YWRtaW46P2s8XnJRM081JjA3";
  config.headers = {
    "Content-Type": "application/json",
    Authorization: `Basic ${token}`,
    ...config.headers,
  };
  return config;
});

export { axiosInstance };
