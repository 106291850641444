import React, { useMemo } from "react";
import classes from "./terminalContactDetails.module.scss";
import { useTranslation } from "react-i18next";

export default function TerminalContactDetails({
  data = {},
  isInlandPort = false,
}) {
  const { t } = useTranslation("translations");
  const { t:translation } = useTranslation("sidebar");

  const terminalData = useMemo(() => {
    if (data) {
      const { name = "N.A.", address = "N.A.", website = "N.A." } = data || {};
      return [
        {
          icon: "./images/terminal-red-icon.svg",
          title: isInlandPort ? translation("legend-section.inland-ports.label") : "Terminal",
          bold: true,
        },
        {
          icon: "./images/location.svg",
          title: name,
        },
        {
          icon: "",
          title: address,
        },
        {
          icon: "./images/global.svg",
          title: `${t("Website")}: ` + (website === "n.a." ? "N.A." : website),
        },
      ];
    }
    return [];
  }, [data, t, isInlandPort, translation]);

  const contactDetails = useMemo(() => {
    if (data) {
      const { email = "n.a.", phone = "n.a.", operator = "n.a." } = data || {};
      return [
        {
          icon: "./images/calendar.svg",
          title: t("Vertrieb - kontakt"),
          bold: true,
        },
        {
          icon: "./images/call.svg",
          title: phone,
        },
        {
          icon: "./images/email.svg",
          title: email,
        },
        {
          icon: "./images/operator.svg",
          title:
            `${t("operator")}: ` + (operator === "n.a." ? "N.A." : operator),
        },
      ];
    }
    return [];
  }, [data, t]);

  return (
    <div className={classes["contact-details-container"]}>
      <div className={classes.left}>
        {terminalData.map((data) => (
          <div
            className={`${classes["single-detail"]} ${
              data.bold ? classes.bold : ""
            }`}
          >
            <div className={classes.icon}>
              {data.icon && <img alt={data.title} src={data.icon} />}
            </div>
            <p className={classes.title}>{data.title}</p>
          </div>
        ))}
      </div>
      <div className={classes.line} />
      <div className={classes.right}>
        {contactDetails.map((data) => (
          <div
            className={`${classes["single-detail"]} ${
              data.bold ? classes.bold : ""
            }`}
          >
            <div className={classes.icon}>
              {data.icon && <img alt={data.title} src={data.icon} />}
            </div>
            <p className={classes.title}>{data.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
