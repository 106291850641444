import React from "react";
import classes from "./logoPlaceholder.module.scss";

export default function LogoPlaceholder({ isSidebarOpen = false }) {
  return (
    <div
      className={`${classes.container} ${isSidebarOpen ? classes.open : ""}`}
    >
      <p>Demo v1.0</p>
    </div>
  );
}
