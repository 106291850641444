import { Marker, Popup, useMap } from "react-leaflet";
import { TerminalsIcon } from "../icons/Terminals.icon";
import React, { useEffect, useState } from "react";
import TerminalData from "../../../data/DiSTILL_Terminaldata-Tool_WGS84-EPSG4326.json";

export const GEOKVTerminals = ({
  selectedFiles,
  data,
  handleTerminals,
  flyBackFunction,
}) => {
  const terminalDetails = TerminalData;
  const map = useMap();

  const flyBackToInitialView = () => {
    map.flyTo([51.5349597, 13.6215388], 8.73, {
      animate: true,
    });
  };

  useEffect(() => {
    if (flyBackFunction) {
      flyBackToInitialView();
    }
  }, [flyBackFunction]);

  const handleMarkerClick = (event, markerPosition, m) => {
    map.flyTo(markerPosition, 15, {
      animate: true,
    });
    handleTerminals(m);
  };

  if (!selectedFiles.includes("kvTerminal")) return null;

  return terminalDetails.features.map((data, index) => {
    const position = [data.geometry.coordinates[1], data.geometry.coordinates[0]]
    return (
      <Marker
        key={index}
        position={position}
        icon={TerminalsIcon}
        eventHandlers={{
          click: (event) =>
            handleMarkerClick(
              event,
              position,
              data
            ),
        }}
      ></Marker>
    );
  });
};
