export const FORECAST_OPTIONS = [
  {
    name: "Beschaeftigungsquote",
    indicators: [
      {
        id: 166,
        friendlyUrl: "beschaeftigungsquote",
        name: "Beschäftigungsquote",
        unit: "%",
      },
      {
        id: 25,
        friendlyUrl: "beschaeftigungsanteil-im-1-sektor",
        name: "Beschäftigungsanteil im 1. Sektor",
        unit: "%",
      },
      {
        id: 26,
        friendlyUrl: "beschaeftigungsanteil-im-2-sektor",
        name: "Beschäftigungsanteil im 2. Sektor",
        unit: "%",
      },
      {
        id: 27,
        friendlyUrl: "beschaeftigungsanteil-im-3-sektor",
        name: "Beschäftigungsanteil im 3. Sektor",
        unit: "%",
      },
      {
        id: 13,
        friendlyUrl: "bevoelkerung",
        name: "Bevölkerung",
        unit: "Anzahl",
      },
      {
        id: 147,
        friendlyUrl: "bevoelkerungsentwicklung-seit-2011",
        name: "Bevölkerungsentwicklung seit 2011",
        unit: "%",
      },
      {
        id: 154,
        friendlyUrl: "geburten",
        name: "Geburten",
        unit: "je 1.000 Einwohner:innen",
      },
      {
        id: 155,
        friendlyUrl: "sterbefaelle",
        name: "Sterbefälle",
        unit: "je 1.000 Einwohner:innen",
      },
      {
        id: 248,
        friendlyUrl: "zuzuege",
        name: "Zuzüge",
        unit: "je 1.000 Einwohner:innen",
      },
      {
        id: 50,
        friendlyUrl: "einwohner-innendichte",
        name: "Einwohner:innendichte",
        unit: "Einwohner:innen je Hektar",
      },
      {
        id: 315,
        friendlyUrl: "staatliche-investitionszuweisungen",
        name: "Staatliche Investitionszuweisungen",
        unit: "Euro je Einwohner:in",
      },
      {
        id: 324,
        friendlyUrl: "gewerbesteuer-netto",
        name: "Gewerbesteuer (netto)",
        unit: "Euro je Einwohner:in",
      },
      {
        id: 336,
        friendlyUrl: "hebesatz-gewerbesteuer",
        name: "Hebesatz Gewerbesteuer",
        unit: "v.H.",
      },
      {
        id: 325,
        friendlyUrl: "einkommensteuer",
        name: "Einkommensteuer",
        unit: "Euro je Einwohner:in",
      },
      {
        id: 300,
        friendlyUrl: "strom-aus-erneuerbaren-quellen",
        name: "Strom aus erneuerbaren Quellen",
        unit: "kW je Einwohner:in",
      },
      {
        id: 225,
        friendlyUrl: "langzeitarbeitslosenquote",
        name: "Langzeitarbeitslosenquote",
        unit: "%",
      },
      {
        id: 160,
        friendlyUrl: "beschaeftigungsquote-55-bis-64-jaehrige",
        name: "Beschäftigungsquote 55- bis 64-Jährige",
        unit: "%",
      },
      {
        id: 301,
        friendlyUrl: "trinkwasserverbrauch",
        name: "Trinkwasserverbrauch",
        unit: "l je Einwohner:in und Tag",
      },
      {
        id: 286,
        friendlyUrl: "kaufkraft",
        name: "Kaufkraft",
        unit: "Euro/Haushalt",
      },
    ],
    indicatorsPopupDetails: {
      name: "Beschäftigungsquote",
      explanation:
        "x % der Bevölkerung im erwerbsfähigen Alter von 15 bis 64 Jahren sind am Wohnort als sozialversicherungspflichtig beschäftigt (SvB) gemeldet.",
      calculation:
        "(SvB am Wohnort 15-64 Jahre) / (Bevölkerung 15-64 Jahre) * 100 (Stichtag für SvB: 30.06.)",
    },
  },
];

export const REGIONS_OPTIONS = [
  {
    friendlyUrl: "dahme-spreewald-lk",
    name: "Dahme-Spreewald, LK",
    title: "Dahme-Spreewald, Landkreis",
    id: 5989,
    gkz: "12061000",
    ars: "120610000000",
    type: "LANDKREIS",
    demographicType: 0,
    parent: "Brandenburg",
  },
  {
    friendlyUrl: "cottbus",
    name: "Cottbus",
    title: "Cottbus, kreisfreie Stadt",
    id: 5960,
    gkz: "12052000",
    ars: "120520000000",
    type: "KREISFREIE_STADT",
    demographicType: 2,
    parent: "Brandenburg",
  },
  {
    friendlyUrl: "oberspreewald-lausitz-lk",
    name: "Oberspreewald-Lausitz, LK",
    title: "Oberspreewald-Lausitz, Landkreis",
    id: 6154,
    gkz: "12066000",
    ars: "120660000000",
    type: "LANDKREIS",
    demographicType: 0,
    parent: "Brandenburg",
  },
  {
    friendlyUrl: "elbe-elster-lk",
    name: "Elbe-Elster, LK",
    title: "Elbe-Elster, Landkreis",
    id: 6027,
    gkz: "12062000",
    ars: "120620000000",
    type: "LANDKREIS",
    demographicType: 0,
    parent: "Brandenburg",
  },
  {
    friendlyUrl: "spree-neisse-lk",
    name: "Spree-Neiße, LK",
    title: "Spree-Neiße, Landkreis",
    id: 6308,
    gkz: "12071000",
    ars: "120710000000",
    type: "LANDKREIS",
    demographicType: 0,
    parent: "Brandenburg",
  },
  {
    friendlyUrl: "bautzen-lk",
    name: "Bautzen, LK",
    title: "Bautzen, Landkreis",
    id: 7307,
    gkz: "14625000",
    ars: "146250000000",
    type: "LANDKREIS",
    demographicType: 0,
    parent: "Sachsen",
  },
  {
    friendlyUrl: "goerlitz-lk",
    name: "Görlitz, LK",
    title: "Görlitz, Landkreis",
    id: 7365,
    gkz: "14626000",
    ars: "146260000000",
    type: "LANDKREIS",
    demographicType: 0,
    parent: "Sachsen",
  },
];

export const REGIONS_LIST = [
  "Dahme-Spreewald, LK",
  "Cottbus",
  "Oberspreewald-Lausitz, LK",
  "Elbe-Elster, LK",
  "Spree-Neiße, LK",
  "Bautzen, LK",
  "Görlitz, LK",
];

export const TRANSPORT_VOLUMES_OPTIONS = (t = () => {}, VK = []) => {
  return [
    {
      name: t("filters.showAll"),
      image: "",
      singleItem: "showAll",
      filter: "Show All",
      values: [],
    },
    {
      name: t("filters.inboundVolumes"),
      image: "./images/Ellipse 31.png",
      filter: "tkmFrom",
      singleItem: "tkmFrom",
    },
    {
      name: t("filters.outBoundsVolumes"),
      image: "./images/Ellipse 31.png",
      filter: "tkmNach",
      singleItem: "tkmNach",
    },
    {
      name: t("filters.cities"),
      image: "./images/Ellipse_35.svg",
      values: VK.map((v) => v?.Region || v?.region),
      filter: "Cities",
      singleItem: "cities",
    },
    {
      name: t("filters.trafficVolumeThreshold"),
      image: "./images/Ellipse_33.svg",
      values: [],
      filter: "Traffic Volume Threshold",
      rangeSlider: true,
      singleItem: "trafficThreshold",
    },
  ];
};

export const FILTER_RAILWAY = (t = () => {}) => {
  return [
    {
      name: t("filters.leagRailway"),
      image: "./images/Ellipse_33.svg",
      singleItem: "leagRailway",
      filter: "LEAG Railways",
      values: [],
    },
    {
      name: t("filters.electrified_railway_international"),
      image: "./images/railway_international.png",
      singleItem: "railway_map",
      filter: "ER_International",
      values: [],
    },
    {
      name: t("filters.electrified_railway_national"),
      image: "./images/railway_international.png",
      singleItem: "railway_map",
      filter: "ER_National",
      values: [],
    },

    // {
    //   name: t("categories.railwayMap"),
    //   image: "./images/earth.png",
    //   singleItem: "openRailwayMap",
    //   filter: "openRailwayMap",
    //   values: [],
    // },
  ];
};

export const ROUTING_OPTIONS = (t = () => {}) => {
  return [
    {
      name: t("filters.roadRouting"),
      image: "./images/roads.png",
      singleItem: "roadRouting",
      filter: "Road Routing",
      values: [],
    },
    {
      name: t("filters.railwayRouting"),
      image: "./images/railways.png",
      singleItem: "railwayRouting",
      filter: "Railway Routing",
      values: [],
    },
    {
      name: t("filters.combinedRouting"),
      image: "./images/railways.png",
      singleItem: "combinedRouting",
      filter: "Combined Routing",
      values: [],
    },
  ];
};

export const FILTER_ROADS = (t = () => {}) => {
  return [
    {
      name: t("filters.motorway"),
      image: "./images/Ellipse 34.png",
      singleItem: "motorway",
      filter: "Motorway",
      values: [],
    },
    {
      name: t("filters.mainRoad"),
      image: "./images/roads.png",
      singleItem: "mainRoad",
      filter: "Main Road",
      values: [],
    },
  ];
};

export const FILTER_TERMINALS = (
  t = () => {},
  extractDropdownValues = () => {}
) => {
  return [
    {
      name: t("filters.railwaySidings"),
      image: "./images/Ellipse_35.svg",
      singleItem: "railwaySidings",
      filter: "Railway Sidings",
      values: extractDropdownValues(),
      isRailwaySiding: true,
    },
  ];
};

export const TABS = (t = () => {}) => {
  return [t("tabs.allSearchFilters")];
};

export const INDICATORS_POPUP_DETAILS = [
  {
    name: "Beschäftigungsquote",
    explanation:
      "x % der Bevölkerung im erwerbsfähigen Alter von 15 bis 64 Jahren sind am Wohnort als sozialversicherungspflichtig beschäftigt (SvB) gemeldet.",
    calculation:
      "(SvB am Wohnort 15-64 Jahre) / (Bevölkerung 15-64 Jahre) * 100 (Stichtag für SvB: 30.06.)",
  },
  {
    name: "Arbeitsplatzentwicklung der vergangenen 5 Jahre",
    explanation:
      "Die Zahl der sozialversicherungspflichtig Beschäftigten (SvB) hat sich in den vergangenen 5 Jahren um x % verändert. Arbeitsplatzzuwachs oder Arbeitsplatzabbau im Verhältnis zu den bestehenden Arbeitsplätzen: Ein hoher positiver Wert weist auf eine hohe positive wirtschaftliche Dynamik hin.",
    calculation:
      "(SvB am Arbeitsort - SvB am Arbeitsort vor 5 Jahren) / SvB am Arbeitsort vor 5 Jahren * 100 (Stichtag: 30.06.)",
  },
  {
    name: "Beschäftigungsanteil im 1. Sektor",
    explanation:
      'Der Anteil der sozialversicherungspflichtig Beschäftigten (SvB) im primären Sektor (Land-, Forstwirtschaft, Fischerei [Wirtschaftsabschnitt "A"]) am Arbeitsort beträgt x % der insgesamt SvB am Arbeitsort.',
    calculation:
      "SvB im primären Sektor (am Arbeitsort) / SvB (am Arbeitsort) * 100 (Stichtag: 30.06.)",
  },
  {
    name: "Beschäftigungsanteil im 2. Sektor",
    explanation:
      'Der Anteil der sozialversicherungspflichtig Beschäftigten (SvB) im sekundären Sektor (Bergbau, Verarbeitendes Gewerbe, Energie, Bau [Wirtschaftsabschnitte "B-F"]) am Arbeitsort beträgt x % der insgesamt SvB am Arbeitsort.',
    calculation:
      "SvB im sekundären Sektor (am Arbeitsort) / SvB (am Arbeitsort) * 100 (Stichtag: 30.06.)",
  },
  {
    name: "Beschäftigungsanteil im 3. Sektor",
    explanation:
      'Der Anteil der sozialversicherungspflichtig Beschäftigten (SvB) im tertiären Sektor (Handel, Gastgewerbe, Verkehr, Kredit, Dienstleistungen, Öffentliche Verwaltung, Soziales, u.w. [Wirtschaftsabschnitte "G-U"]) am Arbeitsort beträgt x % der insgesamt SvB am Arbeitsort.',
    calculation:
      "SvB im tertiären Sektor (am Arbeitsort) / SvB (am Arbeitsort) * 100 (Stichtag: 30.06.)",
  },
  {
    name: "Bevölkerung",
    explanation: "Gesamtbevölkerung am 31.12. des ausgewählten Jahres.",
    calculation: "",
  },
  {
    name: "Bevölkerungsentwicklung seit 2011",
    explanation:
      "Die Bevölkerungszahl hat seit dem Jahr 2011 um x % zugenommen / abgenommen.",
    calculation: "((Bevölkerung aktuell * 100) / Bevölkerung 2011 ) - 100",
  },
  {
    name: "Geburten",
    explanation:
      "Innerhalb des Jahres wurden in der betrachteten Kommune x Personen auf je 1.000 Einwohner:innen geboren. Die natürlichen Bevölkerungsveränderungen sind in fast allen Kommunen niedriger als die Wanderungen.",
    calculation:
      "Lebendgeburten / Bevölkerung * 1.000 (Bezugsjahre: Lebendgeburten und Bevölkerung jeweils gemittelt über die letzten 4 Jahre)",
  },
  {
    name: "Sterbefälle",
    explanation:
      "Im Jahr z sind x Personen auf je 1.000 Personen der Bestandsbevölkerung gestorben. Die natürlichen Bevölkerungsveränderungen sind in fast allen Kommunen niedriger als die Wanderungen.",
    calculation:
      "Sterbefälle / Bevölkerung * 1.000 (Bezugsjahre: Sterbefälle und Bevölkerung jeweils gemittelt über die letzten 4 Jahre)",
  },
  {
    name: "Zuzüge",
    explanation:
      "X Personen auf je 1.000 Personen der Bestandsbevölkerung zogen zu. Die Wanderungen erreichen in fast allen Kommunen viel höhere Werte als die natürlichen Bevölkerungsveränderungen. Je kleiner eine Gemeinde ist, um so höher ist tendenziell der Faktor für den Unterschied.",
    calculation:
      "Zuzüge / Bevölkerung * 1.000 (Bezugsjahre: Zuzüge und Bevölkerung jeweils gemittelt über die letzten 4 Jahre)",
  },
  {
    name: "Fortzüge",
    explanation:
      "X Personen auf je 1.000 Personen der Bestandsbevölkerung zogen fort. Die Wanderungen erreichen in fast allen Kommunen viel höhere Werte als die natürlichen Bevölkerungsveränderungen. Je kleiner eine Gemeinde ist, um so höher ist tendenziell der Faktor für den Unterschied.",
    calculation:
      "Fortzüge / Bevölkerung * 1.000 (Bezugsjahre: Fortzüge und Bevölkerung jeweils gemittelt über die letzten 4 Jahre)",
  },
  {
    name: "Medianalter",
    explanation:
      "Das Medianalter ist das Lebensalter, das eine Population statistisch in zwei gleich große Gruppen teilt: 50% der Bevölkerung sind jünger, und 50% sind älter als dieser Wert. Im Gegensatz zum Durchschnittsalter werden Verzerrungen durch Extremwerte vermieden. Gibt einen Hinweis auf den Fortschritt des Alterungsprozesses der Bevölkerung.",
    calculation:
      "Medianalter = Alter der/s n/2-ten Einwohner:in bei einer Rangfolgenbildung nach erreichtem Lebensalter",
  },
  {
    name: "Einwohner:innendichte",
    explanation: "Pro Fläche in Hektar gibt es x Einwohner:innen.",
    calculation: "Gesamtbevölkerung/Fläche in ha",
  },
  {
    name: "Staatliche Investitionszuweisungen",
    explanation:
      "Die Kommune x erhielt im Jahr z je Einwohner:in Investitionszuweisungen von Bund und Land in Höhe von y Euro. Die staatlichen Investitionszuweisungen ergänzen die kommunalen Einzahlungen/Einnahmen. Allerdings sind sie investiv zweckgebunden. Durch eine Verknüpfung dieses Indikators mit den Sachinvestitionen kann derjenige Anteil der kommunalen Investitionen ermittelt werden, der staatlich mitfinanziert wurde. Dadurch wird die Fähigkeit einer Kommune zur Eigenfinanzierung von Investitionen deutlich.",
    calculation:
      "staatliche Investitionszuweisungen / Einwohner:innen (Stichtag Einwohner:innen: 30.06.) Kam-Gr.-Nr. 360 + 361 Dop-Kto. 6810 + 6811",
  },
  {
    name: "Gewerbesteuer (netto)",
    explanation:
      "Die Gemeinde x hatte im Jahr z je Einwohner:in nach Abzug der Gewerbesteuerumlage ein Gewerbesteueraufkommen in Höhe von y Euro. Diese Steuer ist durchschnittlich die wichtigste Steuerquelle einer Gemeinde (nicht Kreis). Die Höhe sagt etwas über die Charakteristik der Gemeinde als Wirtschaftsstandort und die Wirtschaftsstruktur aus. Die Gewerbesteuer ist gewinnabhängig und schwankt stark mit der Konjunktur.",
    calculation:
      "Gewerbesteuer (netto) / Einwohner:innen (Stichtag Einwohner:innen: 30.06.) Kam-Gr.-Nr. 003 - 81 Dop-Kto. 6013 - 734",
  },
  {
    name: "Hebesatz Gewerbesteuer",
    explanation:
      "Die Gemeinde x hatte im Jahr z einen Gewerbesteuerhebesatz in Höhe von y v.H. Der Indikator zeigt, inwieweit eine Gemeinde (nicht Kreis) (im Vergleich zu anderen Gemeinden) ihre Betriebe zur Ertragsverbesserung heran zieht. Werden die Gewerbesteuereinnahmen durch den Hebesatz geteilt, zeigt sich die tatsächliche Ertragskraft der lokalen Wirtschaft (Bemessungsgrundlage).",
    calculation: "Istaufkommen / Grundbetrag *100",
  },
  {
    name: "Einkommensteuer",
    explanation:
      "Die Gemeinde x hatte im Jahr z je Einwohner:in ein Aufkommen aus dem Gemeindeanteil an der Einkommensteuer in Höhe von y Euro. Die Höhe sagt etwas über die Charakteristik der Gemeinde als Wohnstandort aus. Das Einzahlungsniveau richtet sich nach den Einkommen der Einwohner:innen (bis zu einer bestimmten Höhe). Dadurch erfolgt eine gewisse Nivellierung der Einkommensunterschiede zwischen den Gemeinden.",
    calculation:
      "Gemeindeanteil an der Einkommensteuer / Einwohner:innen (Stichtag Einwohner:innen: 30.06.) Kam-Gr.-Nr. 010 Dop-Kto. 6021",
  },
  {
    name: "Strom aus erneuerbaren Quellen",
    explanation:
      "Es sind erneuerbare Energieträger mit einer Nettonennleistung von insgesamt x Kilowattstunden installiert worden.",
    calculation:
      "(Installierte Nettonennleistung erneuerbaren Stroms aus Biomasse, Solarer Strahlungsenergie, Wasser und Wind) / (Anzahl der Einwohner:innen)",
  },
  {
    name: "Langzeitarbeitslosenquote",
    explanation:
      "Ein Anteil von x % der Erwerbspersonen ist länger als 1 Jahr arbeitslos.",
    calculation:
      "(Anzahl Arbeitslose mit Dauer der Arbeitslosigkeit > 1 Jahr) / ((Anzahl Arbeitslose) + (Anzahl SvB am Wohnort)) * 100",
  },
  {
    name: "Beschäftigungsquote 55- bis 64-Jährige",
    explanation:
      "Der Anteil der am Wohnort sozialversicherungspflichtig beschäftigt (SvB) gemeldeten Personen im Alter von 55 Jahren und mehr an der Bevölkerung im Alter von 55 bis 64 Jahren beträgt x %.",
    calculation:
      "SvB am Wohnort 55-64 Jahre / Bevölkerung 55-64 Jahre * 100. (Stichtag für SvB: 30.06.)",
  },
  {
    name: "Trinkwasserverbrauch",
    explanation:
      "Je Einwohner:in und Tag wurden durchschnittlich x Liter Wasser verbraucht.",
    calculation:
      "(Jährlicher Trinkwasserverbrauch (Haushalte und Kleingewerbe)) / (Anzahl Einwohner) * (Anzahl der Tage pro Jahr)",
  },
  {
    name: "Schüler:innen an Grundschulen",
    explanation:
      "X % der Schüler:innen besuchen eine Grundschule. Nicht berücksichtigt sind für einige Bundesländer Daten zu Vorklassen, Schulkindergärten, Förderschulen - Schulkindergärten, vorschulischen Einrichtungen, Grund- und Hauptschulen [die eine organisatorische Einheit bilden] und Volksschulen.",
    calculation:
      "Anzahl Schüler:innen an Grundschulen / Anzahl Schüler:innen gesamt * 100",
  },
  {
    name: "Schulabgänger:innen mit Förderschulabschluss - Gesamt",
    explanation:
      'X % der Schulabgänger:innen allgemein- und berufsbildender Schulen mit allgmb. Abschluss haben einen Förderschulabschluss.Abgänger:innen mit Förderschulabschluss in Sachsen sind im Indikator "Schulabgänger:innen ohne Hauptschulabschluss" enthalten. Dies gilt auch für Baden-Württemberg in den Jahrgängen vor 2018. In Jahrgängen in denen für Berlin Abgänger:innen mit Förderschulabschluss nicht ausgewiesen sind, sind diese im Indikator "Schulabgänger:innen ohne Hauptschulabschluss" enthalten.',
    calculation:
      "Anzahl Schulabgänger:innen mit Förderschulabschluss (allgmb. + berufsb.) / Anzahl Schulabgänger:innen gesamt (allgmb. + berufsb.) mit allgmb. Abschluss * 100",
  },
  {
    name: "Schulabgänger:innen allgmb./berufsb. Schulen ohne Hauptschulabschluss - Gesamt",
    explanation:
      'X % der Schulabgänger:innen allgemein- und berufsbildender Schulen mit allgmb. Abschluss haben keinen Hauptschulabschluss. Abgänger:innen mit Förderschulabschluss in Sachsen sind im Indikator "Schulabgänger:innen ohne Hauptschulabschluss" enthalten. Dies gilt auch für Baden-Württemberg in den Jahrgängen vor 2018. In Jahrgängen in denen für Berlin Abgänger:innen mit Förderschulabschluss nicht ausgewiesen sind, sind diese im Indikator "Schulabgänger:innen ohne Hauptschulabschluss" enthalten.',
    calculation:
      "Anzahl Schulabgänger:innen ohne Hauptschulabschluss (allgmb. + berufsb.) / Anzahl Schulabgänger:innen gesamt (allgmb. + berufsb.) mit allgmb. Abschluss * 100",
  },
  {
    name: "Schulabgänger:innen mit Hauptschulabschluss - Gesamt",
    explanation:
      'X % der Schulabgänger:innen allgemein- und berufsbildender Schulen mit allgmb. Abschluss haben einen Hauptschulabschluss, inklusive der Daten für einige Bundesländer zum Abschluss "mit erweitertem Hauptschulabschluss". Nicht berücksichtigt sind für einige Bundesländer Daten zum Abschluss "Schulartspezifischer Abschluss".',
    calculation:
      "Anzahl Schulabgänger:innen mit Hauptschulabschluss (allgmb. + berufsb.) / Anzahl Schulabgänger:innen gesamt (allgmb. + berufsb.) mit allgmb. Abschluss * 100",
  },
  {
    name: "Schulabgänger innen mit Realschulabschluss - Gesamt",
    explanation:
      'X % der Schulabgänger:innen allgemein- und berufsbildender Schulen mit allgmb. Abschluss haben einen Realschulabschluss. Indikator, inklusive der Daten für einige Bundesländer zu den Abschlüssen "mit SI-Abschluss (Fachoberschulreife)", "mit mittlerem Bildungsabschluss" und Erweiterter Sekundarabschluss. Nicht berücksichtigt sind für einige Bundesländer Daten zum Abschluss "Schulartspezifischer Abschluss".',
    calculation:
      "Anzahl Schulabgänger:innen mit Realschulabschluss (allgmb. + berufsb.) / Anzahl Schulabgänger:innen gesamt (allgmb. + berufsb.) mit allgmb. Abschluss * 100",
  },
  {
    name: "Schulabgänger:innen allgmb./berufsb. Schulen mit Fachhoch-/Hochschulreife - Gesamt",
    explanation:
      'X % der Schulabgänger:innen allgmb. und berufsb. Schulen mit allgmb. Abschluss haben eine Fachhochschul- oder allgemeiner Hochschulreife, inklusive der Daten für einige Bundesländer zu den Abschlüssen Fachgebundene Hochschulreife und Fachgebundene Fachhochschulreife. Nicht berücksichtigt sind für einige Bundesländer Daten zu den Abschlüssen "Versetzungszeugnis Klasse 11 FO" und "rein berufsbildende Abschlüsse".',
    calculation:
      "Anzahl Schulabgänger:innen mit Fachhochschul- oder allgemeiner Hochschulreife (allgmb. + berufsb.) / Anzahl Schulabgänger:innen gesamt (allgmb. + berufsb.) mit allgmb. Abschluss * 100",
  },
  {
    name: "Kaufkraft",
    explanation:
      "Das durchschnittliche Gesamtnettoeinkommen eines Haushaltes beträgt x Euro. Die allgemeine Kaufkraft umfasst die Summe aller Nettoeinkünfte (Arbeitslohn, Rente, Sozialleistungen, Kindergeld). Der Indikator weist auf die wirtschaftliche Stärke einer Kommune bzw. ihrer Einwohnerschaft hin.",
    calculation: "Summe aller Haushaltsnettoeinkommen / Anzahl Haushalte",
  },
];

export const RAILWAY_SIDINGS = [
  "Nordsachsen",
  "Dresden, Stadt",
  "Halle (Saale), Stadt",
  "Anhalt-Bitterfeld",
  "Wittenberg",
  "Barnim",
  "Oberhavel",
  "Brandenburg an der Havel, St.",
  "Märkisch-Oderland",
  "Berlin, Stadt",
  "Meißen",
  "Chemnitz, Stadt",
  "Mittelsachsen",
  "Leipzig, Stadt",
  "Leipzig",
  "Erzgebirgskreis",
  "Bautzen",
  "Zwickau",
  "Görlitz",
  "Vogtlandkreis",
  "Sächs. Schweiz-Osterzgebirge",
  "Spree-Neiße",
  "Greiz",
  "Altenburger Land",
  "Saale-Holzland-Kreis",
  "Gera, Stadt",
  "Saalekreis",
  "Burgenlandkreis",
  "Mansfeld-Südharz",
  "Ostprignitz-Ruppin",
  "Teltow-Fläming",
  "Oder-Spree",
  "Dahme-Spreewald",
  "Havelland",
  "Potsdam, Stadt",
  "Potsdam-Mittelmark",
  "Frankfurt (Oder), Stadt",
  "Elbe-Elster",
  "Oberspreewald-Lausitz",
  "Cottbus, Stadt",
];

export const TERMINALS_DETAILS = [
  {
    id: 9,
    name: "Gumz Logistik Service Depot",
    latitude: 52.5655043,
    longitude: 12.9271453,
  },
  {
    id: 10,
    name: "Havel Port Berlin",
    latitude: 52.5584391,
    longitude: 12.9619219,
  },
  {
    id: 11,
    name: "BEHALA Westhafen",
    latitude: 52.5374076,
    longitude: 13.3281025,
  },
  {
    id: 12,
    name: "DUSS-Terminal Berlin",
    latitude: 52.3590137,
    longitude: 13.2876858,
  },
  {
    id: 13,
    name: "METRANS Terminal Königs Wusterhausen",
    latitude: 52.3049297,
    longitude: 13.6327841,
  },
  {
    id: 14,
    name: "PCC Frankfurt Oder Terminal",
    latitude: 52.3545164,
    longitude: 14.5077415,
  },
  {
    id: 15,
    name: "Hafen Eisenhüttenstadt",
    latitude: 52.152922,
    longitude: 14.6463173,
  },
  {
    id: 16,
    name: "Frachtterminal Lausitz",
    latitude: 51.7152174,
    longitude: 14.6123105,
  },
  {
    id: 17,
    name: "STR-Terminal Schwarzheide",
    latitude: 51.4723031,
    longitude: 13.8050398,
  },
  {
    id: 18,
    name: "LION-Logistics Container Terminal Kodersdorf",
    latitude: 51.2211458,
    longitude: 14.9192977,
  },
  {
    id: 19,
    name: "Alberthafen Dresden-Friedrichstadt",
    latitude: 51.0644292,
    longitude: 13.712984,
  },
  {
    id: 20,
    name: "GVZ Emons-Terminal Dresden",
    latitude: 51.0536754,
    longitude: 13.7024947,
  },
  {
    id: 21,
    name: "RailPort Elsterwerda",
    latitude: 51.4664232,
    longitude: 13.5034865,
  },
  {
    id: 22,
    name: "SBO Hafen Riesa",
    latitude: 51.3174451,
    longitude: 13.2751339,
  },
  {
    id: 23,
    name: "Railport Chemnitz",
    latitude: 50.814487,
    longitude: 12.922864,
  },
  {
    id: 24,
    name: "Terminal Glauchau Emons",
    latitude: 50.8331465,
    longitude: 12.5270622,
  },
  {
    id: 25,
    name: "Karl Schmidt Spedition Großlehna",
    latitude: 51.305833,
    longitude: 12.1847616,
  },
  {
    id: 26,
    name: "DB Depot Leipzig Quelle (Mockau)",
    latitude: 51.3899455,
    longitude: 12.3954301,
  },
  {
    id: 27,
    name: "DUSS-Terminal Leipzig",
    latitude: 51.3921041,
    longitude: 12.2926505,
  },
  {
    id: 28,
    name: "Terminal Lub Schkeuditz Emons",
    latitude: 51.4057774,
    longitude: 12.2446657,
  },
  {
    id: 29,
    name: "Kombi Terminal Schkopau",
    latitude: 51.3915611,
    longitude: 11.9749223,
  },
  {
    id: 30,
    name: "Railport Halle an der Saale",
    latitude: 51.475118,
    longitude: 12.0048885,
  },
  {
    name: "Container Terminal Halle Saale-CTHS",
    latitude: 51.5241669,
    longitude: 11.9401709,
  },
  {
    name: "Industriehafen Roßlau",
    latitude: 51.8809127,
    longitude: 12.2235616,
  },
  {
    name: "Aken Hafen",
    latitude: 51.8579897,
    longitude: 12.0358507,
  },
  {
    name: "Magdeburg Hanse-Terminal",
    latitude: 52.1710102,
    longitude: 11.6682967,
  },
  {
    name: "UHH Container Terminal Haldensleben",
    latitude: 52.2984111,
    longitude: 11.3724852,
  },
];

export const DATA_SOURCES = [
  {
    icon: "./images/airport.png",
    key: "airport",
    lastUpdate: {
      de: "19.08.2024",
      en: "August 19, 2024",
    },
    id: "airport",
    links: [
      {
        label: "OpenStreetMap contributors",
        href: "https://www.openstreetmap.org/copyright",
      },
    ],
  },
  {
    icon: "./images/ports.png",
    key: "inland-ports",
    lastUpdate: {
      de: "19.08.2024",
      en: "August 19, 2024",
    },
    id: "inlandPort",
    links: [
      {
        label: "OpenStreetMap contributors",
        href: "https://www.openstreetmap.org/copyright",
      },
    ],
  },
  {
    icon: "./images/railways.png",
    key: "railways",
    via: true,
    lastUpdate: {
      de: "19.08.2024",
      en: "August 19, 2024",
    },
    id: "railway",
    links: [
      {
        label: "OpenRailwayMap",
        href: "https://www.openrailwaymap.org/",
      },
      {
        label: "OpenStreetMap contributors",
        href: "https://www.openstreetmap.org/copyright",
      },
      {
        label: "TEN tec",
        href: "https://transport.ec.europa.eu/transport-themes/infrastructure-and-investment/trans-european-transport-network-ten-t/tentec-information-system_en",
      },
      {
        label: "IPG",
        href: "https://www.ipg-potsdam.de/",
      },
    ],
  },
  {
    icon: "./images/kv-terminal.png",
    key: "kv-terminal",
    lastUpdate: {
      de: "15.07.2024",
      en: "July 15, 2024",
    },
    id: "kvTerminal",
    links: [
      {
        label: "OpenStreetMap contributors",
        href: "https://www.openstreetmap.org/copyright",
      },
      {
        label: "Intermodal Map der SGKV",
        href: "https://www.intermodal-map.com/",
      },
      {
        label: "Railway tools der DB InfraGO AG",
        href: "https://railway.tools/#/de",
      },
      {
        label: "Fraunhofer IIS / Projekt SIDING (Gleisanschlüsse)",
        href: "https://www.scs.fraunhofer.de/de/referenzen/siding.html",
      },
    ],
  },
  {
    icon: "./images/roads.png",
    key: "road-tracks",
    lastUpdate: {
      de: "15.07.2024",
      en: "July 15, 2024",
    },
    id: "roadTacks",
    links: [
      {
        label: "OpenStreetMap contributors",
        href: "https://www.openstreetmap.org/copyright",
      },
      {
        label: "TEN tec",
        href: "https://transport.ec.europa.eu/transport-themes/infrastructure-and-investment/trans-european-transport-network-ten-t/tentec-information-system_en",
      },
      {
        label: "IPG",
        href: "https://www.ipg-potsdam.de/",
      },
    ],
  },
  {
    icon: "./images/transport.png",
    key: "transport-volumes",
    lastUpdate: {
      de: "18.01.2024",
      en: "January 18, 2024",
    },
    id: "transportVolumes",
    links: [
      {
        label: "Kraftfahrt-Bundesamt (KBA)",
      },
      {
        label: "Asfrdown.ve7d31verzeit.2022.1",
      },

      {
        label: "Doi: 10.25525/kbaasfrdown.ve7d31verzeit.2022.1",
      },
    ],
  },
  {
    key: "toll-data",
    icon: "./images/toll.png",
    lastUpdate: {
      de: "31.07.2024",
      en: "July 31, 2024",
    },
    id: "tollData",
    links: [
      {
        label: "Toll Collect (GIS-Modell der Mauttabelle des BALM)",
        href: "https://mobilithek.info/offers/665968432987942912",
      },
      {
        label:
          "Bundesamt für Logistik und Mobilität (Monatliche Mautumsätze pro Abschnitt)",
        href: "https://www.balm.bund.de/DE/Service/Open-Data/MautdatenBund/mautdatenbund_node.html",
      },
    ],
  },
  {
    key: "socio-economic",
    icon: "./images/socio-economic.svg",
    via: true,
    lastUpdate: {
      de: "19.08.2024",
      en: "August 19, 2024",
    },
    id: "socio-economic",
    links: [
      {
        label: "Bertelsmann Stiftung - www.wegweiser-kommune.de",
        href: "https://www.wegweiser-kommune.de",
      },
    ],
  },
];
