import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdInformation } from "react-icons/io";
import classes from "./statisticDataOfRegion.module.scss";
import { INDICATORS_POPUP_DETAILS } from "../../../utils/constants";

export default function StatisticsDataOfRegion({
  data,
  fetchChart = () => {},
  setAttribute = () => {},
  setSelectedIndicator = () => {},
  selectedAttributes,
  setSelectedAttributes = () => {},
  setSelectedIndicatorInfo = () => {},
  everyWeg,
}) {
  const { t } = useTranslation("translations");
  const { i18n } = useTranslation();

  const handleAddOrRemove = async ({ name, value, unit, friendlyURL }) => {
    const itemExists = selectedAttributes.some(
      (attribute) => attribute.name === name
    );

    if (itemExists) {
      // If it exists, filter it out to remove
      setSelectedAttributes((prevAttributes) =>
        prevAttributes.filter((attribute) => attribute.name !== name)
      );
    } else {
      // Construct the new item
      const newItem = { name, value, unit, friendlyURL };

      // Add the new item to the array
      setSelectedAttributes((prevAttributes) => [...prevAttributes, newItem]);
    }
  };

  return data.map(({ name, value, unit, friendlyURL }, index) => {
    const itemExists = selectedAttributes.some(
      (attribute) => attribute.name === name
    );

    let val =
      value === "number" ? value.toFixed(2)?.toString() : value?.toString();
    if (i18n.language !== "en" && value) {
      val = Number(val).toLocaleString("de-DE");
    }

    return (
      <tr
        key={name}
        style={{ cursor: "pointer" }}
        onClick={() => {
          fetchChart("liniendiagramm", friendlyURL);
          setSelectedIndicator(friendlyURL);
          setAttribute(name);
        }}
      >
        {!everyWeg && (
          <td onClick={(e) => e.stopPropagation()}>
            <input
              checked={itemExists}
              onChange={() =>
                handleAddOrRemove({ name, value, unit, friendlyURL })
              }
              type="checkbox"
            />
          </td>
        )}
        <td>
          <div className={classes.flex}>
            {t(name?.replace(":", " "))}
            <div
              onClick={(e) => {
                e.stopPropagation();
                setSelectedIndicatorInfo(INDICATORS_POPUP_DETAILS[index]);
              }}
            >
              <IoMdInformation className={classes["open-information-icon"]} />
            </div>
          </div>
        </td>
        <td>{val || "-"}</td>
        <td>{t(unit)}</td>
      </tr>
    );
  });
}
