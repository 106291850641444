import { useMap, Marker, Popup } from 'react-leaflet';
import { useState, useEffect, useRef } from "react";
import file from "./../../../data/Database/VerkehreCottbus.json"

export const SearchIcon = ({ lat, lng, icon, label, nutsInfo, data }) => {
    const [filteredData, setFilteredData] = useState([]);
    const map = useMap();
    const markerRef = useRef(null);

    useEffect(() => {
        // Filter data based on NUTS information
        const filtered = file.filter(entry => entry.NUTS3 === nutsInfo);
        setFilteredData(filtered);
    }, [nutsInfo, file]);

    useEffect(() => {
        // Cleanup function to remove markers when component unmounts
        return () => {
            filteredData.forEach((marker) => map.removeLayer(marker));
        };
    }, [filteredData, map]);

    const marker = markerRef.current;

    const openMarker = ()=>{
        if (marker) {
            marker.openPopup();
        }
    }
    return (
        <Marker ref={markerRef} position={[lng, lat]} onClick={()=>openMarker}>
            <Popup>
                <div>
                    <h3>{label}</h3>
                    {filteredData.map((entry, index) => (
                        <div key={index}>
                            {Object.entries(entry).map(([key, value]) => (
                                <p key={key}>{key}: {value}</p>
                            ))}
                        </div>
                    ))}
                </div>
            </Popup>
        </Marker>
    );
};
