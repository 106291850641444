import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "../filterSection/filterSection.module.scss";
import FilterIcon from "../../icons/filter.icon";
import Slider from "rc-slider";
import { RAILWAY_SIDINGS } from "../../../utils/constants";
import railwaySidingRegions from "../../../data/Database/FHG_SCS_SIDING_Ergebnispunkte.json";
import { useTranslation } from "react-i18next";
import CITIES from "../../../data/VerkehreCottbus.json";

const Filter = ({
  image,
  name,
  filter,
  values,
  singleItem,
  onCheckboxChange,
  isRailwaySiding = false,
  isChecked = false,
  setFilterSelections = () => {},
  isRoadTacks = false,
  component,
  filterSelections = {},
  isRailway = false,
}) => {
  const [open, setOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [railwaySidingSelectedValues, setRailwaySidingSelectedValues] =
    useState({
      "Dahme-Spreewald": true,
      "Spree-Neiße": true,
      "Oberspreewald-Lausitz": true,
      "Elbe-Elster": true,
      Bautzen: true,
      Görlitz: true,
      "Cottbus, Stadt": true,
    });

  const [citiesSelectedValues, setCitiesSelectedValues] = useState({});
  const { t } = useTranslation("translations");

  const handleCheckboxChange = (value) => {
    if (singleItem === "railwaySidings") {
      if (value !== "railwaySidings") {
        setRailwaySidingSelectedValues((prevState) => {
          const updatedValues = {
            ...prevState,
            [value]: !prevState[value],
          };

          // If the value is unchecked, remove it from the state
          if (!updatedValues[value]) {
            delete updatedValues[value];
          }
          onCheckboxChange(filter, value, !updatedValues[value], updatedValues);
          if (updatedValues.hasOwnProperty("railwaySiding")) {
            delete updatedValues["railwaySidings"];
          }
          return updatedValues;
        });
      }
    } else {
      setSelectedValues((prevState) => ({
        ...prevState,
        [value]: !prevState[value],
      }));
      onCheckboxChange(filter, value, !selectedValues[value]);
    }
  };

  const handleFilterIcon = () => {
    const updateDropdownValue = !openDropdown;
    setOpenDropdown(updateDropdownValue);
  };

  const [sliderValue, setSliderValue] = useState([1000000, 1000000000]);

  const handleSliderChange = (value) => {
    setSliderValue(value);
    setFilterSelections((prevState) => ({
      ...prevState,
      "Traffic Volume Threshold": {
        ...prevState["Traffic Volume Threshold"],
        trafficThresholdValue: value,
      },
    }));
  };

  useEffect(() => {
    if (isChecked && filter === "Traffic Volume Threshold") {
      handleSliderChange([1000000, 1000000000]);
    }
  }, [isChecked, filter]);

  const [showInputField, setShowInputField] = useState({
    min: false,
    max: false,
  });

  const handleConvertToInputField = (index) => {
    setShowInputField((prevState) => {
      if (index === 0) {
        return { ...prevState, min: true };
      } else if (index === 1) {
        return { ...prevState, max: true };
      }
      return prevState;
    });
  };

  const handleCloseInputField = (index) => {
    setShowInputField((prevState) => {
      if (index === 0) {
        return { ...prevState, min: false };
      } else if (index === 1) {
        return { ...prevState, max: false };
      }
      return prevState;
    });
  };

  useEffect(() => {
    if (isRoadTacks || isRailway) {
      setOpen(isChecked);
      setSelectedValues({ [singleItem]: isChecked });
    }
  }, [isRoadTacks, singleItem, isRailway, isChecked]);

  useEffect(() => {
    if (open && singleItem === "railwaySidings") {
      onCheckboxChange(
        filter,
        "railwaySiding",
        true,
        railwaySidingSelectedValues
      );
    } else if (!open && singleItem === "railwaySidings") {
      setOpenDropdown(false);
      onCheckboxChange(filter, "railwaySiding", false, {});
    }
  }, [open, singleItem]);

  const aroundLausitz = useMemo(() => {
    if (values?.length && singleItem === "railwaySidings") {
      return values
        .filter((region) => {
          const singleRailwaySidingRegionData = railwaySidingRegions.find(
            (r) => r.Landkreis === region
          );
          return singleRailwaySidingRegionData?.aroundLausitz;
        })
        .sort((a, b) => a.localeCompare(b)); // Sort alphabetically
    } else if (values?.length && singleItem === "cities") {
      return values
        .filter((region) => {
          const filterRegion = CITIES.find((r) => r.Region === region);
          return !filterRegion?.in_lausitz;
        })
        .sort((a, b) => a.localeCompare(b)); // Sort alphabetically;
    }
    return [];
  }, [values, railwaySidingRegions, singleItem, CITIES]);

  const lausitzRegion = useMemo(() => {
    if (values?.length && singleItem === "railwaySidings") {
      return values
        .filter((region) => {
          const singleRailwaySidingRegionData = railwaySidingRegions.find(
            (r) => r.Landkreis === region
          );
          return !singleRailwaySidingRegionData?.aroundLausitz;
        })
        .sort((a, b) => a.localeCompare(b)); // Sort alphabetically
    } else if (values?.length && singleItem === "cities") {
      return values
        .filter((region) => {
          const filterRegion = CITIES.find((r) => r.Region === region);
          return filterRegion?.in_lausitz;
        })
        .sort((a, b) => a.localeCompare(b)); // Sort alphabetically;
    }
    return [];
  }, [values, railwaySidingRegions, singleItem, CITIES]);

  return (
    <div className={classes.filter}>
      <div className={classes["filter-data"]}>
        <div className={classes["fitler-content"]}>
          <div className={classes["filter-selector"]}>
            <input
              // className={
              //   !isAllRegionsSelected && singleItem === "railwaySidings"
              //     ? classes.line
              //     : ""
              // }
              type="checkbox"
              name={`filter-${name}`}
              id={`filter-${name}`}
              checked={open}
              onChange={() => {
                if (singleItem) {
                  handleCheckboxChange(singleItem);
                }
                setOpen(!open);
              }}
            />
          </div>
          <div
            className={classes["filter-fields"]}
            style={{ padding: !image ? "15px" : "" }}
          >
            {image && (
              <div className={classes["filter-image"]}>
                <img
                  className={
                    image?.includes("png") ? classes["width-and-height"] : ""
                  }
                  src={image}
                  alt=""
                />
              </div>
            )}
            <span
              onClick={() => {
                if (values?.length) {
                  handleFilterIcon();
                }
              }}
              className={`${classes["filter-name"]} ${
                values?.length ? classes.pointer : ""
              }`}
            >
              {name}
            </span>
          </div>
          {values?.length > 0 && (
            <FilterIcon
              style={{ cursor: "pointer" }}
              open={openDropdown}
              onClick={() => handleFilterIcon()}
            />
          )}
        </div>
      </div>
      {isChecked && filter === "Traffic Volume Threshold" && (
        <div className={classes["slider-container"]}>
          <>
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              range
              min={1000000}
              max={1000000000}
            />
            <div className={classes["min-max-value-container"]}>
              <div>
                <b>Min:</b>
                <div
                  className={`${classes["input-and-close"]} ${classes.ml}`}
                  onClick={() => handleConvertToInputField(0)}
                >
                  {!showInputField.min && <p>{sliderValue[0]} TKM</p>}
                  {showInputField.min && (
                    <div onClick={(e) => e.stopPropagation()}>
                      <input
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          if (inputValue === "") {
                            inputValue = 0;
                          }
                          const data = [inputValue, sliderValue[1]];
                          handleSliderChange(data);
                        }}
                        value={sliderValue[0]}
                        type="number"
                      />
                      <span
                        onClick={() => handleCloseInputField(0)}
                        className={classes["close-icon"]}
                      >
                        &times;
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <b>Max:</b>
                <div
                  className={classes["input-and-close"]}
                  onClick={() => handleConvertToInputField(1)}
                >
                  {!showInputField.max && <p>{sliderValue[1]} TKM</p>}
                  {showInputField.max && (
                    <div onClick={(e) => e.stopPropagation()}>
                      <input
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          if (inputValue === "") {
                            inputValue = 0;
                          }
                          const data = [sliderValue[0], inputValue];
                          handleSliderChange(data);
                        }}
                        value={sliderValue[1]}
                        type="number"
                      />
                      <span
                        onClick={() => handleCloseInputField(1)}
                        className={classes["close-icon"]}
                      >
                        &times;
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      )}
      {isChecked && filterSelections?.[filter]?.[singleItem] && (
        <>{component}</>
      )}
      {open && openDropdown && values?.length > 0 && (
        <>
          {singleItem === "railwaySidings" || singleItem === "cities" ? (
            <>
              <p className={classes.heading}>Lausitz</p>
              <FilterValueList
                values={lausitzRegion}
                selectedValues={
                  singleItem === "cities"
                    ? citiesSelectedValues
                    : railwaySidingSelectedValues
                }
                handleCheckboxChange={handleCheckboxChange}
              />
              <p className={classes.heading}>{t("Around Lausitz")}</p>
              <FilterValueList
                values={aroundLausitz}
                selectedValues={
                  singleItem === "cities"
                    ? citiesSelectedValues
                    : railwaySidingSelectedValues
                }
                handleCheckboxChange={handleCheckboxChange}
              />
            </>
          ) : (
            <>
              <FilterValueList
                values={values}
                selectedValues={selectedValues}
                handleCheckboxChange={handleCheckboxChange}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

const FilterValueList = ({ values, selectedValues, handleCheckboxChange }) => {
  return (
    <div className={classes["filter-values"]}>
      {values?.map((val, index) => (
        <div
          key={index}
          className={`${classes["filter-value"]} ${
            selectedValues[val] ? classes["selected-filter"] : ""
          }`}
          onClick={() => handleCheckboxChange(val)}
        >
          {selectedValues[val] && <span>{"\u2713"}</span>}
          {val}
        </div>
      ))}
    </div>
  );
};

export default Filter;
