import React from "react";
import classes from "./partnerLogos.module.scss";

const IMAGES = [
    {
        src: '/images/partner-logos/mFUND_Logo.svg',
        link: 'https://bmdv.bund.de/DE/Themen/Digitales/mFund/Ueberblick/ueberblick.html',
    },
    {
        src: '/images/partner-logos/gefordert.gif',
        link: 'https://bmdv.bund.de/DE/Home/home.html',
    },
    {
        src: '/images/partner-logos/technische.png',
        link: 'https://www.tu.berlin/logistik',
    },
    {
        src: '/images/partner-logos/ihk.JPG',
        link: 'https://www.cottbus.ihk.de/',
    },
    {
        src: '/images/partner-logos/ipg.png',
        link: 'https://www.ipg-potsdam.de/',
    },
    {
        src: '/images/partner-logos/i-ways.jpg',
        link: 'https://i-ways.net/',
    },
    {
        src: '/images/partner-logos/lion.png',
        link: 'https://www.lion-group.org/en/home/',
        width: true,
    },
    {
        src: '/images/partner-logos/mar.jpg',
        link: 'https://www.marberlin.com/',
    },
    {
        src: '/images/partner-logos/tss.png',
        link: 'https://www.tss-logistik.de/de/',
    },
];

export default function PartnerLogos() {
  return (
    <div className={classes["logos-container"]}>
      <div className={classes["images-container"]}>
        {/* <div className={classes.row}> */}
        {IMAGES.map((img) => (
          <a href={img.link} target="_blank" rel="noreferrer">
            <div key={img.src} className={classes["single-img"]}>
              <img
                className={`${classes.img} ${img.width ? classes.small : ""}`}
                src={img.src}
                alt=""
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
