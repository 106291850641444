"use client";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import germanyIcon from "./../../assets/germany.png";
import britishFlagIcon from "./../../assets/british-flag.png";
import classes from "./languageSelector.module.scss";
import { LanguageContext } from "../../context/languageSelector";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const { setSelectedLanguage } = useContext(LanguageContext);

  const updateURL = (lng) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("lang", lng);
    const newRelativePathQuery =
      window.location.pathname + "?" + queryParams.toString();
    window.history.pushState(null, "", newRelativePathQuery);
  };

  const changeLanguage = (lng) => {
    if (!lng) {
      lng = "de";
    }
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng).then(() => {
      updateURL(lng);
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const langValue = queryParams.get("lang");
    if (langValue && langValue !== i18n.language) {
      changeLanguage(langValue);
    }
  }, []);

  return (
    <div className={classes["language-switcher"]}>
      <div className={classes["buttons-container"]}>
        <button
          className={i18n.language === "en" ? classes.active : ""}
          onClick={() => {
            changeLanguage("en");
          }}
        >
          <img src={britishFlagIcon} alt="English" /> <p>English</p>
        </button>
        <button
          className={i18n.language === "de" ? classes.active : ""}
          onClick={() => changeLanguage("de")}
        >
          <img src={germanyIcon} alt="German" /> <p>Deutsch</p>
        </button>
        {/* <button
          className={i18n.language === "leichteSprache" ? classes.active : ""}
          onClick={() => changeLanguage("leichteSprache")}
        >
          <img src={germanyIcon} alt="German" /> <p>Leichte Sprache:Deutsch</p>
        </button> */}
      </div>
    </div>
  );
};

export default LanguageSelector;
