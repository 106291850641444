import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import classes from "./barChartWithTooltip.module.scss";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartWithTooltip = ({ JSONData, title = "" }) => {
  const labels = [
    "0-2",
    "3-5",
    "6-9",
    "10-15",
    "16-18",
    "19-24",
    "25-44",
    "45-64",
    "65-79",
    "80+",
  ];

  const { t } = useTranslation("translations");
  const { i18n } = useTranslation();

  const chartData = {
    labels,
    datasets: [
      {
        label: "",
        data: JSONData?.indicators?.map((d) => {
          let value = d.regionYearValues[0][0];
          return value || 0;
        }),
        backgroundColor: "rgb(237, 152, 52)",
      },
      {
        label: "",
        data: JSONData?.indicators?.map((d) => {
          let value = d.regionYearValues[0]?.[1];
          return value || 0;
        }),

        backgroundColor: "rgb(204, 204, 204)",
      },
    ],
  };

  const options = useMemo(() => {
    if (JSONData) {
      const unit = JSONData.indicators[0]?.unit;
      let heading = t(title);
      if (unit) {
        heading += unit ? ` (${unit})` : "";
      }

      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          title: {
            display: true,
            text: heading,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                // Get the index of the hovered bar
                const index = context.dataIndex;
                // Get the corresponding indicator object from JSONData
                const indicator = JSONData.indicators[index];
                // Return the friendlyUrl as the tooltip label
                let value = context.raw || 0;
                if (context.raw && i18n.language !== "en") {
                  value = Number(value).toLocaleString("de-DE");
                }
                let label = indicator.friendlyUrl || "";
                if (label) {
                  label = label?.endsWith("-1") ? label.slice(0, -2) : label;
                }
                return `${t(label)}: ${value}`;
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false, // Removes all vertical grid lines
            },
          },
        },
      };
    }
    return {};
  }, [JSONData, i18n.language, t, title]);

  return (
    <div className={classes["bar-chart-container"]}>
      <Bar JSONData={JSONData} options={options} data={chartData} />
    </div>
  );
};

export default BarChartWithTooltip;
