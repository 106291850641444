"use client";

import React, { useEffect, useMemo, useState } from "react";
import classes from "./statsPopup.module.scss";
import LineChartWithTooltip from "../../lineChartWithTooltip";
import { useTranslation } from "react-i18next";
import { WegweiserService } from "../../../services";
import Source from "../../sharedComponent/source";

const Popup = ({
  wegData2,
  onClose,
  compare = false,
  data = [],
  selectedRegionFriendlyURL = "",
  renderRegionCheckboxes = () => {},
  loading = false,
  lineChart = false,
  jsonData = undefined,
  name = "",
  selectedRegions = [],
}) => {
  const { t } = useTranslation("translations");

  const checkboxes = useMemo(() => {
    if (renderRegionCheckboxes) {
      return renderRegionCheckboxes() || null;
    }
    return null;
  }, [renderRegionCheckboxes]);

  return (
    <div className={classes["popup-overlay"]}>
      <div className={classes["popup-content"]}>
        <span className={classes["popup-close"]} onClick={onClose}>
          &times;
        </span>
        <div className={classes["popup-chart"]}>
          {!compare ? (
            <>
              <h3 style={{ paddingTop: "10px", textAlign: "center" }}>
                {t(name)}
              </h3>
              <div>
                <div
                  className={`${classes["chart-container"]} ${
                    !lineChart ? classes["h-unset"] : ""
                  }`}
                >
                  {lineChart && jsonData && (
                    <LineChartWithTooltip
                      JSONData={jsonData ? JSON.parse(jsonData) : {}}
                      selectedRegions={selectedRegions}
                    />
                  )}
                </div>
                {loading && (
                  <div className={classes["loading-overlay"]}>
                    <div className={classes["loading-container"]}>
                      <p>{t("loading")}...</p>
                    </div>
                  </div>
                )}
              </div>

              {!lineChart && wegData2 && (
                <img
                  src={`data:image/jpg;base64,${wegData2}`}
                  alt="Weg Data 2"
                />
              )}
            </>
          ) : (
            <CompareTableAndChart
              data={data}
              selectedRegionFriendlyURL={selectedRegionFriendlyURL}
            />
          )}
        </div>
        {checkboxes && (
          <>
            <div className={classes["region-container"]}>
              <h4>{t("Regions")}:</h4>
              {renderRegionCheckboxes()}
            </div>
            <Source />
          </>
        )}
      </div>
    </div>
  );
};

const CompareTableAndChart = ({ data, selectedRegionFriendlyURL }) => {
  const [graphs, setGraphs] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("translations");
  const { i18n } = useTranslation();

  const fetchDataForIndicators = async () => {
    const results = [];

    for (const item of data) {
      // Delay the execution to simulate the timeout behavior (if necessary)
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        const response = await WegweiserService.fetchStatsByFriendlyURL({
          indicator: item.friendlyURL,
          friendlyURL: selectedRegionFriendlyURL,
          startYear: "2006",
          endYear: "2024",
          chart: "liniendiagramm",
          arrayBuffer: true,
        });

        const buffer = await response.data;

        // Convert arrayBuffer to base64
        const base64String = buffer
          ? btoa(
              new Uint8Array(buffer).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )
          : "";

        results.push({
          friendlyURL: item.friendlyURL,
          base64String,
          name: item.name,
          unit: item.unit,
        });
      } catch (error) {
        console.error(
          `Error fetching image for indicator ${item.friendlyURL}:`,
          error
        );
        results.push({
          friendlyURL: item.friendlyURL,
          error: true,
          name: item.name,
        });
      }
    }

    return results;
  };

  const fetchGraphs = async () => {
    try {
      setLoading(true);
      const fetchedData = await fetchDataForIndicators();
      setGraphs(fetchedData.filter((data) => !data.error));
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data.length) {
      fetchGraphs();
    }
  }, [JSON.stringify(data)]);

  if (loading) {
    return <h1>{t("loading")}....</h1>;
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <table className={classes.dataTable}>
        <thead>
          <tr>
            {data.length > 0 &&
              data.map((d) => {
                return (
                  <th>
                    {t(d.name)} {d.unit}
                  </th>
                );
              })}
          </tr>
        </thead>

        <tbody>
          <tr>
            {data.map((d) => {
              let value = d.value;
              if (i18n.language !== "en" && value) {
                value = d.value.toString().replace(".", ",");
              }
              return (
                <td>
                  {value} {d.unit}
                </td>
              );
            })}
          </tr>
          <tr>
            <td colSpan={data?.length || 0}>
              <Source />
            </td>
          </tr>
        </tbody>
      </table>
      {graphs.length > 0 &&
        graphs.map((g, i) => {
          return (
            <div key={g.friendlyURL}>
              <h2>{t(g.name)}</h2>
              <img
                src={`data:image/jpg;base64,${g.base64String}`}
                alt="Compare Attributes.png"
                style={{
                  maxWidth: "1040px",
                  objectFit: "contain",
                  display: "block",
                  margin: "0 auto",
                }}
              />
              <div style={{ maxWidth: "750px", margin: "0 auto" }}>
                <Source />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Popup;
