// Import necessary modules and styles
import React from "react";
import DataTable from "../dataTable";
import classes from "./sidebar.module.scss";
import TollDataTable from "../tollData/tollDataTable";

const SideBar = ({
  data = {},
  selectedRegion = "",
  onClose,
  isTollData = false,
}) => {
  return (
    <div
      className={`${classes.sidebar} ${isTollData ? classes["w-unset"] : ""}`}
    >
      <div className={classes["sidebar-header"]}>
        {/* Add an onClick handler to the close icon */}
        <span
          className={classes["close-icon"]}
          style={{ float: "right" }}
          onClick={onClose}
        >
          &times;
        </span>
      </div>
      <h2 style={{ textAlign: "center" }}>{selectedRegion}</h2>
      {!isTollData ? (
        <DataTable data={data} terminal />
      ) : (
        <TollDataTable data={data} />
      )}
    </div>
  );
};

export default SideBar;
