import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaDirections } from "react-icons/fa";
import ComingSoonAnimation from "./animation";
import VK from "../../data/VerkehreCottbus.json";
import InformationPopup from "./informationPopup";
import AutoComplete from "../autoComplete";
import { useTranslation } from "react-i18next";
import data from "../../data/Database/FHG_SCS_SIDING_Ergebnispunkte.json";
import { FILTER_TERMINALS, RAILWAY_SIDINGS } from "../../utils/constants";
import CombinedRouting from "../map/routing-machine/combinedRouting";
import TransportEmission from "../transportEmission";
import classes from "./mainSidebar.module.scss";
import LeftArrowIcon from "../icons/leftArrow.icon";
import RightArrowIcon from "../icons/rightArrow.icon";
import PrintIcon from "../icons/printIcon.icon";
import ShareIcon from "../icons/share.icon";
import MapStylesOptions from "../openRailwayMap/mapStylesOptions";
import { ResetDirectionsContext } from "../../context/reset";
import _ from "lodash";
import { FaSearch } from "react-icons/fa";

import { IoMdInformation } from "react-icons/io";
import Categories from "./categories";
import {
  FILTER_RAILWAY,
  FILTER_ROADS,
  ROUTING_OPTIONS,
  TABS,
  TRANSPORT_VOLUMES_OPTIONS,
} from "../../utils/constants";
import FilterSection from "./filterSection";
import Tabs from "./tabs";
import PulseLoader from "../sharedComponent/pulseLoader";
import PartnerLogos from "../partnerLogos";
import LogoPlaceholder from "../logoPlaceholder";
const MainSidebar = ({
  selectedFiles,
  fetchRelevantFile,
  loadingState,
  onOpenOrCloseSidebar = () => {},
  isSidebarOpen = true,
  setIsSidebarOpen = () => {},
  sourceStation,
  destinationStation,
  filterSelections,
  setRailwaySidingRegions = () => {},
  setSourceStation,
  setDestinationStation,
  setFilterSelections = () => {},
  directionMode,
  setDirectionMode = () => {},
  setDirectionPoints = () => {},
  directionPoints,
  moveToLausitzMap = () => {},
  setRadius = () => {},
  radius,
  setIsSearchedClicked = () => {},
  setSelectedCategoryID = () => {},
  selectedCategoryID = null,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [openInformationPopup, setOpenInformationPopup] = useState(false);
  const { t } = useTranslation("sidebar");
  const { t: translation } = useTranslation("translations");

  const handleOpenInformationPopup = () => {
    setOpenInformationPopup(true);
    setSelectedCategoryID("all");
  };
  const handleCloseInformationPopup = () => {
    setOpenInformationPopup(false);
    setSelectedCategoryID(null);
  };
  const [filterCategory, setFilterCategory] = useState("");

  const ref = useRef();

  const [loading, setLoading] = useState(false);

  const [sourceStations, setSourceStations] = useState([]);

  const { resetDirections, handleResetFalse, handleResetTrue } = useContext(
    ResetDirectionsContext
  );

  useEffect(() => {
    if (onOpenOrCloseSidebar) {
      onOpenOrCloseSidebar(isSidebarOpen);
    }
  }, [isSidebarOpen, onOpenOrCloseSidebar]);

  const handleSelect = (id, option) => {
    setDirectionPoints((prev) => ({
      ...prev,
      [id]: { latitude: option?.raw?.lat, longitude: option?.raw?.lon },
    }));
  };

  const extractDropdownValues = () => {
    const landkreiseSet = new Set(
      data.map((item) => {
        if (item.Landkreis && RAILWAY_SIDINGS.includes(item.Landkreis)) {
          return item.Landkreis;
        }
      })
    );
    // Convert the Set to an array and filter out null or undefined values
    const uniqueLandkreise = Array.from(landkreiseSet).filter(Boolean);
    return uniqueLandkreise;
  };

  // Callback function to handle checkbox changes
  const handleCheckboxChange = (
    filterName,
    value,
    isChecked,
    railwaySidingSelectedValues
  ) => {
    setFilterSelections((prevState) => ({
      ...prevState,
      [filterName]: {
        ...prevState[filterName],
        [value]: isChecked,
      },
    }));
    if (railwaySidingSelectedValues) {
      setRailwaySidingRegions(Object.keys(railwaySidingSelectedValues));
    }
  };

  // Reset filters and move back to lausitz
  const handleExitDirection = async () => {
    setDirectionPoints({
      source: {},
      destination: {},
    });
    setSourceStation(null);
    setDestinationStation(null);

    const updateFilters = { ...filterSelections };

    delete updateFilters["Combined Routing"];
    delete updateFilters["Railway Routing"];
    delete updateFilters["Road Routing"];

    await new Promise((resolve) => {
      setFilterSelections(updateFilters);
      resolve();
    }).then(() => {
      setDirectionMode(false);
      handleResetFalse();
      moveToLausitzMap();
    });
  };

  useEffect(() => {
    if (resetDirections && ref.current) {
      ref.current.click();
    }
  }, [resetDirections, ref]);

  const routingOptions = ROUTING_OPTIONS(t);
  const totalTabs = TABS(t);
  const railWayOptions = FILTER_RAILWAY(t);
  const roadsOptions = FILTER_ROADS(t);
  const transportVolumesOptions = TRANSPORT_VOLUMES_OPTIONS(t, VK);
  const terminalOptions = FILTER_TERMINALS(t, extractDropdownValues);

  const handleKeyPress = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      onChangeRadius(event);
      setIsSearchedClicked(true);
    }
  };

  const onChangeRadius = (event) => {
    const { value } = event.target;
    setRadius(value);
  };

  return (
    <>
      {/* sidebar button */}
      <div
        className={`${classes["sidebar-button"]} ${
          isSidebarOpen ? classes.open : classes.close
        }`}
        onClick={() => setIsSidebarOpen((prev) => !prev)}
      >
        {isSidebarOpen ? <LeftArrowIcon /> : <RightArrowIcon />}
      </div>     
        <span 
        className={classes["floating-buttons"]}
        onClick={() => window.print()}>
          <PrintIcon />
        </span>
      {/* sidebar */}
      {isSidebarOpen && (
        <div className={classes["main-sidebar"]}>
          <div className={classes["sidebar_search"]}>
            <input
              type="text"
              placeholder={translation("searchTerm")}
              onChange={(e) => setFilterCategory(e.target.value)}
            />
            <button
            // onClick={() => setDirectionMode(true)}
            >
              <FaSearch />
            </button>
          </div>
          {directionMode && (
            <>
              <div className={classes["sidebar_direction"]}>
                <AutoComplete
                  onSelect={handleSelect}
                  label={t("Enter Source")}
                  id="source"
                  disabled={loading}
                />

                <AutoComplete
                  onSelect={handleSelect}
                  label={t("Enter Destination")}
                  id="destination"
                  disabled={loading}
                />
              </div>
              <span
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginTop: "10px",
                  float: "right",
                }}
                ref={ref}
                onClick={handleExitDirection}
              >
                {t("exitDirection")}
              </span>
              <div className={classes["scroll"]}>
                {filterSelections &&
                  filterSelections["Railway Routing"] &&
                  filterSelections["Railway Routing"].railwayRouting && (
                    // Render the Combined Routing
                    <CombinedRouting
                      source={directionPoints?.source}
                      destination={directionPoints?.destination}
                      filterSelections={filterSelections}
                      setSourceStation={setSourceStation}
                      setDestinationStation={setDestinationStation}
                      sourceStation={sourceStation}
                      destinationStation={destinationStation}
                      setSourceStations={setSourceStations}
                      sourceStations={sourceStations}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  )}
                <FilterSection
                  label={t("routingOptions")}
                  data={routingOptions}
                  handleCheckboxChange={handleCheckboxChange}
                  m0
                />
                <TransportEmission
                  viaValue="A13"
                  hours="1 hr 30 min"
                  shortestRouteKm="120 km"
                  totalCarbonEmission="1.512 tkm"
                  thresholdLevel="400 tkm"
                  emissionRatePerKm="0.105 t"
                />
              </div>
            </>
          )}

          {!directionMode && (
            <>
              {/* Show Tabs */}
              <Tabs
                data={totalTabs}
                onClick={setActiveTab}
                activeTab={activeTab}
              />
              {/* Show default Tab Details */}
              {activeTab === 0 && (
                <div className={classes["categories-and-filters-container"]}>
                  <div className={classes["sidebar_categories"]}>
                    <div className={classes["sidebar_categories_header"]}>
                      <span className={classes["categories-heading"]}>
                        {t("categoriesTab")}
                      </span>
                      <div className={classes.tooltip}>
                        <div onClick={handleOpenInformationPopup}>
                          <IoMdInformation
                            className={classes["open-information-icon"]}
                          />
                        </div>
                      </div>
                    </div>
                    <Categories
                      fetchRelevantFile={fetchRelevantFile}
                      selectedFiles={selectedFiles}
                      handleSelectedID={setSelectedCategoryID}
                      loading={loadingState}
                      handleCheckboxChange={handleCheckboxChange}
                      // railway
                      railWayOptions={railWayOptions}
                      // transportVolumes
                      transportVolumesOptions={transportVolumesOptions}
                      filterSelections={filterSelections}
                      setFilterSelections={setFilterSelections}
                      // roadTacks
                      roadsOptions={roadsOptions}
                      // Railway Map
                      onChangeRadius={onChangeRadius}
                      handleKeyPress={handleKeyPress}
                      radius={radius}
                      setIsSearchedClicked={setIsSearchedClicked}
                      railwayMapClass={classes}
                      // Terminal Options
                      terminalOptions={terminalOptions}
                      // Filter Category
                      filterCategory={filterCategory}
                    />
                  </div>
                  {/* Render selected style - Open Railway Map  */}
                  {/* {selectedFiles.includes("openRailwayMap") && (
                    <MapStylesOptions
                      selectedOption={selectedRailwayMapStyle?.style}
                      onChange={setSelectedRailwayMapStyle}
                    />
                  )} */}

                  {/* Render Coming Soon Animation */}
                  {/* <div className={classes["sidebar_maintenance"]}>
                    <ComingSoonAnimation />
                    <div className={classes["sidebar_maintenance_text"]}>
                      {t("comingSoonNote")}
                    </div>
                  </div> */}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* Categories Information Popup */}
      {(openInformationPopup || selectedCategoryID) && (
        <InformationPopup
          selectedCategoryID={selectedCategoryID}
          onClose={handleCloseInformationPopup}
        />
      )}
      <LogoPlaceholder isSidebarOpen={isSidebarOpen} />
    </>
  );
};

export default MainSidebar;
