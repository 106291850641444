export const mapStore = (set) => ({
  mapColors: {
    roads: {
      motorway: "#13139b",
      mainRoad: "#ff9933",
    },
    railway: {
      leag: "#7ab929",
      siding: "blue",
      root: "#EC0117",
      routing: "orange",
      combinedRouting: "red",
      roadRouting: "#FF6666",
      railwayRouting: "#FF6666",
    },
    transportVolumes: {
      root: "#112255",
    },
    tollData: {
      root: "blue",
    },
    railwayMap: {
      nonElectrified: "#cc0000",
      electrified: "#7b3706",
    },
    steckenPolyline: {
      bkr: "#990000",
      krg: "#990000",
    },
  },
});
