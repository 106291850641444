import React, { useEffect, useState } from "react";
import TooltipSlider from "../../tooltipSlider";
import classes from "./graphPopup.module.scss";
import BarChartWithTooltip from "../../barChartWithTooltip";
import { useTranslation } from "react-i18next";
import {
  INDICATORS_POPUP_DETAILS,
  REGIONS_OPTIONS,
} from "../../../utils/constants";
import Loader from "../../sharedComponent/loader";
import Modal from "../../sharedComponent/modal";
import { IoMdInformation } from "react-icons/io";
import Source from "../../sharedComponent/source";

const GraphPopup = ({
  jsonData,
  onClose,
  loading = false,
  onSliderChange = () => {},
  friendlyUrl = "",
  setSliderValue = () => {},
  sliderValue,
  minYear,
  maxYear,
  title = "",
  hasTable = false,
  setSelectedRegionsIds = () => {},
  selectedRegionsIds = () => {},
  tableData = null,
}) => {
  const [baseYear, setBaseYear] = useState(false);
  const { t } = useTranslation("translations");
  const { i18n } = useTranslation();
  const [selectedIndicatorInfo, setSelectedIndicatorInfo] = useState(null);

  const handleSliderChange = (value) => {
    if (loading) return;

    setSliderValue(value);
    onSliderChange(!hasTable ? friendlyUrl : [], value, baseYear);
  };

  const handleChangeBaseYear = (value) => {
    if (loading) return;
    setBaseYear(value);
    onSliderChange(!hasTable ? friendlyUrl : [], sliderValue, value);
  };

  const handleRegionCheckboxChange = (id) => {
    if (loading) return;
    const isSelected = selectedRegionsIds.includes(id);

    if (isSelected) {
      setSelectedRegionsIds(
        selectedRegionsIds.filter((regionId) => regionId !== id)
      );
    } else {
      setSelectedRegionsIds([...selectedRegionsIds, id]);
    }
  };

  useEffect(() => {
    if (hasTable && selectedRegionsIds) {
      onSliderChange([], sliderValue, baseYear);
    }
  }, [selectedRegionsIds]);

  const handleSelectedIndicator = (name) => {
    setSelectedIndicatorInfo(
      INDICATORS_POPUP_DETAILS.find((indicator) => indicator.name === name)
    );
  };

  return (
    <>
      <div className={classes.modal}>
        <div
          className={`${classes["modal-content"]} ${
            !jsonData ? classes.loading : ""
          } ${hasTable ? classes["width-full"] : ""}`}
        >
          {!jsonData ? (
            <Loader />
          ) : (
            <div className={classes["graph-table-popup-container"]}>
              {loading && (
                <div className={classes["loading-overlay"]}>
                  <div className={classes["loading-container"]}>
                    <p>{t("loading")}...</p>
                  </div>
                </div>
              )}
              <span className={classes.close} onClick={onClose}>
                &times;
              </span>
              {!hasTable ? (
                <BarChartWithTooltip
                  title={title}
                  JSONData={jsonData ? JSON.parse(jsonData) : {}}
                />
              ) : (
                // Indicators details of selected region(s) and year
                <div className={classes["main-table-container"]}>
                  <div className={classes["single-table-container"]}>
                    <div className={classes["table-container"]}>
                      <table className={`${classes.dataTable} ${classes.mt}`}>
                        <thead>
                          <th>{t("Indicator")}</th>
                        </thead>
                        <tbody>
                          {tableData.indicators.map((IND, indIndex) => {
                            let name = IND.name;
                            if (name) {
                              name = name.replace(":", " ");
                            }
                            return (
                              <tr key={`item-${indIndex}`}>
                                <td>
                                  <div className={classes.flex}>
                                    {t(name)} ({IND.unit})
                                    <IoMdInformation
                                      onClick={() =>
                                        handleSelectedIndicator(IND.name)
                                      }
                                      className={
                                        classes["open-information-icon"]
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={classes["scrollable-container"]}>
                    {tableData?.years?.map((year, yearIndex) => (
                      <div
                        className={`${classes["single-table-container"]} ${classes["flex-1"]}`}
                        key={yearIndex}
                      >
                        <div className={classes["table-container"]}>
                          <table className={classes.dataTable}>
                            <thead>
                              {/* <th>Indicator</th> */}
                              {tableData.regions.map((region, i) => (
                                <th key={i}>
                                  {region?.name?.replace(", LK", "")} ({year})
                                </th>
                              ))}
                            </thead>
                            <tbody>
                              {tableData.indicators.map((IND, indIndex) => (
                                <tr key={indIndex}>
                                  {tableData.regions?.map((_, rIndex) => {
                                    let regionValue =
                                      tableData.indicators[indIndex]
                                        .regionYearValues[rIndex][yearIndex];

                                    if (i18n.language !== "en" && regionValue) {
                                      regionValue =
                                        Number(regionValue).toLocaleString(
                                          "de-DE"
                                        );
                                    }
                                    return (
                                      <td key={rIndex}>{regionValue || "-"}</td>
                                    );
                                  })}
                                </tr>
                              ))}
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {/* Year(s) Slider */}
          {(jsonData || hasTable) && (
            <div className={classes.slider}>
              <TooltipSlider
                onChange={handleSliderChange}
                dots
                defaultValue={hasTable ? 2006 : minYear}
                min={hasTable ? 2006 : minYear}
                startingLabel={hasTable ? 2006 : minYear}
                max={hasTable ? 2022 : maxYear}
                endingLabel={hasTable ? 2022 : maxYear}
                value={sliderValue}
                loading={loading}
                showBaseYear
                onChangeBaseYear={handleChangeBaseYear}
                baseYear={baseYear}
                step={hasTable ? 1 : 5}
                trackStyle={{ backgroundColor: "transparent" }}
                dotStyle={{ backgroundColor: "#727272" }}
                activeDotStyle={{
                  backgroundColor: "#727272",
                  border: "2px solid #e9e9e9",
                }}
                handleStyle={{
                  backgroundColor: "rgb(4, 136, 219)",
                  border: "none",
                  opacity: 1,
                }}
              />
              <Source mt />
            </div>
          )}
          {/* Option to select region(s) */}
          {hasTable && (
            <div className={classes["region-container"]}>
              <h4>{t("Regions")}:</h4>
              <div className={classes.regions}>
                {REGIONS_OPTIONS.map((region, index) => (
                  <div className={classes.region} key={index}>
                    <input
                      type="checkbox"
                      checked={selectedRegionsIds.includes(region.id)}
                      onChange={() => handleRegionCheckboxChange(region.id)}
                    />
                    <label>{region.name}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {selectedIndicatorInfo && (
        <Modal onClose={() => setSelectedIndicatorInfo(null)}>
          <div className={classes["indicator-info-container"]}>
            <p className={classes.bold}>{t(selectedIndicatorInfo.name)}</p>
            <p>{selectedIndicatorInfo.explanation}</p>
            <p className={classes.heading}>{t("Calculation")}:</p>
            <p>{selectedIndicatorInfo.calculation}</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default GraphPopup;
