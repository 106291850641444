import React from "react";
import classes from "./miniLoader.module.scss";

export default function MiniLoader() {
  return (
    <div className={classes.loader}>
      <div className={classes.spinner} />
    </div>
  );
}
