import React, { useState, useEffect } from "react";
import classes from "./legends.module.scss";
import ThreeLinesIcon from "../icons/three-lines.icon";
import LegendCard from "../mainSidebar/legendCard";
import { useTranslation } from "react-i18next";
import { useMap } from "react-leaflet";
import L from "leaflet";

export default function Legends({ selectedFiles = [], filterSelections = {} }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("sidebar");

  const map = useMap();

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleMapClick = () => setOpen(false);

    map.on("popupopen", handleMapClick);

    // Add event listeners
    map.on("click", handleMapClick);

    map.eachLayer(function (layer) {
      if (layer instanceof L.Marker) {
        layer.on("click", function (e) {
          handleMapClick();
        });
      }
    });

    return () => {
      // Clean up event listeners
      map.off("click", handleMapClick);
    };
  }, [map]);

  return (
    <div className={classes["legends-container"]}>
      <button className={open ? classes.active : ""} onClick={handleOpen}>
        <ThreeLinesIcon />
        {t("legend")}
      </button>
      {open && (
        <LegendCard
          removeHeading
          selectedFiles={selectedFiles}
          filterSelections={filterSelections}
        />
      )}
    </div>
  );
}
