import React, { useState } from "react";
import classes from "./comparisionTable.module.scss";
import { useTranslation } from "react-i18next";
import { IoMdInformation } from "react-icons/io";
import { INDICATORS_POPUP_DETAILS } from "../../../utils/constants";
import Modal from "../../sharedComponent/modal";
import Source from "../../sharedComponent/source";

export default function ComparisionTable({ handleRowClick, regions, data }) {
  const { t } = useTranslation("translations");
  const { i18n } = useTranslation();
  const [selectedIndicatorInfo, setSelectedIndicatorInfo] = useState(null);

  return (
    <>
      <div className={classes["tables-container"]}>
        <table className={classes.dataTable}>
          <thead>
            <tr>
              <th>{t("Selected Indicators")}</th>
              {regions.map((region, index) => (
                <th key={index}>{region.name ? region.name + " (2022)": ""}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((data, dataIndex) => (
              <tr key={dataIndex} onClick={() => handleRowClick(data)}>
                <td className={classes.flex}>
                  {t(data?.name?.replace(":", " "))}
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedIndicatorInfo(
                        INDICATORS_POPUP_DETAILS[dataIndex]
                      );
                    }}
                    className={classes["info-icon-container"]}
                  >
                    <IoMdInformation
                      className={classes["open-information-icon"]}
                    />
                  </div>
                </td>
                {regions.map((region, index) => {
                  let value = region?.data[dataIndex]?.value;
                  if (i18n.language !== "en" && value) {
                    value = Number(value).toLocaleString("de-DE");
                  }
                  let unit = region?.data[dataIndex]?.unit;
                  if (unit && value) {
                    value += ` ${unit}`;
                  }

                  return <td key={index}>{value || "-"}</td>;
                })}
              </tr>
            ))}
            <tr>
              <td colSpan={regions?.length ? regions?.length + 1 : 0}>
                <Source />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {selectedIndicatorInfo && (
        <Modal onClose={() => setSelectedIndicatorInfo(null)}>
          <div className={classes["indicator-info-container"]}>
            <p className={classes.bold}>{t(selectedIndicatorInfo.name)}</p>
            <p>{selectedIndicatorInfo.explanation}</p>
            <p className={classes.heading}>{t("Calculation")}:</p>
            <p>{selectedIndicatorInfo.calculation}</p>
          </div>
          <Source />
        </Modal>
      )}
    </>
  );
}
