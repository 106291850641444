import React from "react";

export default function PrintIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 55 55"
      fill="none"
    >
      <g filter="url(#filter0_d_92_1945)">
        <rect
          x="13.4961"
          y="13.0078"
          width="39.75"
          height="40.125"
          rx="5"
          fill="white"
        />
        <path
          d="M27.7461 30.2578V23.6953H38.9961V30.2578"
          stroke="#484848"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7461 38.6953H25.8711C25.3738 38.6953 24.8969 38.4978 24.5453 38.1461C24.1936 37.7945 23.9961 37.3176 23.9961 36.8203V32.1328C23.9961 31.6355 24.1936 31.1586 24.5453 30.807C24.8969 30.4554 25.3738 30.2578 25.8711 30.2578H40.8711C41.3684 30.2578 41.8453 30.4554 42.1969 30.807C42.5485 31.1586 42.7461 31.6355 42.7461 32.1328V36.8203C42.7461 37.3176 42.5485 37.7945 42.1969 38.1461C41.8453 38.4978 41.3684 38.6953 40.8711 38.6953H38.9961"
          stroke="#484848"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.9961 34.9453H27.7461V42.4453H38.9961V34.9453Z"
          stroke="#484848"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_92_1945"
          x="0.496094"
          y="0.0078125"
          width="69.75"
          height="70.125"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_92_1945"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_92_1945"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
