import React, { createContext, useState } from "react";

const ResetDirectionsContext = createContext();

const ResetDirectionsProvider = ({ children }) => {
  const [resetDirections, setResetDirections] = useState(false);

  const handleResetTrue = () => setResetDirections(true);
  const handleResetFalse = () => setResetDirections(false);

  return (
    <ResetDirectionsContext.Provider
      value={{ handleResetFalse, resetDirections, handleResetTrue }}
    >
      {children}
    </ResetDirectionsContext.Provider>
  );
};

export { ResetDirectionsContext, ResetDirectionsProvider };
