import React from "react";
import classes from "./footer.module.scss";
import { useTranslation } from "react-i18next";
import { FaLinkedin } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";

export default function Footer() {
  const { t } = useTranslation("translations");

  const PAGES = [
    {
      label: "Impressum",
      link: "https://www.distill-lausitz.de/impressum/",
      icon: null, // No icon for Impressum
    },
    {
      label: "Linkedin",
      link: "https://www.linkedin.com/showcase/distill-projekt/posts/?feedView=all",
      icon: <FaLinkedin />,
    },
    {
      label: "Contact Us",
      link: "mailto:distill@logistik.tu-berlin.de",
      icon: <IoMailSharp />
    },
  ];

  return (
    <div className={classes["footer-container"]}>
      <div className={classes.content}>
        <p>
          {t("Copyright Text")} {t("All rights reserved")}.
        </p>
        <div className={classes.pages}>
          {PAGES.map(({ label, link, icon }) => (
            <a
              key={label}
              href={link}
              target="_blank"
              className={`${classes.page} ${
                label === "Privacy Policy" ? classes.border : ""
              }`}
              rel="noreferrer"
            >
              {icon && icon} {t(label)}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
