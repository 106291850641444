// Tooltip.jsx
import React from "react";
import classes from "./tooltip.module.scss";

const Tooltip = ({ text, children }) => {
  return (
    <>
      <div className={classes.wrapper}>
        {children}
        <div class={classes.tooltip}>{text}</div>
      </div>
    </>
  );
};

export default Tooltip;
