import React, { useEffect, useState } from "react";
import classes from "./combinedRouting.module.scss";
import { useTranslation } from "react-i18next";

const CombinedRouting = ({
  source,
  destination,
  setSourceStation = () => {},
  setDestinationStation = () => {},
  sourceStation,
  destinationStation,
  sourceStations = [],
  setSourceStations = () => {},
  setLoading = () => {},
  loading = false,
}) => {
  const [destinationStations, setDestinationStations] = useState([]);
  const [message, setMessage] = useState(null);

  const { t } = useTranslation("sidebar");

  useEffect(() => {
    if (Object.keys(source).length) {
      fetchStations(source.latitude, source.longitude, setSourceStations);
    }
  }, [JSON.stringify(source)]);

  useEffect(() => {
    if (Object.keys(destination).length) {
      fetchStations(
        destination.latitude,
        destination.longitude,
        setDestinationStations
      );
    }
  }, [JSON.stringify(destination)]);

  const fetchStations = (
    latitude,
    longitude,
    setStations,
    radius = 1000,
    maxRadius = 50000
  ) => {
    setLoading(true);
    setMessage(`${t("fetching-near-by")}...`);
    const overpassQuery = `[out:json];
        (
            node["railway"="station"](around:${radius},${latitude},${longitude});
        );
        out center;`;

    fetch("https://overpass-api.de/api/interpreter", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `data=${encodeURIComponent(overpassQuery)}`,
    })
      .then((response) => response.json())
      .then((data) => {
        const stations = data.elements.map((element) => ({
          id: element.id,
          name: element.tags.name || "Train Station",
          lat: element.lat,
          lon: element.lon,
        }));
        if (stations.length === 0 && radius < maxRadius) {
          // If no stations found and radius is less than maxRadius, increase search radius and retry
          setMessage("Expanding area for nearby stations ...");
          fetchStations(
            latitude,
            longitude,
            setStations,
            radius + 5000,
            maxRadius
          );
        } else {
          // Set stations if found or if max radius reached
          setMessage(null);
          setStations(stations);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching train stations:", error);
      });
  };

  const handleSourceChange = (event) => {
    const selectedStationId = parseInt(event.target.value);
    const station = sourceStations.find(
      (station) => station.id === selectedStationId
    );
    setSourceStation(station);
  };

  const handleDestinationChange = (event) => {
    const selectedStationId = parseInt(event.target.value);
    const station = destinationStations.find(
      (station) => station.id === selectedStationId
    );
    setDestinationStation(station);
  };

  return (
    <div className={classes["railway-stations"]}>
      <p>{t("source-destination-text")}</p>
      <div className={classes["source-station"]}>
        <select onChange={handleSourceChange} disabled={loading}>
          {sourceStations.length > 0 ? (
            <>
              <option value="">{t("Nearby Railway Stations (Source)")}</option>
              {sourceStations.map((station) => (
                <option key={station.id} value={station.id}>
                  {station.name}
                </option>
              ))}
            </>
          ) : (
            <option value="">{t("No railway connection found")}</option>
          )}
        </select>
      </div>
      <div className={classes["destination-station"]}>
        <select onChange={handleDestinationChange} disabled={loading}>
          {destinationStations.length > 0 ? (
            <>
              <option value="">
                {t("Nearby Railway Stations (Destination)")}
              </option>
              {destinationStations.map((station) => (
                <option key={station.id} value={station.id}>
                  {station.name}
                </option>
              ))}
            </>
          ) : (
            <option value="">{t("No railway connection found")}</option>
          )}
        </select>
      </div>
      <p style={{ color: "red" }}>{message && message}</p>
    </div>
  );
};

export default CombinedRouting;
