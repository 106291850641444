import React from "react";
import Filter from "../filter";
import classes from "./filterSection.module.scss";

export default function FilterSection({
  data = [],
  label = "",
  handleCheckboxChange = () => {},
  // TransportVolume
  isTransportVolumes = false,
  setFilterSelections = () => {},
  filterSelections = {},
  //   Railway
  isRailway = false,
  // Road Tracks
  isRoadTacks = false,
  m0 = false,
}) {
  return (
    <div className={classes["sidebar_transport_filters"]}>
      <span className={classes["sidebar_transport-heading"]}>{label}</span>
      <div className={classes.filters}>
        {data?.map((f = {}, i) => {
          const isChecked =
            isTransportVolumes || isRoadTacks || isRailway
              ? filterSelections?.[f.filter]?.[f.singleItem]
              : undefined;

          return (
            <Filter
              key={`filter-${i}`}
              image={f.image}
              name={f.name}
              filter={f.filter}
              singleItem={f.singleItem}
              values={f.values}
              onCheckboxChange={handleCheckboxChange}
              rangeSlider={f.rangeSlider}
              isChecked={isChecked}
              setFilterSelections={
                isTransportVolumes ? setFilterSelections : () => {}
              }
              isRailwaySiding={isRailway ? f?.isRailwaySiding : false}
              isRoadTacks={isRoadTacks}
              m0={m0}
              component={f.component}
              filterSelections={filterSelections}
              isRailway={isRailway}
            />
          );
        })}
      </div>
    </div>
  );
}
