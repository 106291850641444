import axios from "axios";
import { axiosInstance } from "../../axios/axiosIntercepter";

class WegweiserService {
  // Fetches details of a specific region.
  getSpecificRegionDetails = async (region) => {
    try {
      let types = "LANDKREIS";
      if (region.includes("Cottbus")) {
        types = "KREISFREIE_STADT";
      }
      const res = await axios.get(
        `https://www.wegweiser-kommune.de/data-api/rest/region/list?max=1&search=${region}&types=${types}`
      );
      return res;
    } catch (ex) {
      throw ex;
    }
  };

  // Fetches statistical data for a specified region and set of indicators

  getStatisticsData = async (data) => {
    try {
      const res = await axiosInstance.post("/wegweiser/statistic/data", data);
      return res;
    } catch (ex) {
      throw ex;
    }
  };

  // Exports the indicator descriptions for the specified friendly URL.

  fetchStatsByFriendlyURL = async ({
    indicator,
    friendlyURL,
    startYear,
    endYear,
    chart,
    isJSON = false,
    arrayBuffer = false,
  }) => {
    try {
      const res = await axios.get(
        `https://www.wegweiser-kommune.de/data-api/rest/export/${indicator}+${friendlyURL}+${startYear}-${endYear}+${chart}.jpg${
          isJSON ? ".json" : ""
        }`,
        {
          ...(arrayBuffer ? { responseType: "arraybuffer" } : {}),
          params: {
            charset: "UTF-8",
            download: false,
            raw: true,
            width: "800",
          },
        }
      );
      return res;
    } catch (ex) {
      throw ex;
    }
  };

  // Fetches airports details

  getAirports = async (pageNo, pageSize) => {
    try {
      const res = await axiosInstance.get(
        `/api/getAirport?pageNo=${pageNo}&pageSize=${pageSize}`
      );

      return res;
    } catch (ex) {
      throw ex;
    }
  };

  // Fetches the properties of an airport by its ID

  getAirportPropertiesById = async (id) => {
    try {
      const res = await axiosInstance.get(
        `/api/getAirport/propertiesById?id=${id}`
      );

      return res;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetches KV-terminal details

  getKVTerminals = async (pageNo, pageSize) => {
    try {
      const res = await axiosInstance.get(
        `/api/getKvTerminal?pageNo=${pageNo}&pageSize=${pageSize}`
      );

      return res;
    } catch (ex) {
      throw ex;
    }
  };
}

const instance = new WegweiserService();

export default instance;
