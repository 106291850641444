import React, { useState } from "react";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import classes from "./autoComplete.module.scss";

const AutoComplete = ({ onSelect, label, id, ...rest }) => {
  const provider = new OpenStreetMapProvider();

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  let timeoutId;

  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);

    // Clear previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout
    timeoutId = setTimeout(async () => {
      const results = await provider.search({ query: inputValue });
      setSuggestions(results);
    }, 500); // Adjust this delay as needed (in milliseconds)
  };

  const handleSelectOption = (option) => {
    setInputValue(option?.label);
    setSuggestions([]);
    onSelect(id, option);
  };

  return (
    <div className={classes["autocomplete-container"]}>
      <input
        className={classes["autocomplete-input"]}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={label}
        {...rest}
      />
      <ul className={classes["autocomplete-suggestions"]}>
        {suggestions.map((option, index) => (
          <li
            key={index}
            className={classes["autocomplete-suggestion"]}
            onClick={() => handleSelectOption(option)}
          >
            {option?.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AutoComplete;
