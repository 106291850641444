import L from "leaflet";
import transportVolume from "../../../assets/mapElements/transport_volumes.png";
import transportVolumeCircle from "../../../assets/mapElements/transport-volume-circle.png";

export const TransportVolumeIcon = new L.Icon({
  iconUrl: transportVolume,
  iconSize: [40, 50], // Adjust the size of your icon
  iconAnchor: [16, 32], // Adjust the anchor point if needed
  popupAnchor: [0, -32], // Adjust the popup anchor if needed
});

export const TransportVolumeEndPointIcon = new L.Icon({
  iconUrl: transportVolumeCircle,
  iconSize: [20, 20],
});
