import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageContext = createContext();

const LanguageSelectorProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const langValue = queryParams.get("lang") || "de";
    if (langValue) {
      setSelectedLanguage(langValue);
      i18n.changeLanguage(langValue);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageSelectorProvider };
