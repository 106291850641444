import { useEffect } from "react";
import { useMap } from "react-leaflet";

const ZoomLevel = ({ zoomValue, isSidebarOpen }) => {
  const map = useMap();

  useEffect(() => {
    if (!isSidebarOpen) {
      map.flyTo([51.5349597, 13.9915388], zoomValue, {
        animate: true,
      });
    } else {
      map.flyTo([51.5349597, 13.6215388], zoomValue);
    }
  }, [map, isSidebarOpen, zoomValue]);

  return null;
};

export default ZoomLevel;
