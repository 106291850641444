import React from "react";
import { useTranslation } from "react-i18next";

const keys = ["city", "email", "phone", "website", "source"];

export default function TerminalTableContent({ classes, data }) {
  const { t } = useTranslation("translations");

  const attributes = data; // Access the "data" property
  return Object.entries(attributes).map(([attribute, value]) => {
    if (keys.includes(attribute)) {
      attribute = attribute.charAt(0).toUpperCase() + attribute.slice(1);
    }

    if (Array.isArray(value)) {
      value = value.toString();
      value = value.replaceAll(",", ", ");
    }

    return (
      <tr key={attribute} className={classes.border}>
        <td
          width="200px"
          className={`${classes["text-capitialize"]} ${classes["p-left"]}`}
        >
          <div className={classes.value}>
            {t(`${attribute}`)?.replace("_", " ")}
          </div>
        </td>
        <td className={classes["p-right"]}>
          <div>{value}</div>
        </td>
      </tr>
    );
  });
}
