import React, { useEffect, useState } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { BsDash } from "react-icons/bs";
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import classes from "./tooltipSlider.module.scss";
import { useTranslation } from "react-i18next";

export default function TooltipSlider(props) {
  const [play, setPlay] = useState(false);

  const handlePlay = () => setPlay(true);
  const handlePause = () => setPlay(false);

  const { t } = useTranslation("translations");

  const { value, min, max, loading = false } = props;

  useEffect(() => {
    let interval;
    if (play && !loading) {
      interval = setInterval(() => {
        if (value === max) {
          props.onChange(min);
        } else {
          props.onChange(value + props.step);
        }
      }, 3000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [play, value, loading]);

  return (
    <div className={classes["tooltip-main-container"]}>
      {props?.showBaseYear && (
        <div
          className={classes["base-year-container"]}
          onClick={() => props.onChangeBaseYear(!props?.baseYear)}
        >
          {props.baseYear ? <FaCheck /> : <BsDash />}
          <p className={classes["base-year-text"]}>
            {t("Basisjahr")} {props.min}
          </p>
        </div>
      )}
      <p>{props.startingLabel}</p>
      <Slider
        {...props}
        handleRender={(renderProps) => {
          return (
            <div {...renderProps.props}>
              <div className={classes["tooltip-container"]}>
                <p>{props.value}</p>
              </div>
            </div>
          );
        }}
      />
      <p>{props.endingLabel}</p>
      {play ? (
        <FaCirclePause
          onClick={handlePause}
          size="30px"
          color="rgb(4, 136, 219)"
        />
      ) : (
        <FaCirclePlay
          onClick={handlePlay}
          size="30px"
          color="rgb(4, 136, 219)"
        />
      )}
    </div>
  );
}
