import React, { useEffect, useState } from "react";
import { IoMdInformation } from "react-icons/io";
import classes from "./categoryCard.module.scss";
import FilterIcon from "../../../icons/filter.icon";

export default function CategoryCard({
  fetchRelevantFile = () => {},
  selectedFiles = [],
  handleSelectedID = () => {},
  loading = false,
  id,
  image,
  label,
  id2 = undefined,
  action,
  isDropdown = false,
  shouldOpen = false,
}) {
  const [open, setOpen] = useState();
  const handleToggle = () => setOpen(!open);

  useEffect(() => {
    if (shouldOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [shouldOpen]);

  return (
    <>
      <div className={classes["category-card"]}>
        <div className={classes["category-content"]}>
          <input
            type="checkbox"
            name={label}
            onClick={() => {
              fetchRelevantFile(id);
              if (id2) {
                fetchRelevantFile(id2);
              }
            }}
            checked={selectedFiles.includes(id)}
            disabled={loading}
          />
          <div className={classes["category-assets"]}>
            <img src={image} alt={label} />
          </div>
          <div onClick={handleToggle} className={classes["label-container"]}>
            <p>{label}</p>
            {isDropdown && <FilterIcon className={classes.icon} open={open} />}
          </div>
          {id !== "openRailwayMap" && (
            <div className={classes.tooltip}>
              <div onClick={handleSelectedID}>
                <IoMdInformation className={classes["open-information-icon"]} />
              </div>
            </div>
          )}
        </div>
        <div className={open ? classes.open : classes.hide}>{action}</div>
      </div>
    </>
  );
}
