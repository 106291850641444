import { DATA_SOURCES } from "../constants";

// Function to get default sources
export const getDefaultSource = () => [
  {
    label: "OpenStreetMap contributors",
    href: "https://www.openstreetmap.org/copyright",
  },
  {
    label: "GeoBasis-DE / BKG (2023)",
    href: "https://gdz.bkg.bund.de/index.php/default/nuts-gebiete-1-250-000-stand-31-12-nuts250-31-12.html",
  },
];

// Function to ensure unique links based on label
export const getUniqueLinks = (links) => {
  const map = new Map();
  links.forEach((link) => {
    if (!map.has(link.label)) {
      map.set(link.label, link);
    }
  });
  return Array.from(map.values());
};

// Function to get additional sources based on filter selections
export const getAdditionalSources = (filterSelections) => {
  const sources = [];

  if (filterSelections["LEAG Railways"]?.leagRailway) {
    sources.push({
      label: "IPG",
      href: "https://www.ipg-potsdam.de/",
    });
  }

  if (filterSelections["Railway Sidings"]?.railwaySiding) {
    sources.push({
      label: "Fraunhofer IIS / Projekt SIDING (Gleisanschlüsse)",
      href: "URL_FOR_FRAUNHOFER_IIS",
    });
  }

  if (filterSelections["Railway Map"]?.railwayMap) {
    sources.push(
        {
          label: "OpenRailwayMap",
          href: "https://www.openrailwaymap.org/",
        },
        {
          label: "OpenStreetMap contributors",
          href: "https://www.openstreetmap.org/copyright",
        }
    );
  }

  if (
      filterSelections?.ER_International?.railway_map ||
      filterSelections?.ER_National?.railway_map ||
      filterSelections?.national?.roads ||
      filterSelections?.international?.roads
  ) {
    sources.push(
        {
          label: "IPG",
          href: "https://www.ipg-potsdam.de/",
        },
        {
          label: "OpenStreetMap contributors",
          href: "https://www.openstreetmap.org/copyright",
        },
        {
          label: "TEN tec",
          href: "https://transport.ec.europa.eu/transport-themes/infrastructure-and-investment/trans-european-transport-network-ten-t/tentec-information-system_en",
        }
    );
  }

  return sources;
};

// Main function to get selected category data sources
export const getSelectedCategoriesDataSources = ({
                                                   selectedFiles = [],
                                                   selectedCategoryID = "",
                                                   filterSelections = {},
                                                 }) => {
  const defaultSource = getDefaultSource();
  const additionalSources = getAdditionalSources(filterSelections);

  if (selectedCategoryID === "all") {
    // Merge all data sources and ensure unique links
    const allSources = DATA_SOURCES.flatMap((d) => d.links);
    return getUniqueLinks([...defaultSource, ...allSources, ...additionalSources]);
  }

  if (selectedCategoryID) {
    const selectedData = DATA_SOURCES.find(
        (data) => data.id === selectedCategoryID
    );
    if (selectedData) {
      return getUniqueLinks([
        ...defaultSource,
        ...selectedData.links,
        ...additionalSources,
      ]);
    }
  }

  // Filter selected files and get corresponding data sources
  const allLinks = DATA_SOURCES.filter((data) =>
      selectedFiles.includes(data.id)
  ).flatMap((data) => data.links);

  return getUniqueLinks([...defaultSource, ...allLinks, ...additionalSources]);
};
