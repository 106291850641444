import React, { useEffect, useRef, useState } from "react";
import classes from "./customSlider.module.scss"; // Import CSS module

const CustomSlider = () => {
  const [value, setValue] = useState(0); // Example default value
  const sliderRef = useRef(); // Reference to the slider
  const [tooltipPosition, setTooltipPosition] = useState("0%");

  const handleChange = (e) => {
    setValue(Number(e.target.value));
    updateTooltipPosition(e.target.value);
  };

  const marks = [0, 100, 200, 300, 400, 500, "km"];
  const scale = [0, 1.26, 2.52, 3.78, 5.04, 6.3, "tkm"];

  const updateTooltipPosition = (newValue) => {
    if (sliderRef.current) {
      const percentage = newValue / sliderRef.current.max; // 200 / 400 => 0.5
      const sliderWidth = sliderRef.current.clientWidth; // 181
      const newLeft = percentage * sliderWidth; // 0.5 * 181 => 90.5
      setTooltipPosition(`${newLeft}px`);
    }
  };

  useEffect(() => {
    updateTooltipPosition(value);
  }, [value]);

  return (
    <div className={classes["custom-slider-container"]}>
      <div className={classes.relative}>
        <div
          className={classes["tool-tip-container"]}
          style={{
            left: tooltipPosition,
          }}
        >
          <div className={classes["tool-tip-details"]}>
            <img src="/images/tooltip-transport.png" alt="tooltip" />
            <p>1.512 t</p>
          </div>
        </div>
      </div>
      <div className={classes["range-lines"]}>
        <input
          type="range"
          ref={sliderRef}
          min="0"
          max="400"
          value={value}
          onChange={handleChange}
          className={classes["slider"]} // Use dynamic class name
        />
        <div className={classes["remaining"]} />
      </div>
      <div className={classes["marks-container"]}>
        {marks.map((m) => (
          <p className={classes["mark"]} key={m}>
            {m}
          </p>
        ))}
      </div>

      <div className={classes["scale-main-container"]}>
        <div className={classes["scale-container"]}>
          {scale.slice(1).map((_, i) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className={classes["short-line"]}
              key={i}
            ></div>
          ))}
        </div>

        <div className={`${classes["marks-container"]} ${classes["mt"]}`}>
          {scale.map((m, i) => (
            <p
              className={`${classes["mark"]} ${
                !Boolean(i === 0 || i === scale.length)
                  ? classes["translate"]
                  : ""
              }`}
              key={m}
            >
              {m}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
