import React from "react";
import classes from "./singleCard.module.scss";
import { Link } from "react-router-dom";

export default function SingleCard({ image, title, link, className, isLast }) {
  return (
    <div className={classes.container}>
      <Link to={link}>
        <div
          className={`${classes["image-container"]} ${
            className ? classes[className] : ""
          } ${isLast ? classes.m0: ''}`}
        >
          <img src={image} alt={title} />
        </div>
        <p className={classes.title}>{title}</p>
      </Link>
    </div>
  );
}
