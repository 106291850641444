import React from "react";
import classes from "./modal.module.scss";

export default function Modal({
  children,
  onClose = () => {},
  noMaxWidth = false,
}) {
  return (
    <div className={classes["modal-main-container"]} onClick={onClose}>
      <div
        className={`${classes["modal-container"]} ${
          noMaxWidth ? classes["remove-max-width"] : ""
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <span onClick={onClose} className={classes["close-icon"]}>
          &times;
        </span>
        {children}
      </div>
    </div>
  );
}
