// App.js
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomMap from "./components/map";
import LogisticsMap from "./components/logisticsMap";
import { BarChart } from "./components/barChart/index";
import Layout from "./components/layout";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
// Import translations
import enSidebar from "./locales/en/sidebar.json";
import deSidebar from "./locales/de/sidebar.json";
import leichteSidebar from "./locales/leichteSprache/sidebar.json";
import enTranslations from "./locales/en/translations.json";
import deTranslations from "./locales/de/translations.json";
import leichteTranslations from "./locales/leichteSprache/translations.json";
import WelcomePage from "./components/welcome-page";

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { sidebar: enSidebar, translations: enTranslations },
    de: { sidebar: deSidebar, translations: deTranslations },
    leichteSprache: {
      sidebar: leichteSidebar,
      translations: leichteTranslations,
    },
  },
  lng: "en",
  fallbackLng: ["en", "de", "leichteSprache"],
});

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/map" element={<CustomMap />} />
          <Route path="/logistics" element={<LogisticsMap />} />
          <Route path="/analysis" element={<BarChart />} />
          <Route path="/" element={<WelcomePage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
