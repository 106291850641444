import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import axios from 'axios';

const MapWithMarkers = () => {
    const [ctTerminals, setCtTerminals] = useState([]);
    const [railports, setRailports] = useState([]);
    const fetchData = async () => {
        try {
            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
            };

            // Fetch railports and CT terminals data asynchronously
            const [railportResponse, ctTerminalsResponse] = await Promise.all([
                axios.post(
                    'https://overpass-api.de/api/interpreter',
                    {
                        data: `[out:json];
                  node[railway=signal];
                  out center;`,
                    },
                    { headers } // Increase the timeout value (in milliseconds)
                ),
                // Add another axios.post for CT terminals if needed
            ]);

            console.log(railportResponse, "railportResponse");
            // Process railport data

            console.log(ctTerminalsResponse, "ctTerminalsResponse");
            // Process CT terminals data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <MapContainer center={[51.376978318950478, 13.835024677847938]} zoom={9} style={{ height: '500px', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* Add markers for CT terminals */}
            {ctTerminals?.map((terminal) => (
                <Marker
                    key={terminal.properties.id}
                    position={[
                        terminal.geometry.coordinates[1],
                        terminal.geometry.coordinates[0],
                    ]}
                >
                    <Popup>{terminal.properties.tags.name}</Popup>
                </Marker>
            ))}

            {/* Add markers for railports */}
            {railports?.map((railport) => (
                <Marker
                    key={railport.properties.id}
                    position={[
                        railport.geometry.coordinates[1],
                        railport.geometry.coordinates[0],
                    ]}
                >
                    <Popup>{railport.properties.tags.name}</Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default MapWithMarkers;
