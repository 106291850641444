import React from "react";
import classes from "./circularIcon.module.scss";
import Tooltip from "../../sharedComponent/tooltip";

export default function CircularIcon({
  imageSrc = "",
  borderColor = "",
  rotate = "",
  toolTipText = "",
}) {
  return (
    <div
      className={classes.container}
      style={{
        borderColor: borderColor || "unset",
        rotate: `${rotate ? `-${rotate}` : "unset"}`,
      }}
    >
      <Tooltip text={toolTipText}>
        <div className={classes["inner-circle"]}>
          <img src={imageSrc} alt="" />
        </div>
      </Tooltip>
    </div>
  );
}
