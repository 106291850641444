import React, { useContext } from "react";
import classes from "./topBar.module.scss";

import logo from "./../../assets/distill-logo.png";
import LanguageSelector from "../languageSelector";
import { ResetDirectionsContext } from "../../context/reset";
import { Link, useLocation } from "react-router-dom";
import PartnerLogos from "../partnerLogos";
import { useTranslation } from "react-i18next";
const TopBar = () => {
  const { handleResetTrue } = useContext(ResetDirectionsContext);
  const { pathname } = useLocation() || {};

  const { i18n } = useTranslation();

  return (
    <div className={classes["top-bar"]}>
      <div
        className={`${classes.logo} ${
          pathname === "/" ? classes.absolute : ""
        }`}
      >
        <Link to={`/?lang=${i18n.language}`}>
          <img src={logo} alt="logo" onClick={handleResetTrue} />
        </Link>
      </div>
      <PartnerLogos />
      <div className=""></div>
      <LanguageSelector />
    </div>
  );
};

export default TopBar;
