import React from "react";
import { GeoJSON } from "react-leaflet";

import MaustraBenLausitzJSON from "../../data/Database/Maustraßen_Lausitz.json";
import store from "../../store/index.js";
import { axiosInstance } from "../../axios/axiosIntercepter.js";

export default function TollData({
  setTollDetailOfSelectedId = () => {},
  setShowTollPopup = () => {},
}) {
  const { mapColors = {} } = store((state) => state) || {};

  const getData = async (id) => {
    try {
      await axiosInstance
        .get(
          `https://core-backend.distill-lausitz.de/api/getMautdatenBund?id=${id}&langauge=DE`
        )
        .then((res) => {
          setTollDetailOfSelectedId(res?.data);
          setShowTollPopup(true);
        });
    } catch (err) {
      console.log("err: ", err);
    }
  };

  return (
    <>
      <GeoJSON
        data={MaustraBenLausitzJSON.features}
        style={() => ({
          color: mapColors.tollData.root,
          weight: 2,
          fillOpacity: 0.15,
          fillColor: "#ddc6e1",
        })}
        onEachFeature={(feature, layer) => {
          layer.on({
            click: async (event) => {
              await getData(feature.properties.Abschnitts_ID);
              // Extract the region value from the feature properties
              // console.log(locationInfo,"locations")
            },
          });
        }}
      />
      {/* To make hitbox increase we increased weight and make color transparent */}
      <GeoJSON
        data={MaustraBenLausitzJSON.features}
        style={() => ({
          color: "transparent",
          weight: 6,
          fillOpacity: 0.15,
          // fillColor: "#ddc6e1",
        })}
        onEachFeature={(feature, layer) => {
          layer.on({
            click: async (event) => {
              await getData(feature.properties.Abschnitts_ID);
              // Extract the region value from the feature properties
              // console.log(locationInfo,"locations")
            },
          });
        }}
      />
    </>
  );
}
