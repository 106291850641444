import { GeoSearchControl } from "leaflet-geosearch";
import { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import "./index.css";
import { useTranslation } from "react-i18next";

const Search = (props) => {
  const { t, i18n } = useTranslation("translations");
  const { provider, onSearchResult, searchLocation } = props;
  const Map = useMap();
  const searchRef = useRef();

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider: provider,
      showMarker: false,
      showPopup: false,
      popupFormat: ({ query, result }) => result.label,
      resultFormat: ({ result }) => result.label,
      maxMarkers: 1,
      retainZoomLevel: false,
      animateZoom: true,
      autoClose: false,
      searchLabel: t("searchTerm"),
      keepResult: false,
      updateMap: true,
    });

    searchRef.current = searchControl;
    Map.addControl(searchControl);

    // Listen for the 'geosearch/showlocation' event to get the selected location
    Map.on("geosearch/showlocation", (event) => {
      const { location } = event;
      onSearchResult(location);
    });

    return () => {
      Map.removeControl(searchControl);
      Map.off("geosearch/showlocation");
    };
  }, [provider, onSearchResult, Map, i18n.language]);

  useEffect(() => {
    if (searchLocation) {
      // Use setTimeout to ensure the search input is populated after the control is rendered
      setTimeout(() => {
        const inputElement = document.querySelector(
          ".leaflet-control-geosearch input"
        );
        if (inputElement) {
          inputElement.value = searchLocation;

          // Dispatch input event
          const inputEvent = new Event("input", { bubbles: true });
          inputElement.dispatchEvent(inputEvent);

          // Dispatch enter key event
          const enterEvent = new KeyboardEvent("keydown", {
            key: "Enter",
            keyCode: 13,
            bubbles: true,
          });
          inputElement.dispatchEvent(enterEvent);
        }
      }, 100);
    }
  }, [searchLocation]);

  return null;
};

export default Search;
