import React from "react";

export default function CommentsIcon() {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.501 4.964V11.571C19.5013 12.0917 19.3989 12.6073 19.1998 13.0884C19.0007 13.5695 18.7087 14.0067 18.3406 14.3749C17.9725 14.7431 17.5354 15.0352 17.0543 15.2344C16.5733 15.4337 16.0577 15.5361 15.537 15.536H12.894L10.714 17.716C10.6546 17.779 10.5829 17.8292 10.5033 17.8635C10.4238 17.8977 10.3381 17.9154 10.2515 17.9154C10.1649 17.9154 10.0792 17.8977 9.99967 17.8635C9.92014 17.8292 9.84845 17.779 9.789 17.716L7.609 15.536H4.964C4.44336 15.536 3.92781 15.4334 3.44681 15.2342C2.96581 15.0349 2.52878 14.7428 2.16068 14.3746C1.79257 14.0064 1.50061 13.5693 1.30145 13.0883C1.1023 12.6072 0.999869 12.0916 1 11.571V4.964C1 3.91268 1.41763 2.90442 2.16103 2.16103C2.90442 1.41763 3.91268 1 4.964 1H15.536C16.5873 1 17.5956 1.41763 18.339 2.16103C19.0824 2.90442 19.501 3.91268 19.501 4.964Z"
        stroke="#484848"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.70996 8.32812H13.792"
        stroke="#484848"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
}
