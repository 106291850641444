import React from "react";
import classes from "./loader.module.scss";

export default function Loader() {
  return (
    <div className={classes["loader-container"]}>
      <div className={classes.loader}></div>
    </div>
  );
}
