import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import data from "../../data/Database/FHG_SCS_SIDING_Ergebnispunkte.json";
import MarkerClusterGroup from "react-leaflet-cluster";
import classes from "./railSidding.module.scss";
import { useTranslation } from "react-i18next";

const RailSidding = ({ railwaySidingRegions }) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("translations");
  const filteredData = data.filter((item) =>
    [...railwaySidingRegions].includes(item.Landkreis)
  );

  useEffect(() => {
    setLoading(false); // Since data is loaded locally, set loading to false immediately
  }, []);

  return (
    !loading && (
      <MarkerClusterGroup chunkedLoading>
        {filteredData.map((item, index) => (
          <Marker key={index} position={[item.YCoordinate, item.XCoordinate]}>
            <Popup>
              <div className={classes["railway-siding-popup"]}>
                <h2>
                  {item?.Name || item["Nächstgelegene Straße"] || "Siding"}
                </h2>
                <p>{t(item?.Anschlussart)}</p>
                <p>
                  <b>{t("Address")}: </b>
                  {`${item?.Ort} - ${item?.["Nächstgelegene Straße"]}, ${item?.Landkreis},
              ${item?.Bundesland}`}
                </p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    )
  );
};

export default RailSidding;
