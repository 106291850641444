import React from "react";
import { useTranslation } from "react-i18next";
import { Polyline } from "react-leaflet";

const PolyLines = ({ from = [], to = [], tkm = 0 }) => {
  const { i18n } = useTranslation();
  if (i18n.language === "en") {
    tkm = tkm.toLocaleString("en-US");
  } else {
    tkm = tkm.toLocaleString("de-DE");
  }

  return (
    <>
      <Polyline
        positions={[from, to]}
        color="#112255"
        weight={3}
        eventHandlers={{
          mouseover: (e) => {
            const latlng = e.latlng;
            e.target.bindPopup(`<div>${tkm}</div>`).openPopup(latlng);
          },
          // mouseout: (e) => e.target.closePopup(),
        }}
      />
    </>
  );
};

export default PolyLines;
